<template>
  <img v-if="showButton" class="demo__hint-button cp" src="/img/misc/demo-hint.svg" alt="" @click="showTour">
</template>

<script>
export default {
  name: 'demo-hint-button',
  data() {
    return {
      showButton: true,
    };
  },
  created() {
    this.showButton = localStorage.getItem('demo');
  },
  methods: {
    showTour() {
      if (this.$route.name !== 'tickets') {
        this.$router.push('/admin/tickets').then(() => {
          this.$tours.tour.start();
        });
      } else {
        this.$tours.tour.start();
      }
    },
  },
};
</script>

<style scoped>
.demo__hint-button {
  position: fixed;
  top: 24px;
  right: 24px;
}
</style>
