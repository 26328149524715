<template>
  <BaseSimpleDropdown
    v-if="selectedLanguage"
    :position="'bottom-left'"
    :offsetTop="40"
    :options="languagesList"
    :clearable="false"
    :button-text="selectedLanguage ? selectedLanguage.title : ''"
    :selected-option="currentLang ? currentLang : ''"
    @selectOption="changeLang($event)"
  >
  </BaseSimpleDropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_SAVE } from '@/store/actions/user';
import BaseSimpleDropdown from '@/views/RenovaDashboard/components/ui-kit/Dropdowns/BaseSimpleDropdown';
import { GET_DICTIONARY } from '@/store/actions/dictionary';

export default {
  name: 'LangSwitcher',
  components: {
    BaseSimpleDropdown,
  },
  props: {
    reloadApp: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentLang: null,
    };
  },
  watch: {
    'user.defaultLocale': {
      handler() {
        this.currentLang = this.user.defaultLocale;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['user', 'languages']),
    languagesList() {
      return [...this.languages];
    },
    selectedLanguage() {
      return this.languagesList.find((lang) => lang.id === this.currentLang);
    },
  },
  created() {
    this.currentLang = localStorage.getItem('lang');
  },
  methods: {
    changeLang(lang) {
      localStorage.setItem('lang', lang);
      this.$i18n.locale = lang;
      this.currentLang = lang;
      this.$store
        .dispatch(USER_SAVE, {
          id: this.user.id,
          defaultLocale: lang,
        })
        .then(() => {
          Promise.all([
            this.$store.dispatch(GET_DICTIONARY),
            this.$store.dispatch('saveUserSettings', {
              defaultLanguage: lang,
              notification: {
                leadCreated: {
                  browser: false,
                  email: false,
                },
              },
            }),
          ]).then(() => {
            if (this.reloadApp) {
              window.location.reload();
            }
          });
        });
    },
  },
};
</script>
