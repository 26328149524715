export const CUP_CLIENT_LIST_REQUEST = 'CUP_CLIENT_LIST_REQUEST';
export const CUP_CLIENT_LIST_SUCCESS = 'CUP_CLIENT_LIST_SUCCESS';
export const CUP_CLIENT_LIST_FILTERS_CHANGE = 'CUP_CLIENT_LIST_FILTERS_CHANGE';
export const CUP_CLIENT_SUGGEST_LIST = 'CUP_CLIENT_LIST_SUGGEST_LIST';

export const CUP_CLIENT_ADD_REQUEST = 'CUP_CLIENT_ADD_REQUEST';
export const CUP_CLIENT_UPDATE_REQUEST = 'CUP_CLIENT_UPDATE_REQUEST';
export const CUP_CLIENT_DELETE_REQUEST = 'CUP_CLIENT_DELETE_REQUEST';
export const CUP_CLIENT_REQUEST_LOADING = 'CUP_CLIENT_REQUEST_LOADING';

export const CUP_FILES_LIST_GET = 'CUP_FILES_LIST_GET';
export const CUP_FILES_LIST_DOWNLOAD_ALL = 'CUP_FILES_LIST_DOWNLOAD_ALL';

export const CUP_TICKETS_LIST_GET = 'CUP_TICKETS_LIST_GET';
export const CUP_TICKET_DETAILS_GET = 'CUP_TICKET_DETAILS_GET';

export const CUP_ADD_USERS_FROM_FILE = 'CUP_ADD_USERS_FROM_FILE';
export const CUP_USERS_ADD_DOWNLOAD_EXAMPLE = 'CUP_USERS_ADD_DOWNLOAD_EXAMPLE';

export const CUP_UPLOAD_FILE = 'CUP_UPLOAD_FILE';

export const CUP_USERS_REMOVE_DOWNLOAD_EXAMPLE = 'CUP_USERS_REMOVE_DOWNLOAD_EXAMPLE';
export const CUP_REMOVE_USERS_FROM_FILE = 'CUP_REMOVE_USERS_FROM_FILE';

export const CUP_SET_COMPANY = 'CUP_SET_COMPANY';

export const CUP_AVAILABLE_DATES_REQUEST = 'CUP_AVAILABLE_DATES_REQUEST';
