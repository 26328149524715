<template>
  <div class="onboarding-companies">
    <p class="h2 mb_12 color--black-purple text_ac">
      {{ $t('onboarding.AddInsuranceCompanies') }}
    </p>
    <p class="body-text mb_32 onboarding-companies__description"
       v-html="$t('onboarding.IncludeTheNames')">
    </p>
    <p class="onboarding-companies__caption caption color--dark-purple mb_8">{{$t('onboarding.InsuranceCompany')}}</p>
    <el-form
      ref="form"
      :model="form"
      class="onboarding-companies__form"
      @submit.native.prevent
    >
      <el-form-item
        v-for="(company, index) in form.companies"
        :id="`Company_${company.key}`"
        class="onboarding-companies__form-input"
        :key="company.key"
        :prop="'companies.' + index + '.value'"
        :rules="companyRules"
      >
        <el-input
          v-model="company.value"
          :placeholder="$t('onboarding.EnterInsuranceCompanyName')"
        />
        <BaseTextButton
          @click="removeCompany(company)"
          class="onboarding-companies__form-input-btn color--dark-purple"
          v-if="form.companies.length > 1 && !!company.value || index > 0"
        >
          {{ $t('adminTitles.Delete') }}
        </BaseTextButton>
      </el-form-item>
      <BaseTextButton
        @click="addCompany"
        class="color--black-purple mt_12">
        <img src="/img/renova-dashboard/icons/plus-circle.svg" alt="add">
        {{ $t('onboarding.AddCompany') }}
      </BaseTextButton>
    </el-form>
    <BaseButton
      :loading="btnLoading"
      :full-width="true"
      class="mt_32 mb_16"
      @click="submitStep"
    >
      {{ $t('buttons.add') }}
    </BaseButton>
    <BaseTextButton
      @click="skipStep"
      class="color--purple">
      {{ $t('onboarding.SkipForNow') }}
    </BaseTextButton>
  </div>
</template>

<script>
import BaseTextButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseTextButton';
import { mapGetters } from 'vuex';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import { UPDATE_ONBOARDING } from '@/store/actions/onboarding';

const defaultCompaniesList = [{
  key: 0,
  value: ''
}];
export default {
  name: 'OnboardingInsuranceCompaniesStep',
  components: {
    BaseButton,
    BaseTextButton
  },
  emits: ['skip', 'submitStep'],
  props: {
    btnLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      form: {
        companies: defaultCompaniesList,
      },
      companyRules: [
        {
          required: true,
          message: this.$t('adminTitles.FieldIsRequired'),
          trigger: 'blur',
        },
      ]
    };
  },
  computed: {
    ...mapGetters(['onboarding']),
  },
  mounted() {
    if (this.onboarding.companies.length) {
      this.form.companies = this.onboarding.companies.map((value, key) => ({key, value}));
    }
  },
  methods: {
    skipStep() {
      this.form.companies = defaultCompaniesList;
      this.updateCompanies();
      this.$emit('skip');
    },
    updateCompanies() {
      this.$store.dispatch(UPDATE_ONBOARDING, {
        companies: this.form.companies.map((val) => val.value),
      });
    },
    removeCompany(item) {
      const index = this.form.companies.indexOf(item);
      if (index !== -1) {
        this.form.companies.splice(index, 1);
      }
    },
    addCompany() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const key = this.form.companies.length + 1;
          this.form.companies.push({
            key,
            value: ''
          });
          setTimeout(() => {
            document.getElementById(`Company_${key}`).querySelector('input').focus();
          }, 100);
        }
      });
    },
    submitStep() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.updateCompanies();
          this.$emit('submitStep');
        }
      });
    }
  },
};
</script>

<style lang="scss">
.onboarding-companies {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 70px 0px 70px;
  align-items: center;
}

.onboarding-companies__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 190px;
  overflow: auto;

  .el-form-item__error {
    position: relative !important;
  }

  input {
    padding-right: 71px !important;
  }
}

.onboarding-companies__form-input {
  position: relative;
}

.onboarding-companies__form-input-btn {
  position: absolute;
  right: 16px;
  top: 12px;
}
.onboarding-companies__description {
  line-height: 20px !important;
  color: var(--solid-black-purple);
  text-align: center;
}
.onboarding-companies__caption{
  width: 100%;
  text-align: left;
}
</style>
