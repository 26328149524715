<template>
  <div class="programs">
    <div class="flex flex-a-fe mb_24">
      <el-input
        v-model="searchKey"
        class="a-common__search mr_24"
        :placeholder="$t('adminTitles.search')"
        clearable
      />

      <button class="cm__button" @click="$router.push('/admin/programs/new')">
        <img class="mr_8" src="/img/misc/plus.svg" alt="" /> {{$t('buttons.newProgram')}}
      </button>
    </div>

    <div class="flex flex-a-fe mb_24">
      <el-form class="flex" label-position="top" label-width="auto" v-if="filters">
        <el-form-item class="a-filters-select mr_24" :label="$t('adminTitles.companies')">
          <el-select
            v-model="filters.clientCompanyIds"
            multiple
            filterable
            collapse-tags
            :placeholder="$t('placeholders.notSelected')"
            :loading="dictionaryLoading"
            @change="changeFilter"
          >
            <el-option
              v-for="item in clientCompanies"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="a-filters-select mr_24" :label="$t('adminTitles.insuranceCompany')">
          <el-select
            v-model="filters.insuranceCompanyIds"
            multiple
            filterable
            collapse-tags
            :placeholder="$t('placeholders.notSelected')"
            :loading="dictionaryLoading"
            @change="changeFilter"
          >
            <el-option
              v-for="item in filteredInsuranceCompanies"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="a-filters-select mr_24" :label="$t('adminTitles.status')">
          <el-select
            v-model="activeFilter"
            clearable
            :placeholder="$t('placeholders.notSelected')"
          >
            <el-option
              v-for="item in activeList"
              :key="item.val"
              :label="item.name"
              :value="item.val">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <el-skeleton style="padding: 16px" :rows="20" v-if="programsList.loading"/>

    <div v-else class="a-common__blocks-items-container">
      <div v-for="item in filterPrograms" :key="item.id" class="a-common__blocks-item">
        <p class="text_16 text_dark text_b mb_16" v-html="item.name" />

        <p v-if="item.clientCompany" class="text_12 text_grey text_300 mb_8">
          {{$t('adminTitles.company')}}: <span class="text_dark">{{item.clientCompany.name}}</span>
        </p>

        <p class="text_12 text_grey text_300 mb_8">
          {{$t('adminTitles.services')}}: <span class="text_dark">{{item.offerCount}}</span>
        </p>

        <p class="text_12 text_grey text_300 mb_16">
          {{$t('adminTitles.insured')}}: <span class="text_dark">{{item.userCount}}</span>
        </p>

        <p class="text_12 text_grey text_300 mb_16">
          {{$t('adminTitles.status')}}: <span :class="statusColor(item.isAvailable)">{{item.isAvailable ? $t('adminTitles.active') : $t('adminTitles.inactive')}}</span>
        </p>

        <button class="cm__button cm__button-dark" @click="$router.push(`/admin/programs/${item.packageId}`)">
          {{$t('buttons.edit')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PROGRAMS_LIST_FILTERS_CHANGE, PROGRAMS_LIST_REQUEST } from '@/store/actions/programs';

export default {
  name: 'ProgramsListView',
  data() {
    return {
      searchKey: '',
      filters: null,
      activeFilter: '',
      activeList: [
        {
          val: true,
          name: this.$t('adminTitles.activeProgram'),
        },
        {
          val: false,
          name: this.$t('adminTitles.inactiveProgram'),
        },
      ],
    };
  },
  created() {
    this.$store.dispatch(PROGRAMS_LIST_REQUEST);
    this.filters = JSON.parse(JSON.stringify(this.programsListFilters));
    this.searchKey = this.programsSearchQuery;
    this.activeFilter = this.programsStatusFilter;
  },
  watch: {
    programsListFilters: {
      handler() {
        this.filters = JSON.parse(JSON.stringify(this.programsListFilters));
      },
      deep: true,
    },
    searchKey() {
      this.$store.commit('PROGRAMS_LIST_SEARCH_QUERY_CHANGE', this.searchKey);
    },
    activeFilter() {
      this.$store.commit('PROGRAMS_LIST_STATUS_FILTER_CHANGE', this.activeFilter);
    },
  },
  computed: {
    ...mapGetters([
      'programsList',
      'programsListFilters',
      'programsStatusFilter',
      'programsSearchQuery',
      'ticketsFiltersData',
      'insuranceCompanies',
      'dictionaryLoading',
      'clientCompanies',
      'filteredInsuranceCompaniesByCompanyIds',
    ]),
    /**
     * Filtered insurance companies by selected client company ids
     * @returns {*}
     */
    filteredInsuranceCompanies() {
      // If no client company selected, return all insurance companies
      if (!this.filters.clientCompanyIds.length) {
        return this.insuranceCompanies;
      }
      return this.filteredInsuranceCompaniesByCompanyIds(this.filters.clientCompanyIds);
    },
    statusFilter() {
      return this.activeFilter !== ''
        ? this.programsList.items.filter((item) => item.isAvailable === this.activeFilter)
        : this.programsList.items;
    },
    filterPrograms() {
      return this.statusFilter.filter((item) => item.name.toLowerCase().includes(this.searchKey.toLowerCase()));
    },
  },
  methods: {
    changeFilter() {
      this.filters.page = 1;
      this.$store.dispatch(PROGRAMS_LIST_FILTERS_CHANGE, this.filters);
    },
    statusColor(status) {
      return {
        text_blue: status,
        text_orange: !status,
      };
    },
  },
};
</script>

<style scoped>

</style>
