import axios from 'axios';

const state = {
  userSettings: null,
};

const getters = {
  userSettings: () => state.userSettings,
  userNotificationsSettings: () => state.userSettings.settings,
};

const actions = {
  fetchUserSettings({ commit, state }, providerId) {
    axios
      .post(
        '/insurance/user/detail',
        {},
        { headers: { 'X-Insurance-Provider-ID': providerId }}
      )
      .then((response) => {
        commit('setUserSettings', response.data);
      })
      .catch((error) => {
        console.error('Error fetching tasks:', error);
      });
  },
  saveUserSettings({ commit, dispatch }, body) {
    axios
      .post('/insurance/user/save', {
        settings: {
          ...body,
        }
      })
      .catch((error) => {
        console.error('Error fetching task details:', error);
      });
  },
};

const mutations = {
  setUserSettings(state, userSettings) {
    state.userSettings = userSettings;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
