export const CLINICS_REQUEST = 'CLINICS_REQUEST';
export const CLINICS_CLEAR = 'CLINICS_CLEAR';
export const CLINICS_SUCCESS = 'CLINICS_SUCCESS';
export const CLINIC_REQUEST = 'CLINIC_REQUEST';
export const CLINIC_SUCCESS = 'CLINIC_SUCCESS';
export const CLINICS_FILTERS_CHANGE = 'CLINICS_FILTERS_CHANGE';

export const CLINICS_DELETE_REQUEST = 'CLINICS_DELETE_REQUEST';
export const CLINICS_DELETE_SUCCESS = 'CLINICS_DELETE_SUCCESS';
export const CLINIC_SAVE = 'CLINIC_SAVE';
