import axios from 'axios';

import { OFFERS_LIST_BY_PROGRAM_REQUEST } from '@/store/actions/offers';
import i18n from '@/i18n';
import {
  PROGRAMS_LIST_REQUEST,
  PROGRAMS_LOADING_STATUS,
  PROGRAM_DETAILS_REQUEST,
  PROGRAM_LOADING_STATUS,
  PROGRAM_SAVE,
  PROGRAM_OFFER_DELETE,
  PROGRAMS_LIST_BY_CLIENT_COMPANY_REQUEST,
  PROGRAM_OFFER_ADD,
  PROGRAMS_LIST_FILTERS_CHANGE,
  PROGRAMS_LIST_SEARCH_QUERY_CHANGE,
  PROGRAMS_LIST_STATUS_FILTER_CHANGE,
  PROGRAM_SERVICES_ORDER_SAVE,
  PROGRAMS_LIST_FILTERS_CLEAR,
  PROGRAM_STOP_GENERATION,
} from '../actions/programs';

function FiltersDefault(clientCompanyIds) {
  this.page = 1;
  this.limit = 1000;
  this.clientCompanyIds = clientCompanyIds || [];
  this.insuranceCompanyIds = [];
  this.insuranceTypes = [];
  this.insurancePlanTypes = [];
}

/**
 * Load cached search query from localStorage
 * @returns {string}
 */
function loadCachedSearchQuery() {
  // const cachedQuery = localStorage.getItem('programsSearchQuery');
  // if (cachedQuery) {
  //   return cachedQuery;
  // }
  return '';
}

/**
 * Load cached filters from localStorage if it exists or return default filters
 * @returns {any|FiltersDefault}
 */
function loadCachedFilters() {
  // const cachedFilters = localStorage.getItem('programsFilters');
  // if (cachedFilters) {
  //   return JSON.parse(cachedFilters);
  // }
  return new FiltersDefault();
}

/**
 * Load cached status filter value from localStorage if it exists or return false
 * @returns {any|boolean}
 */
function loadCachedStatusFilter() {
  // const cachedStatusFilter = localStorage.getItem('programsStatusFilter');
  // if (cachedStatusFilter) {
  //   return JSON.parse(cachedStatusFilter);
  // }
  return '';
}

const state = {
  programsList: {
    items: [],
    totalCount: 1,
    loading: false,
  },
  programDetails: {
    program: null,
    loading: false,
  },
  filters: loadCachedFilters(),
  programsSearchQuery: loadCachedSearchQuery(),
  programsStatusFilter: loadCachedStatusFilter(),
};

const getters = {
  programsList: () => state.programsList,
  programDetails: () => state.programDetails,
  programsListFilters: () => state.filters,
  programsSearchQuery: () => state.programsSearchQuery,
  programsStatusFilter: () => state.programsStatusFilter,
};

const actions = {
  [PROGRAMS_LIST_REQUEST]({ commit, state }, companyIds = []) {
    const filters = JSON.parse(JSON.stringify(state.filters));
    filters.page -= 1;

    if (companyIds.length) {
      filters.clientCompanyIds = companyIds;
    }

    commit(PROGRAMS_LOADING_STATUS);
    return axios.post(
      '/insurance/package/list',
      { ...filters, limit: 1000 },
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(PROGRAMS_LIST_REQUEST, resp.data);
        commit(PROGRAMS_LOADING_STATUS);
        return resp.data;
      })
      .catch((err) => {
        commit(PROGRAMS_LOADING_STATUS);
        return Promise.reject(err.response.data);
      });
  },
  [PROGRAM_DETAILS_REQUEST]({ commit }, programsId) {
    commit(PROGRAM_LOADING_STATUS);

    return axios.post(
      '/insurance/package/detail',
      programsId,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(PROGRAM_DETAILS_REQUEST, resp.data);
        commit(PROGRAM_LOADING_STATUS);
        return resp.data;
      })
      .catch((err) => {
        commit(PROGRAM_LOADING_STATUS);
        return Promise.reject(err.response.data);
      });
  },
  [PROGRAM_STOP_GENERATION]({ commit }, packageId) {
    return axios.post(
      '/insurance/package/save',
      {
        packageId,
        claraServiceGenerationCancel: true
      },
      { headers: { 'X-Locale': i18n.locale } },
    ).then((resp) => resp.data);
  },
  [PROGRAM_SAVE]({ commit }, program) {
    commit(PROGRAM_LOADING_STATUS);

    return axios.post('/insurance/package/save', {
      ...program,
      clientCompanyId: program?.clientCompanyId || undefined,
      insuranceCompanyId: program?.insuranceCompanyId || undefined
    }, { headers: { 'X-Locale': i18n.locale } })
      .then((resp) => {
        if (program.packageId) {
          commit(PROGRAM_DETAILS_REQUEST, resp.data);
        }
        commit(PROGRAM_LOADING_STATUS);
        return resp.data;
      })
      .catch((err) => {
        commit(PROGRAM_LOADING_STATUS);
        return Promise.reject(err.response.data);
      });
  },
  [PROGRAM_SERVICES_ORDER_SAVE]({ commit }, program) {
    return axios.post('/insurance/package/save', {
      ...program,
      insuranceCompanyId: program.insuranceCompanyId || null,
      clientCompanyId: program.clientCompanyId || null,
    }, { headers: { 'X-Locale': i18n.locale } })
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err.response.data));
  },

  [PROGRAM_OFFER_ADD]({ commit, dispatch }, data) {
    dispatch(PROGRAM_SAVE, data).then(() => {
      dispatch(OFFERS_LIST_BY_PROGRAM_REQUEST, data.packageId);
    });
  },
  [PROGRAM_OFFER_DELETE]({ commit, dispatch }, data) {
    dispatch(PROGRAM_SAVE, data).then(() => {
      dispatch(OFFERS_LIST_BY_PROGRAM_REQUEST, data.packageId);
    });
  },
  [PROGRAMS_LIST_BY_CLIENT_COMPANY_REQUEST]({ dispatch }, companyId) {
    dispatch(PROGRAMS_LIST_REQUEST, [companyId]);
  },
  [PROGRAMS_LIST_FILTERS_CHANGE]({ commit, dispatch }, filters) {
    commit(PROGRAMS_LIST_FILTERS_CHANGE, filters);
    dispatch(PROGRAMS_LIST_REQUEST);
  },
  [PROGRAMS_LIST_FILTERS_CLEAR]({ commit, dispatch }, filters) {
    commit(PROGRAMS_LIST_FILTERS_CLEAR, filters);
  },
};

const mutations = {
  [PROGRAMS_LOADING_STATUS]: (state) => {
    state.programsList.loading = !state.programsList.loading;
  },
  [PROGRAMS_LIST_REQUEST]: (state, resp) => {
    state.programsList.items = resp.items;
    state.programsList.totalCount = resp.totalCount;
  },
  [PROGRAMS_LIST_FILTERS_CHANGE]: (state, filters) => {
    state.filters = filters;
    localStorage.setItem('programsFilters', JSON.stringify(filters));
  },
  [PROGRAMS_LIST_SEARCH_QUERY_CHANGE]: (state, query) => {
    state.programsSearchQuery = query;
    localStorage.setItem('programsSearchQuery', query);
  },
  [PROGRAMS_LIST_STATUS_FILTER_CHANGE]: (state, status) => {
    state.programsStatusFilter = status;
    localStorage.setItem('programsStatusFilter', JSON.stringify(status));
  },

  [PROGRAM_LOADING_STATUS]: (state) => {
    state.programDetails.loading = !state.programDetails.loading;
  },
  [PROGRAM_DETAILS_REQUEST]: (state, resp) => {
    state.programDetails.program = resp;
    state.programDetails.program.clientCompanyId = state.programDetails.program.clientCompany ? state.programDetails.program.clientCompany.id : '';
    state.programDetails.program.insuranceCompanyId = state.programDetails.program.insuranceCompany ? state.programDetails.program.insuranceCompany.id : '';
  },
  [PROGRAMS_LIST_FILTERS_CLEAR]: (state, filters) => {
    state.filters = loadCachedFilters();
    state.programsSearchQuery = loadCachedSearchQuery();
    state.programsStatusFilter = loadCachedStatusFilter();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
