const WhitelabelCompanies = ['capitalcare', 'portento', 'apex', 'malakut'];
const WhiteLabelConfig = {
  capitalcare: {
    name: 'Capitalcare',
  },
  renova: {
    name: 'Renova',
  },
  portento: {
    name: 'Portento',
  },
  apex: {
    name: 'Apex',
  },
  malakut: {
    name: 'Malakut',
  },
};

//  the run local company Name.
export const getCompanyName = (domain) => WhitelabelCompanies.find((company) => domain.includes(company)) || 'renova'; // renova is default
export const getWhiteLabelConfig = () => WhiteLabelConfig[window.whiteLabelCompany];
export const getCompanyLogo = () => `/img/white-labels/${window.whiteLabelCompany}/logo-min.svg`;
