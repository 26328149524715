export const USERS_LIST_REQUEST = 'USERS_LIST_REQUEST';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_CHUNK = 'USERS_LIST_CHUNK';

export const USERS_LIST_REQUEST_FOR_PROGRAM = 'USERS_LIST_REQUEST_FOR_PROGRAM';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_CLEAR = 'USER_DETAILS_CLEAR';

export const USER_SAVE_REQUEST = 'USER_SAVE_REQUEST';
export const USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS';
export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';

export const USER_CSAT_REQUEST = 'USER_CSAT_REQUEST';
export const USER_CSAT_SUCCESS = 'USER_CSAT_SUCCESS';
export const USERS_LIST_FILTERS_CHANGE = 'USERS_LIST_FILTERS_CHANGE';
export const USERS_LIST_FILTERS_CLEAR = 'USERS_LIST_FILTERS_CLEAR';

export const USERS_LIST_SET_PAGE = 'USERS_LIST_SET_PAGE';
export const USERS_LIST_SET_LIMIT = 'USERS_LIST_SET_LIMIT';

export const USER_EXCLUDE_FROM_PROGRAM = 'USER_EXCLUDE_FROM_PROGRAM';
export const USER_DEACTIVATE_FROM_PROGRAM = 'USER_DEACTIVATE_FROM_PROGRAM';
export const USER_POLICY_DETAILS_REQUEST = 'USER_POLICY_DETAILS_REQUEST';
export const USER_POLICY_DETAILS_SUCCESS = 'USER_POLICY_DETAILS_SUCCESS';

export const GET_USERS_SUGGEST_LIST = 'GET_USERS_SUGGEST_LIST';

export const ADD_USERS_TO_PROGRAM_FROM_FILE_REQUEST = 'ADD_USERS_TO_PROGRAM_FROM_FILE_REQUEST';
export const ADD_USERS_TO_PROGRAM_FROM_FILE_SUCCESS = 'ADD_USERS_TO_PROGRAM_FROM_FILE_SUCCESS';

export const USERS_SEND_WELCOME_EMAIL_REQUEST = 'USERS_SEND_WELCOME_EMAIL_REQUEST';

export const USERS_SEND_WELCOME_EMAIL_SUCCESS = 'USERS_SEND_WELCOME_EMAIL_SUCCESS';
export const USERS_SEND_BULK_MESSAGES = 'USERS_SEND_BULK_MESSAGES';
export const USERS_SEND_BULK_MESSAGES_SUCCESS = 'USERS_SEND_BULK_MESSAGES_SUCCESS';

export const USER_SAVE_POLICY = 'USER_SAVE_POLICY';
export const USERS_POLICY_LIST_REQUEST = 'USERS_POLICY_LIST_REQUEST';
