import axios from 'axios';
import { Centrifuge } from 'centrifuge';
import { mapGetters } from 'vuex';

const WhatsAppIntegration = {
  computed: {
    ...mapGetters(['insuranceCompanies'])
  },
  methods: {
    subscribeToChannels() {
      const centrifuge = new Centrifuge(window.WebsocketUrl, {token: localStorage.getItem('ins-token')});
      centrifuge.connect();
      const channel = centrifuge.newSubscription(`/insurance/${this.selectedInsuranceProvider}`);
      channel.on('publication', (message) => {
        this.handleNotification(message.data);
      });
      channel.subscribe();
    },
    handleNotification(message) {
      switch (message.type) {
        case 'WHATSAPP_QR_UPDATED':
          if (!this.insuranceConfig.whatsAppUnofficialDetailsView.qrCode) {
            this.insuranceConfig.whatsAppUnofficialDetailsView = {
              ...this.insuranceConfig.whatsAppUnofficialDetailsView,
              status: 'QR_CONFIRMATION',
              qrCode: message.whatsappQRUpdated.qr,
            };
          }
          break;
        case 'WHATSAPP_QR_EXPIRED':
          this.$notify.error({
            message: this.$t('employees.qrExpired'),
          });
          this.fetchWhatsAppData();
          break;
        case 'WHATSAPP_CONNECTED':
          this.insuranceConfig.whatsAppUnofficialDetailsView = {
            ...this.insuranceConfig.whatsAppUnofficialDetailsView,
            status: 'CONNECTED',
          };
          this.$emit('WhatsAppConnected');
          break;
        default:
          break;
      }
    },
    fetchWhatsAppData() {
      axios
        .post('/insurance/config/detail', {
          insuranceCompanyId: this.selectedInsuranceProvider,
        })
        .then((response) => {
          this.insuranceConfig = response.data;
          this.btnLoading = false;
        })
        .catch((error) => {
          console.error('Error sending POST request:', error);
        });
    },
    connectWhatsApp() {
      return axios.post('insurance/config/save', {
        insuranceCompanyId: this.selectedInsuranceProvider,
        whatsAppUnofficialConnectionSave: {
          description: '',
        },
      }).then(() => {
        this.fetchWhatsAppData();
      });
    },
    disconnectWhatsAppRequest() {
      this.btnLoading = true;
      return axios.post('insurance/config/save', {
        insuranceCompanyId: this.selectedInsuranceProvider,
        whatsAppUnofficialConnectionDelete: true,
      }).then(() => {
        this.connectWhatsApp();
      });
    },
    clearInterval() {
      clearInterval(this.interval);
    }
  },
  destroyed() {
    this.clearInterval(this.interval);
  },
};

export default WhatsAppIntegration;
