<template>
  <div class="cookie-banner"
       v-if="showBanner">
    <h2
      class="cookie-banner__title"
      :class="{
        'cookie-banner__title--old': adminLayout,
        'cookie-banner__title--new': !adminLayout,
      }">
      {{ $t('adminTitles.ThisWebsiteUsesCookies') }}
    </h2>
    <p :class="{
        'text_16 text_grey': adminLayout,
        'cookie-banner__description': !adminLayout,
      }" v-html="$t('adminTitles.CookieDescription')"></p>
    <div class="flex">
      <el-button
        v-if="adminLayout"
        type="primary"
        class="mt_24"
        @click="acceptAll">
        {{ $t('adminTitles.CookieBtn') }}
      </el-button>
      <BaseButton
        v-if="!adminLayout"
        class="mt_24"
        @click="acceptAll"
      >
        {{ $t('adminTitles.CookieBtn') }}
      </BaseButton>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';

export default {
  name: 'CookieBanner',
  components: { BaseButton },
  data() {
    return {
      adminLayout: window.adminLayout === 'AdminLayout',
      showBanner: !localStorage.getItem('cookieConsent'),
    };
  },
  methods: {
    acceptAll() {
      localStorage.setItem('cookieConsent', 'true');
      this.showBanner = false;
    },
  },
};
</script>

<style lang="scss">
.cookie-banner {
  position: fixed;
  bottom: 30px;
  left: 3%;
  width: 94%;
  background-color: white;
  z-index: 1000000;
  border-radius: 16px;
  padding: 24px;
  border: 1px solid var(--solid-lavender-grey);
  display: flex;
  flex-direction: column;
  box-shadow: 1px -1px 10px 2px rgba(215,212,228,0.71);
}

.cookie-banner__title {
  margin-bottom: 16px;
  margin-top: 0;

  &--old {
    font-size: 23px;
    font-weight: normal;
    color: #0096FF;
  }

  &--new {
    font-family: Gilroy;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--solid-purple);
  }
}

.cookie-banner__description {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  color: var(--solid-black-purple);
}
</style>
