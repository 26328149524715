<template>
  <div
    class="base-text-button body-text--medium"
    @click="onClick"
  >
    <slot/>
  </div>
</template>
<script>
export default {
  name: 'BaseTextButton',
  methods: {
    onClick() {
      if (!this.locked) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-text-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}
</style>
