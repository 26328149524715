<template>
  <div class="side-modal-overlay">
    <slot/>
    <div
      v-if="closeBtn"
      class="side-modal-overlay__close-btn"
      @click="$emit('close')"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'BaseOverlay',
  emits: ['close'],
  props: {
    closeBtn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.side-modal-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--solid-black-purple-70);
  z-index: 1000;
}
.side-modal-overlay__close-btn{
  position: fixed;
  cursor: pointer;
  z-index: 1001;
  right: 24px;
  top: 24px;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/public/img/renova-dashboard/icons/close-btn.svg);
}
</style>
