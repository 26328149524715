<template>
  <div class="container">
    <markdown-wysiwyg
      style="margin-top: 400px"
      v-model="text"
      :show-balloon="true"
      :show-extend-buttons="true"
    />

    <p class="mt_40">{{text}}</p>

    <vue-markdown :anchorAttributes=anchorAttributes :source="text" class="mt_40" />
  </div>
</template>

<script>

import VueMarkdown from 'vue-markdown';
import MarkdownWysiwyg from '@/components/editor/markdown-wysiwyg';

export default {
  name: 'TestView',
  components: { MarkdownWysiwyg, VueMarkdown },
  data() {
    return {
      text: '',
      anchorAttributes: {
        target: '_blank',
      },
    };
  },
};
</script>

<style>
</style>
