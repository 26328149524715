export const RECEPTION_DETAILS_REQUEST = 'RECEPTION_DETAILS_REQUEST';
export const RECEPTION_DOCTOR_CALL_DETAILS_REQUEST = 'RECEPTION_DOCTOR_CALL_DETAILS_REQUEST';
export const RECEPTION_TELEMED_DETAILS_REQUEST = 'RECEPTION_TELEMED_DETAILS_REQUEST';
export const RECEPTION_DETAILS_COMMON_SUCCESS = 'RECEPTION_DETAILS_COMMON_SUCCESS';
export const RECEPTION_LOADING = 'RECEPTION_LOADING';
export const RECEPTION_REQUEST_ERROR = 'RECEPTION_REQUEST_ERROR';

export const RECEPTION_GET_OFFERS = 'RECEPTION_GET_OFFERS';
export const RECEPTION_GET_CLINICS = 'RECEPTION_GET_CLINICS';
export const RECEPTION_GET_CITIES = 'RECEPTION_GET_CITIES';
export const RECEPTION_SAVE = 'RECEPTION_SAVE';

export const RECEPTION_CANCEL = 'RECEPTION_CANCEL';

export const RECEPTION_DETAILS_CLEAR = 'RECEPTION_DETAILS_CLEAR';

export const RECEPTION_OFFERS_LOADING = 'RECEPTION_OFFERS_LOADING';
export const RECEPTION_CITIES_LOADING = 'RECEPTION_CITIES_LOADING';
export const RECEPTION_CLINICS_LOADING = 'RECEPTION_CLINICS_LOADING';
