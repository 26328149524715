import Vue from 'vue';
import VueRouter from 'vue-router';

import TestView from '@/views/TestView';
import ProgramsListView from '@/views/Admin/ProgramParts/ProgramsListView';
import TheProgramsView from '@/views/RenovaDashboard/ProgramsParts/TheProgramsView';
import { USER_ROLES } from '@/store/modules/user';
import {USER_REQUEST} from '@/store/actions/user';
import {checkOptionalUserPermissions, PermissionsTypes} from '@/utils/permissions';
import store from '../store';

Vue.use(VueRouter);

const ifNotAuthenticated = async (to, from, next) => {
  if (!store.getters.authData.isAuthenticated) {
    next();
    return;
  }

  if (to.name === 'hr-login') {
    next('/hr/user-page');
  } else {
    if (!store.getters.userLoaded) {
      await store.dispatch(USER_REQUEST);
    }
    if (store.getters.userRole === 'DOCTOR') {
      next('/admin/tickets');
    } else {
      next('/admin/programs');
    }
  }
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.authData.isAuthenticated) {
    next();
    return;
  }

  if (to.name === 'hr-user-page') {
    next('/hr/login');
  } else {
    next('/login');
  }
};
const checkPermissions = async (to, from, next) => {
  if (!store.getters.permissions) {
    await store.dispatch(USER_REQUEST);
  }
  if (checkOptionalUserPermissions(to.meta.permissions, store.getters.permissions)) {
    next();
    return;
  }
  next(false);
};
function operate(guards, from, to, lastNext, i) {
  const guard = guards[i];
  if (guards.length === i + 1) {
    guard(from, to, lastNext);
  } else {
    guard(from, to, (nextArg) => {
      switch (typeof (nextArg)) {
        case 'undefined':
          operate(guards, from, to, lastNext, i + 1);
          break;
        case 'object':
          lastNext(nextArg);
          break;
        case 'boolean':
          lastNext(nextArg);
          break;
        case 'string':
          lastNext(nextArg);
          break;
        default:
          break;
      }
    });
  }
}
function GuardsCheck(ListOfGuards) {
  return function (from, to, next) {
    operate(ListOfGuards, from, to, next, 0);
  };
}
const routes = [
  {
    path: '/',
    name: 'landingMain',
    meta: {
      layout: window.adminLayout === 'AdminLayout' ? 'LandingLayout' : null,
    },
    component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "tickets" */ '@/views/Landings/MainInsuranceView') : import(/* webpackChunkName: "tickets" */ '@/views/RenovaDashboard/MainRenovaView/MainRenovaView')),
  },
  {
    path: '/health-insurance',
    name: 'healthInsurance',
    meta: {
      layout: 'LandingLayout',
    },
    component: () => import(/* webpackChunkName: "need-insurance" */ '@/views/Landings/NeedInsuranceView'),
  },
  {
    path: '/switch-health-insurance',
    name: 'switchHealthInsurance',
    meta: {
      layout: 'LandingLayout',
    },
    component: () => import(/* webpackChunkName: "have-insurance" */ '@/views/Landings/HaveInsuranceView'),
  },
  {
    path: '/acquisition-insurance',
    name: 'acquisitionInsurance',
    meta: {
      layout: 'LandingLayout',
    },
    component: () => import(/* webpackChunkName: "have-insurance" */ '@/views/Landings/AcquisitionInsuranceView'),
  },
  {
    path: '/insurance-platform',
    name: 'insurancePlatform',
    meta: {
      layout: 'LandingLayout',
    },
    component: () => import(/* webpackChunkName: "have-insurance" */ '@/views/Landings/InsurancePlatformView'),
  },
  {
    path: '/get-app',
    name: 'getApp',
    meta: {
      layout: 'LandingLayout',
    },
    component: () => import(/* webpackChunkName: "have-insurance" */ '@/views/Landings/GetAppView'),
  },
  {
    path: '/update-app',
    name: 'updateApp',
    meta: {
      layout: 'LandingLayout',
    },
    component: () => import(/* webpackChunkName: "have-insurance" */ '@/views/Landings/UpdateAppView'),
  },
  {
    path: '/get-app-iphone',
    name: 'getIphoneApp',
    meta: {
      layout: 'LandingLayout',
    },
    component: () => import(/* webpackChunkName: "have-insurance" */ '@/views/Landings/GetAppIphoneView'),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'WhiteBlancLayout',
    },
    component: () => (window.adminLayout === 'AdminLayout' ? import('@/views/Admin/LoginParts/LoginView') : import('@/views/RenovaDashboard/LoginParts/LoginView')),

    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/hr/login',
    name: 'hr-login',
    meta: {
      layout: 'WhiteBlancLayout',
    },
    component: () => (window.adminLayout === 'AdminLayout' ? import('@/views/Admin/HR/HrLoginView') : import('@/views/RenovaDashboard/LoginParts/LoginView')),

    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/hr/new-password',
    name: 'hr-new-password',
    meta: {
      layout: 'WhiteBlancLayout',
    },
    component: () => (window.adminLayout === 'AdminLayout' ? import('@/views/Admin/LoginParts/NewPasswordView') : import('@/views/RenovaDashboard/LoginParts/NewPasswordView')),
  },
  {
    path: '/new-password',
    name: 'new-password',
    meta: {
      layout: 'WhiteBlancLayout',
    },
    component: () => (window.adminLayout === 'AdminLayout' ? import('@/views/Admin/LoginParts/NewPasswordView') : import('@/views/RenovaDashboard/LoginParts/NewPasswordView')),
  },
  {
    path: '/admin/dashboard',
    name: 'dashboard',
    component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "dashboard" */ '@/views/Admin/DashboardParts/TheDashboardView') : import(/* webpackChunkName: "tickets" */ '@/views/RenovaDashboard/Dashboard/TheDashboardView')),
    meta: {
      layout: window.adminLayout,
      name: 'dashboard',
      path: '/admin/dashboard',
      menuImg: '/img/menu/dashboard.svg',
      renovaDashboardIcon: '/img/renova-dashboard/menu/dashboard.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/dashboard--active.svg',
      menu: true,
      exact: true,
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      permissions: [PermissionsTypes.DASHBOARD_LEADS_VIEW, PermissionsTypes.DASHBOARD_SERVICING_VIEW]
    },
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/tickets',
    component: () => import(/* webpackChunkName: "tickets" */ '@/views/Admin/TicketsParts/TicketsMainView'),
    meta: {
      layout: 'AdminLayout',
      name: 'tickets',
      path: '/admin/tickets',
      menuTitle: 'Сообщения',
      menuImg: '/img/menu/message.svg',
      menuImgActive: '/img/menu/message-a.svg',
      renovaDashboardIcon: '/img/renova-dashboard/menu/requests.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/requests--active.svg',
      menu: true,
      exact: true,
      permissions: [PermissionsTypes.REQUESTS_VIEW]
    },
    children: [
      {
        name: 'tickets',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.REQUESTS_VIEW]
        },
        component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "tickets" */ '@/views/Admin/TicketsParts/TicketsView') : import(/* webpackChunkName: "tickets" */ '@/views/RenovaDashboard/RequestParts/TheRequestsView')),
      },
      {
        name: 'ticketDetail',
        path: '/admin/tickets/:id',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.REQUESTS_VIEW]
        },
        component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "tickets" */ '@/views/Admin/TicketsParts/TicketDetailsView') : import(/* webpackChunkName: "tickets" */ '@/views/RenovaDashboard/RequestParts/TheRequestDetailsView')),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/users',
    component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "users" */ '@/views/Admin/UsersParts/UsersMainView') : import(/* webpackChunkName: "users" */ '@/views/RenovaDashboard/UsersParts/UsersMainView')),
    meta: {
      layout: 'AdminLayout',
      name: window.adminLayout === 'AdminLayout' ? 'users' : 'InsuredList',
      path: '/admin/users',
      menuTitle: 'Пользователи',
      menuImg: '/img/menu/users.svg',
      renovaDashboardIcon: '/img/renova-dashboard/menu/users.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/users--active.svg',
      menu: true,
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      exact: true,
      permissions: [PermissionsTypes.INSURED_VIEW]
    },
    children: [
      {
        name: 'users',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.INSURED_VIEW]
        },
        component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "users" */ '@/views/Admin/UsersParts/UsersView') : import(/* webpackChunkName: "users" */ '@/views/RenovaDashboard/UsersParts/UsersView')),
      },
      {
        name: 'userDetail',
        path: '/admin/users/:id',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.INSURED_VIEW]
        },
        component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "users" */ '@/views/Admin/UsersParts/UserDetailsView') : import(/* webpackChunkName: "users" */ '@/views/RenovaDashboard/UsersParts/UserDetailsView')),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/policies',
    component: () => import(/* webpackChunkName: "policies" */ '@/views/RenovaDashboard/PoliciesParts/PoliciesMainView'),
    meta: {
      layout: 'AdminLayout',
      name: 'Policies',
      path: '/admin/policies',
      renovaDashboardIcon: '/img/renova-dashboard/menu/policies.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/policies--active.svg',
      menu: window.adminLayout !== 'AdminLayout',
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      permissions: [PermissionsTypes.POLICIES_VIEW],
      exact: true,
    },
    children: [
      {
        name: 'policies',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.POLICIES_VIEW],
        },
        component: () => import(/* webpackChunkName: "policies" */ '@/views/RenovaDashboard/PoliciesParts/PoliciesView'),
      },
      {
        name: 'lead',
        path: '/admin/policies/:id',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.POLICIES_VIEW],
        },
        component: () => import(/* webpackChunkName: "policies" */ '@/views/RenovaDashboard/PoliciesParts/PolicyDetailsView'),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/leads',
    component: () => import(/* webpackChunkName: "leads" */ '@/views/RenovaDashboard/LeadsParts/LeadsMainView'),
    meta: {
      layout: 'AdminLayout',
      name: 'Leads',
      path: '/admin/leads',
      renovaDashboardIcon: '/img/renova-dashboard/menu/leads.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/leads--active.svg',
      menu: window.adminLayout !== 'AdminLayout',
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      permissions: [PermissionsTypes.LEADS_VIEW],
      exact: true,
    },
    children: [
      {
        name: 'leads',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.LEADS_VIEW],
        },
        component: () => import(/* webpackChunkName: "leads" */ '@/views/RenovaDashboard/LeadsParts/LeadsView'),
      },
      {
        name: 'lead',
        path: '/admin/leads/:id',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.LEADS_VIEW],
        },
        component: () => import(/* webpackChunkName: "leads" */ '@/views/RenovaDashboard/LeadsParts/LeadDetailsView'),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/tasks',
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/RenovaDashboard/TasksParts/TasksMainView'),
    meta: {
      layout: 'AdminLayout',
      name: 'Tasks',
      path: '/admin/tasks',
      renovaDashboardIcon: '/img/renova-dashboard/menu/tasks.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/tasks--active.svg',
      menu: window.adminLayout !== 'AdminLayout',
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      permissions: [PermissionsTypes.LEADS_VIEW],
      // permissions: [PermissionsTypes.TASKS_VIEW],
      exact: true,
    },
    children: [
      {
        name: 'tasks',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.LEADS_VIEW],
          // permissions: [PermissionsTypes.TASKS_VIEW],
        },
        component: () => import(/* webpackChunkName: "tasks" */ '@/views/RenovaDashboard/TasksParts/TasksView'),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/renewals',
    component: () => import(/* webpackChunkName: "renewals" */ '@/views/RenovaDashboard/RenewalsParts/RenewalsMainView'),
    meta: {
      layout: 'AdminLayout',
      name: 'Renewals',
      path: '/admin/renewals',
      renovaDashboardIcon: '/img/renova-dashboard/menu/renewals.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/renewals--active.svg',
      menu: window.adminLayout !== 'AdminLayout',
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      permissions: [PermissionsTypes.RENEWALS_VIEW],
      exact: true,
    },
    children: [
      {
        name: 'renewals',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.RENEWALS_VIEW],
        },
        component: () => import(/* webpackChunkName: "renewals" */ '@/views/RenovaDashboard/RenewalsParts/RenewalsView'),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/programs',
    component: () => import(/* webpackChunkName: "tickets" */ '@/views/Admin/ProgramParts/ProgramsMainView'),
    meta: {
      layout: 'AdminLayout',
      name: 'programs',
      path: '/admin/programs',
      menuTitle: 'Программы',
      menuImg: '/img/menu/programs.svg',
      renovaDashboardIcon: '/img/renova-dashboard/menu/plans.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/plans--active.svg',
      menu: true,
      exact: true,
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      permissions: [PermissionsTypes.PLANS_VIEW]
    },
    children: [
      {
        name: 'programs',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.PLANS_VIEW]
        },
        component: window.adminLayout === 'AdminLayout' ? ProgramsListView : TheProgramsView,
      },
      {
        name: 'programNew',
        path: '/admin/programs/new',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.PLANS_VIEW]
        },
        component: () => import(/* webpackChunkName: "tickets" */ '@/views/Admin/ProgramParts/ProgramNewView'),
      },
      {
        name: 'programDetail',
        path: '/admin/programs/:id',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.PLANS_VIEW]
        },
        component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "tickets" */ '@/views/Admin/ProgramParts/ProgramDetailsView') : import(/* webpackChunkName: "tickets" */ '@/views/RenovaDashboard/ProgramsParts/TheProgramDetailsView')),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/companies',
    component: () => import(/* webpackChunkName: "tickets" */ '@/views/Admin/Companies/CompaniesMainView'),
    meta: {
      layout: 'AdminLayout',
      name: 'companies',
      path: '/admin/companies',
      menuTitle: 'Компании',
      menuImg: '/img/menu/companies.svg',
      renovaDashboardIcon: '/img/renova-dashboard/menu/companies.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/companies--active.svg',
      menu: true,
      exact: true,
      excludedRoles: [USER_ROLES.DOCTOR],
    },
    children: [
      {
        name: 'companies',
        path: '',
        meta: {
          layout: window.adminLayout,
        },
        component: () => (window.adminLayout === 'AdminLayout' ? import('@/views/Admin/Companies/CompaniesView') : import(/* webpackChunkName: "tickets" */ '@/views/RenovaDashboard/CompaniesParts/TheCompaniesView')),
      },
      {
        name: 'companyNew',
        path: '/admin/companies/new',
        meta: {
          layout: window.adminLayout,
        },
        component: () => import(/* webpackChunkName: "tickets" */ '@/views/Admin/Companies/CompanyNewView'),
      },
      {
        name: 'companyDetails',
        path: '/admin/companies/:id',
        meta: {
          layout: window.adminLayout,
        },
        component: () => (window.adminLayout === 'AdminLayout' ? import('@/views/Admin/Companies/CompanyDetailsView') : import(/* webpackChunkName: "tickets" */ '@/views/RenovaDashboard/CompaniesParts/TheCompanyDetailsView')),
        // component: () => import(/* webpackChunkName: "tickets" */ '@/views/Admin/Companies/CompanyDetailsView'),
      },
    ],
    beforeEnter: ifAuthenticated,
  },
  {
    name: 'hr-user-page',
    path: '/hr/user-page',
    component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "hr" */ '@/views/Admin/HR/HrUserPageView') : import(/* webpackChunkName: "hr" */ '@/views/RenovaDashboard/HR/HrMainView')),
    props: true,
    meta: {
      layout: window.adminLayout === 'AdminLayout' ? '' : 'HrRenovaLayout',
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/admin/demo-programs',
    component: () => import(/* webpackChunkName: "tickets" */ '@/views/TourParts/DemoProgramsMainView'),
    meta: {
      layout: 'AdminLayout',
      name: 'demo-programs',
      path: '/admin/demo-programs',
      menuTitle: 'Программы',
      menuImg: '/img/menu/programs.svg',
      menu: false,
      exact: true,
    },
    children: [
      {
        name: 'demo-programs',
        path: '',
        meta: {
          layout: 'AdminLayout',
        },
        component: () => import(/* webpackChunkName: "tickets" */ '@/views/TourParts/DemoProgramsView'),
      },
      {
        name: 'demo-programDetail',
        path: '/admin/demo-programs/:id',
        meta: {
          layout: 'AdminLayout',
        },
        component: () => import(/* webpackChunkName: "tickets" */ '@/views/TourParts/DemoProgramDetailsView'),
      },
    ],
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/admin/help',
    name: 'adminHelp',
    meta: {
      layout: 'AdminLayout',
      name: 'adminHelp',
      path: '/admin/help',
      menu: false,
      exact: true,
    },
    component: () => import(/* webpackChunkName: "help" */ '@/views/Admin/HelpView'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/admin/settings',
    name: 'adminSettings',
    meta: {
      layout: 'AdminLayout',
      name: 'adminSettings',
      path: '/admin/settings',
      menu: false,
      exact: true,
    },
    beforeEnter: ifAuthenticated,
    component: () => import(/* webpackChunkName: "help" */ '@/views/Admin/SettingsParts/SettingsView'),
  },
  {
    path: '/admin/stories',
    component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "stories" */ '@/views/Admin/StoriesParts/StoriesMainView') : import(/* webpackChunkName: "hr" */ '@/views/RenovaDashboard/StoriesParts/StoriesMainView')),
    meta: {
      layout: window.adminLayout,
      name: 'Stories',
      path: '/admin/stories',
      menuImg: '/img/menu/stories.svg',
      renovaDashboardIcon: '/img/renova-dashboard/menu/stories.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/stories--active.svg',
      menu: true,
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      permissions: [PermissionsTypes.STORIES_VIEW],
      exact: true,
    },
    children: [
      {
        name: 'Stories',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.STORIES_VIEW],
        },
        component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "stories" */ '@/views/Admin/StoriesParts/StoriesView') : import(/* webpackChunkName: "hr" */ '@/views/RenovaDashboard/StoriesParts/StoriesView')),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/chat-bot',
    component: () => import(/* webpackChunkName: "chatbot" */ '@/views/RenovaDashboard/ChatBotParts/ChatBotMainView'),
    meta: {
      layout: window.adminLayout,
      name: 'ChatBot',
      path: '/admin/chat-bot',
      menuImg: '/img/menu/chat-bot.svg',
      renovaDashboardIcon: '/img/renova-dashboard/menu/chat-bot.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/chat-bot--active.svg',
      menu: true,
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      exact: true,
      feature: 'chat-bot',
    },
    children: [
      {
        name: 'ChatBotReplies',
        path: '',
        meta: {
          layout: window.adminLayout,
        },
        component: () => import(/* webpackChunkName: "chatbot" */ '@/views/RenovaDashboard/ChatBotParts/ChatBotView'),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated]),
  },
  {
    path: '/admin/telemed',
    component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "telemed" */ '@/views/Admin/TelemedParts/TelemedMainView') : import(/* webpackChunkName: "telemed" */ '@/views/RenovaDashboard/TelemedParts/TelemedMainView')),
    meta: {
      layout: window.adminLayout,
      name: 'telemed',
      path: '/admin/telemed',
      menuTitle: 'Telemed',
      menuImg: '/img/menu/telemed.svg',
      renovaDashboardIcon: '/img/renova-dashboard/menu/telemed.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/telemed.svg',
      menu: true,
      exact: true,
    },
    children: [
      {
        path: '',
        redirect: '/admin/telemed/doctors',
      },
      {
        name: 'doctors',
        path: '/admin/telemed/doctors/',
        meta: {
          layout: window.adminLayout,
        },
        component: () => (window.adminLayout === 'AdminLayout' ? import(/* webpackChunkName: "telemed" */ '@/views/Admin/TelemedParts/DoctorsViews/DoctorsView') : import(/* webpackChunkName: "telemed" */ '@/views/RenovaDashboard/TelemedParts/TelemedView')),
      },
      {
        name: 'doctorDetail',
        path: '/admin/telemed/doctors/:id',
        meta: {
          layout: window.adminLayout,
        },
        component: () => import(/* webpackChunkName: "telemed" */ '@/views/RenovaDashboard/TelemedParts/TelemedDetailsView'),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated]),
  },
  {
    path: '/admin/operators',
    component: () => import(/* webpackChunkName: "leads" */ '@/views/RenovaDashboard/EmployeesParts/EmployeesMainView'),
    meta: {
      layout: 'AdminLayout',
      name: 'AddOperators',
      path: '/admin/operators',
      renovaDashboardIcon: '/img/renova-dashboard/menu/operators.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/operators--active.svg',
      menu: window.adminLayout !== 'AdminLayout',
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      exact: true,
      menuLinkClass: 'mt_24',
      permissions: [PermissionsTypes.ADD_MEMBERS_VIEW],
    },
    children: [
      {
        name: 'Operators',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.ADD_MEMBERS_VIEW],
        },
        component: () => import(/* webpackChunkName: "operators" */ '@/views/RenovaDashboard/EmployeesParts/EmployeesView'),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/integrations',
    component: () => import(/* webpackChunkName: "leads" */ '@/views/RenovaDashboard/IntegrationsParts/IntegrationsMainView'),
    meta: {
      layout: 'AdminLayout',
      name: 'Integrations',
      path: '/admin/integrations',
      renovaDashboardIcon: '/img/renova-dashboard/menu/integrations.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/integrations--active.svg',
      menu: window.adminLayout !== 'AdminLayout',
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      permissions: [PermissionsTypes.INTEGRATIONS_VIEW],
      exact: true,
    },
    children: [
      {
        name: 'Integrations',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.INTEGRATIONS_VIEW],
        },
        component: () => import(/* webpackChunkName: "integrations" */ '@/views/RenovaDashboard/IntegrationsParts/IntegrationsView'),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/settings-and-members',
    component: () => import(/* webpackChunkName: "employees" */ '@/views/RenovaDashboard/SettingsParts/SettingsMainView'),
    meta: {
      // layout: window.adminLayout,
      name: 'EmployeesSettings',
      path: '/admin/settings-and-members',
      renovaDashboardIcon: '/img/renova-dashboard/menu/settings.svg',
      renovaDashboardActiveIcon: '/img/renova-dashboard/menu/settings--active.svg',
      menu: window.adminLayout === 'RenovaDashboardLayout',
      roles: [USER_ROLES.ADMIN, USER_ROLES.INSURANCE_OPERATOR],
      permissions: [PermissionsTypes.SETTINGS_VIEW],
      exact: true,
      bottomLink: true,
    },
    children: [
      {
        name: 'EmployeesSettings',
        path: '',
        meta: {
          layout: window.adminLayout,
          permissions: [PermissionsTypes.SETTINGS_VIEW],
        },
        component: () => import(/* webpackChunkName: "employees" */ '@/views/RenovaDashboard/SettingsParts/SettingsView'),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/admin/appointments',
    name: 'appointments',
    component: () => import(/* webpackChunkName: "appointments" */ '@/views/Admin/AppointmentsParts/AppointmentsMainView'),
    meta: {
      layout: 'AdminLayout',
      path: '/admin/appointments',
      name: 'appointments',
      menuTitle: 'Appointments',
      menuImg: '/img/menu/appointments.svg',
      menu: window.adminLayout === 'AdminLayout',
      exact: true,
      roles: [USER_ROLES.DOCTOR],
    },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/admin/provider',
    component: () => import(/* webpackChunkName: "provider" */ '@/views/RenovaDashboard/ProviderParts/ProviderMainView'),
    meta: {
      layout: 'RenovaDashboardLayout',
      name: 'provider',
      path: '/admin/provider',
      menu: false,
      exact: true,
      permissions: [PermissionsTypes.MY_PROFILE_VIEW],
    },
    children: [
      {
        name: 'providerDetails',
        path: '/admin/provider/:id',
        meta: {
          layout: 'RenovaDashboardLayout',
          permissions: [PermissionsTypes.MY_PROFILE_VIEW],
        },
        component: () => import(/* webpackChunkName: "tickets" */ '@/views/RenovaDashboard/ProviderParts/ProviderView'),
      },
    ],
    beforeEnter: GuardsCheck([ifAuthenticated, checkPermissions]),
  },
  {
    path: '/checkme-personal_data_processing_policy',
    component: () => import(/* webpackChunkName: "policy" */ '@/views/PersonalDataProcessingPolicyView'),
    meta: {
      layout: 'LandingLayout',
      name: 'checkme-personal_data_processing_policy',
    },
  },
  {
    path: '/privacy_policy',
    component: () => import(/* webpackChunkName: "policy" */ '@/views/PrivacyPolicy'),
    meta: {
      layout: 'LandingLayout',
      name: 'privacyPolicy',
    },
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      layout: 'WhiteBlancLayout',
    },
    component: TestView,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
