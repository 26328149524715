<template>
  <section>
    <section
      v-for="(step, i) in steps"
      :key="i"
    >
      <div
        v-if="i !== (steps.length - 1)"
        class="onboarding__step-item"
        :class="{
            'onboarding__step-item--divider' : i !== steps.length - 2,
            'onboarding__step-item--active' : i === currentStepIndex,
          }"
      >
        <div class="onboarding__step-item-status">
          <img
            v-if="i < currentStepIndex || allStepsCompleted"
            alt="'done'"
            src="/img/renova-dashboard/icons/check.svg"
          />
          <span
            v-if="i >= currentStepIndex && !allStepsCompleted"
            class="caption--semibold color--white">
                {{ i + 1 }}
              </span>
        </div>
        <span class="body-text color--white">{{ $t(stepsInfo[step].stepTitle) }}</span>
      </div>
    </section>
  </section>
</template>
<script>
import { stepsInfo } from '@/views/RenovaDashboard/OnboardingParts/onboarding-steps';

export default {
  name: 'OnboardingSteps',
  props: {
    steps: {
      type: Array,
    },
    currentStepIndex: {
      type: Number,
    },
    allStepsCompleted: {
      type: Boolean,
    }
  },
  data() {
    return {
      stepsInfo,
    };
  },
};
</script>

<style lang="scss">
.onboarding__step-item {
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  position: relative;
  opacity: 0.5;
  align-items: center;
  transition: .3s;

  &--divider:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 20px;
    background: #FFFFFF;
    left: 11px;
    bottom: -20px;
    opacity: 0.5;
  }

  &--active {
    opacity: 1;
  }
}

.onboarding__step-item-status {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  border: 1px solid #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: -1px;
  }
}
</style>
