<template>
  <div class="hr-wrapper">
    <HrHeader></HrHeader>
    <slot></slot>
  </div>
</template>

<script>
import HrHeader from '@/views/RenovaDashboard/HR/components/HrHeader';

export default {
  name: 'HrRenovaLayout',
  components: {HrHeader},
  beforeMount() {
    this.setupStyles();
  },
  methods: {
    setupStyles() {
      if (window.adminLayout === 'RenovaDashboardLayout') {
        import('@/assets/scss/renova/renova-ui-elements.scss');
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/renova/fonts.scss";
</style>
