<template>
  <div class="wrapper landing-main">
    <landing-top-bar />

    <slot />

    <landing-footer />
  </div>
</template>

<script>
import LandingTopBar from '@/views/Landings/common-blocks/landing-top-bar';
import LandingFooter from '@/views/Landings/common-blocks/landing-footer';

export default {
  name: 'LandingLayout',
  components: { LandingFooter, LandingTopBar },
  created() {
    // if root path and hostname is admin.renova.world will redirect to /login
    if (this.$route.path === '/' && window.location.hostname === 'admin.renova.world') {
      this.$router.push('/login');
    }
  },
};
</script>

<style scoped>

</style>
