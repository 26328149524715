import Vue from 'vue';
import Vuex from 'vuex';
import hardcode from '@/store/modules/hardcode';
import clientsChat from '@/store/modules/clientsChat';
import Renewals from '@/store/modules/renewals';
import Tasks from '@/store/modules/tasks';
import Tags from '@/store/modules/tags';
import Dictionary from './modules/dictionary';
import Auth from './modules/auth';
import TicketList from './modules/ticketList';
import Users from './modules/users';
import Programs from './modules/programs';
import Chat from './modules/chat';
import Receptions from './modules/reception';
import Files from './modules/files';
import User from './modules/user';
import HrUserPage from './modules/hr-user-page';
import Offers from './modules/offers';
import ClientCompanies from './modules/client-companies';
import ClientCompaniesGrants from './modules/client-companies-grants';
import Clinics from './modules/clinics';
import Specializations from './modules/specializations';
import Doctors from './modules/doctors';
import Doctor from './modules/doctor';
import Dashboard from './modules/dashboard';
import Stories from './modules/stories';
import HrDashboard from './modules/hr-dashboard';
import Employees from './modules/employees';
import Onboarding from './modules/onboarding';
import Leads from './modules/leads';
import Policies from './modules/policies';
import InsuranceCompanies from './modules/insurance-companies';
import ChatBot from './modules/chat-bot';
import Telemed from './modules/telemed';
import UserSettings from './modules/userSettings';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    TicketList,
    Users,
    Dictionary,
    Chat,
    clientsChat,
    Receptions,
    Files,
    hardcode,
    Programs,
    User,
    HrUserPage,
    ClientCompanies,
    ClientCompaniesGrants,
    Offers,
    Clinics,
    Specializations,
    Doctors,
    Doctor,
    Dashboard,
    Stories,
    HrDashboard,
    Employees,
    Onboarding,
    Leads,
    Renewals,
    Policies,
    Tasks,
    InsuranceCompanies,
    ChatBot,
    Telemed,
    UserSettings,
    Tags,
  },
});
