<template>
  <emoji-picker class="emoji-invoker__button" @emoji="addEmoji">
    <button
      class="emoji-invoker"
      slot="emoji-invoker"
      slot-scope="{ events: { click: clickEvent } }"
      @click.stop="clickEvent"
    >
      <svg width="20" height="20">
        <use xlink:href="/img/svg/editor.svg#emoji"/>
      </svg>
    </button>

    <div slot="emoji-picker" slot-scope="{ emojis, insert }">
      <vue-custom-scrollbar :settings="settings" class="scroll-area emoji-picker">
        <div v-for="(emojiGroup, category) in filterEmojis(emojis)" :key="category">

          <p class="text_16 text_dark text_b mb_8">
            {{ emojiGroup.title }}
          </p>

          <div class="emojis mb_24">
                <span
                  v-for="(emoji, emojiName) in emojiGroup.emojis"
                  :key="emojiName"
                  @click="insert(emoji)"
                  :title="emoji"
                >{{ emoji }}</span>
          </div>
        </div>
      </vue-custom-scrollbar>
    </div>
  </emoji-picker>
</template>

<script>
import EmojiPicker from 'vue-emoji-picker';

export default {
  name: 'mwe-emoji-picker',
  components: { EmojiPicker },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
    };
  },
  methods: {
    addEmoji(emoji) {
      this.$emit('addEmoji', emoji);
    },
    filterEmojis(emojis) {
      return {
        frequentlyUsed: {
          emojis: emojis['Frequently used'],
        },
        peoples: {
          emojis: emojis.People,
        },
      };
    },
  },
};
</script>

<style scoped>

</style>
