<template>
  <div :class="`loader-container loader-container--${size}`">
    <span :class="`loader loader--${color}`"></span>
  </div>
</template>

<script>
export default {
  name: 'BasePreloader',
  props: {
    size: {
      type: String,
      default: 's',
      validate: (value) => ['s', 'm', 'l'].includes(value),
    },
    color: {
      type: String,
      default: 'white',
      validate: (value) => ['white', 'purple'].includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;

  &--s {
    width: 16px;
    height: 16px;
    .loader {
      font-size: 2px;
    }
  }

  &--m {
    width: 24px;
    height: 24px;
    .loader {
      font-size: 3px;
    }
  }

  &--l {
    width: 32px;
    height: 32px;
    .loader {
      font-size: 5px;
    }
  }
}

.loader {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  &--purple{
    animation: mulShdSpinPurple 1.1s infinite ease;
  }
  &--white{
    animation: mulShdSpin 1.1s infinite ease;
  }
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes mulShdSpinPurple {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em var(--solid-purple), 1.8em -1.8em 0 0em var(--solid-purple-02), 2.5em 0em 0 0em var(--solid-purple-02), 1.75em 1.75em 0 0em var(--solid-purple-02), 0em 2.5em 0 0em var(--solid-purple-02), -1.8em 1.8em 0 0em var(--solid-purple-02), -2.6em 0em 0 0em var(--solid-purple-05), -1.8em -1.8em 0 0em var(--solid-purple-07);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em var(--solid-purple-07), 1.8em -1.8em 0 0em var(--solid-purple), 2.5em 0em 0 0em var(--solid-purple-02), 1.75em 1.75em 0 0em var(--solid-purple-02), 0em 2.5em 0 0em var(--solid-purple-02), -1.8em 1.8em 0 0em var(--solid-purple-02), -2.6em 0em 0 0em var(--solid-purple-02), -1.8em -1.8em 0 0em var(--solid-purple-05);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em var(--solid-purple-05), 1.8em -1.8em 0 0em var(--solid-purple-07), 2.5em 0em 0 0em var(--solid-purple), 1.75em 1.75em 0 0em var(--solid-purple-02), 0em 2.5em 0 0em var(--solid-purple-02), -1.8em 1.8em 0 0em var(--solid-purple-02), -2.6em 0em 0 0em var(--solid-purple-02), -1.8em -1.8em 0 0em var(--solid-purple-02);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em var(--solid-purple-02), 1.8em -1.8em 0 0em var(--solid-purple-05), 2.5em 0em 0 0em var(--solid-purple-07), 1.75em 1.75em 0 0em var(--solid-purple), 0em 2.5em 0 0em var(--solid-purple-02), -1.8em 1.8em 0 0em var(--solid-purple-02), -2.6em 0em 0 0em var(--solid-purple-02), -1.8em -1.8em 0 0em var(--solid-purple-02);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em var(--solid-purple-02), 1.8em -1.8em 0 0em var(--solid-purple-02), 2.5em 0em 0 0em var(--solid-purple-05), 1.75em 1.75em 0 0em var(--solid-purple-07), 0em 2.5em 0 0em var(--solid-purple), -1.8em 1.8em 0 0em var(--solid-purple-02), -2.6em 0em 0 0em var(--solid-purple-02), -1.8em -1.8em 0 0em var(--solid-purple-02);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em var(--solid-purple-02), 1.8em -1.8em 0 0em var(--solid-purple-02), 2.5em 0em 0 0em var(--solid-purple-02), 1.75em 1.75em 0 0em var(--solid-purple-05), 0em 2.5em 0 0em var(--solid-purple-07), -1.8em 1.8em 0 0em var(--solid-purple), -2.6em 0em 0 0em var(--solid-purple-02), -1.8em -1.8em 0 0em var(--solid-purple-02);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em var(--solid-purple-02), 1.8em -1.8em 0 0em var(--solid-purple-02), 2.5em 0em 0 0em var(--solid-purple-02), 1.75em 1.75em 0 0em var(--solid-purple-02), 0em 2.5em 0 0em var(--solid-purple-05), -1.8em 1.8em 0 0em var(--solid-purple-07), -2.6em 0em 0 0em var(--solid-purple), -1.8em -1.8em 0 0em var(--solid-purple-02);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em var(--solid-purple-02), 1.8em -1.8em 0 0em var(--solid-purple-02), 2.5em 0em 0 0em var(--solid-purple-02), 1.75em 1.75em 0 0em var(--solid-purple-02), 0em 2.5em 0 0em var(--solid-purple-02), -1.8em 1.8em 0 0em var(--solid-purple-05), -2.6em 0em 0 0em var(--solid-purple-07), -1.8em -1.8em 0 0em var(--solid-purple);
  }
}

</style>
