export const INSURANCE_TYPES = {
  HEALTH_INSURANCE: 'HEALTH_INSURANCE',
  LIFE_INSURANCE: 'LIFE_INSURANCE',
  AUTO_INSURANCE: 'AUTO_INSURANCE',
  HOMEOWNERS_INSURANCE: 'HOMEOWNERS_INSURANCE',
  BUSINESS_INSURANCE: 'BUSINESS_INSURANCE',
  TRAVEL_INSURANCE: 'TRAVEL_INSURANCE',
  INVESTMENT_INSURANCE: 'INVESTMENT_INSURANCE',
};
export const PLAN_TYPES = {
  // FINANCIAL: 'FINANCIAL',
  CORPORATE: 'CORPORATE',
  INDIVIDUAL: 'INDIVIDUAL',
};
export const PLAN_TYPES_NAMES = {
  [PLAN_TYPES.CORPORATE]: 'adminTitles.GroupPlan',
  [PLAN_TYPES.INDIVIDUAL]: 'adminTitles.IndividualPlan',
};
export const INSURANCE_TYPES_NAMES = {
  [INSURANCE_TYPES.HEALTH_INSURANCE]: 'programTypes.HEALTH_INSURANCE',
  [INSURANCE_TYPES.LIFE_INSURANCE]: 'programTypes.LIFE_INSURANCE',
  [INSURANCE_TYPES.AUTO_INSURANCE]: 'programTypes.AUTO_INSURANCE',
  [INSURANCE_TYPES.HOMEOWNERS_INSURANCE]: 'programTypes.HOMEOWNERS_INSURANCE',
  [INSURANCE_TYPES.BUSINESS_INSURANCE]: 'programTypes.BUSINESS_INSURANCE',
  [INSURANCE_TYPES.TRAVEL_INSURANCE]: 'programTypes.TRAVEL_INSURANCE',
  [INSURANCE_TYPES.INVESTMENT_INSURANCE]: 'programTypes.INVESTMENT_INSURANCE',
};
export const PlanTypeOptions = {
  data() {
    return {
      INSURANCE_TYPES_OPTIONS: [
        {
          id: INSURANCE_TYPES.HEALTH_INSURANCE,
          name: this.$t(INSURANCE_TYPES_NAMES[INSURANCE_TYPES.HEALTH_INSURANCE])
        },
        {
          id: INSURANCE_TYPES.LIFE_INSURANCE,
          name: this.$t(INSURANCE_TYPES_NAMES[INSURANCE_TYPES.LIFE_INSURANCE])
        },
        {
          id: INSURANCE_TYPES.AUTO_INSURANCE,
          name: this.$t(INSURANCE_TYPES_NAMES[INSURANCE_TYPES.AUTO_INSURANCE])
        },
        {
          id: INSURANCE_TYPES.HOMEOWNERS_INSURANCE,
          name: this.$t(INSURANCE_TYPES_NAMES[INSURANCE_TYPES.HOMEOWNERS_INSURANCE])
        },
        {
          id: INSURANCE_TYPES.BUSINESS_INSURANCE,
          name: this.$t(INSURANCE_TYPES_NAMES[INSURANCE_TYPES.BUSINESS_INSURANCE])
        },
        {
          id: INSURANCE_TYPES.TRAVEL_INSURANCE,
          name: this.$t(INSURANCE_TYPES_NAMES[INSURANCE_TYPES.TRAVEL_INSURANCE])
        },
        {
          id: INSURANCE_TYPES.INVESTMENT_INSURANCE,
          name: this.$t(INSURANCE_TYPES_NAMES[INSURANCE_TYPES.INVESTMENT_INSURANCE])
        },
      ],
      PLAN_TYPES_OPTIONS: [
        {
          id: PLAN_TYPES.CORPORATE,
          name: this.$t(PLAN_TYPES_NAMES[PLAN_TYPES.CORPORATE])
        },
        {
          id: PLAN_TYPES.INDIVIDUAL,
          name: this.$t(PLAN_TYPES_NAMES[PLAN_TYPES.INDIVIDUAL])
        },
      ],
    };
  },
};
