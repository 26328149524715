<template>
  <th>
    <slot/>
  </th>
</template>

<script>
export default {
  name: 'BaseTableColumn',
  inject: ['selectable'],
};
</script>

<style scoped>

</style>
