export const OFFERS_LIST_REQUEST = 'OFFERS_LIST_REQUEST';
export const OFFERS_LIST_SUCCESS = 'OFFERS_LIST_SUCCESS';

export const OFFERS_LIST_BY_PROGRAM_REQUEST = 'OFFERS_LIST_BY_PROGRAM_REQUEST';
export const OFFERS_LIST_BY_PROGRAM_SUCCESS = 'OFFERS_LIST_BY_PROGRAM_SUCCESS';

export const OFFERS_LIST_BY_INSURANCE_REQUEST = 'OFFERS_LIST_BY_INSURANCE_REQUEST';
export const OFFERS_LIST_BY_INSURANCE_SUCCESS = 'OFFERS_LIST_BY_INSURANCE_SUCCESS';

export const OFFER_DETAILS_REQUEST = 'OFFER_DETAILS_REQUEST';
export const OFFER_DETAILS_SUCCESS = 'OFFER_DETAILS_SUCCESS';

export const OFFER_SAVE_REQUEST = 'OFFER_SAVE_REQUEST';

export const OFFER_COPY = 'OFFER_COPY';

export const OFFER_ADD_CLINICS_FROM_FILE = 'OFFER_ADD_CLINICS_FROM_FILE';

export const OFFERS_LIST_FILTERS_RESET = 'OFFERS_LIST_FILTERS_RESET';

export const CHANGE_OFFER_BY_PROGRAM_DESCRIPTION_COLLAPSE = 'CHANGE_OFFER_BY_PROGRAM_DESCRIPTION_COLLAPSE';
export const CHANGE_OFFER_BY_INSURANCE_DESCRIPTION_COLLAPSE = 'CHANGE_OFFER_BY_INSURANCE_DESCRIPTION_COLLAPSE';
export const RESET_OFFERS_STORE = 'RESET_OFFERS_STORE';
