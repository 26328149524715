<template>
    <el-input
      v-model="searchValue"
      :placeholder="placeholder || $t('adminTitles.search')"
      class="input-search"
      :class="{'input-search--opened': opened}"
      clearable
      @focus="$emit('focus')"
    />
</template>

<script>
export default {
  name: 'InputSearch',
  emits: ['searchUpdate', 'focus'],
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    searchValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('searchUpdate', newVal);
      }
    }
  },
};
</script>

<style lang="scss">
.el-input {
  &.input-search {
    transition: width 0.3s ease-in-out;
    width: 40px !important;
    input {
      padding-right: 22px;
      border:1px solid var(--solid-lavender-grey) !important;
      &::placeholder {
        color: transparent;
      }
    }
    &:before {
      content: '';
      position: absolute;
      background-image: url(/public/img/renova-dashboard/icons/search.svg);
      width: 40px;
      height: 40px;
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      pointer-events: none;
    }
  }
  &.input-search:hover, &.input-search:has(:focus, input:not(:placeholder-shown)), &.input-search--opened {
    width: 240px !important;
    margin-right: 0;
    input {
      padding-right: 16px;
      padding-left: 35px;
      color: var(--solid-black-purple);
      &::placeholder {
        color: var(--solid-dark-purple);
      }
    }
    &:before {
      //content: none;
    }
  }
  .el-input__suffix{
    .el-icon-circle-close{
      color: var(--solid-black-purple);
      &:before{
        content: "\e6db" !important;
      }
    }
  }
}
</style>
