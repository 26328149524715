<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :show-close="false"
    :custom-class="dialogClass"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >

    <div class="greeting-demo__st-1" v-if="stage === 1">
      <div>
        <img src="/img/misc/mac-min.png" class="mb_16" alt="">

        <p class="text_16 text_dark mb_16">
          {{$t('adminHelps.greeting.text1')}}
        </p>

        <div style="margin-bottom: 26px" class="flex flex-ac">
          <div class="greeting-demo__dot" />

          <p class="text_16 text_dark ml_4">
            {{$t('adminHelps.greeting.text2')}}
          </p>
        </div>

        <div class="flex flex-ac">
          <div class="greeting-demo__dot" />

          <p class="text_16 text_dark ml_4">
            {{$t('adminHelps.greeting.text3')}}
          </p>
        </div>
      </div>

      <div class="login__block-demo">
        <img class="mb_24" :src="`/img/${$companyName}-logo.svg`" alt="">

        <p class="text_16 text_grey mb_24" v-html="$t('adminHelps.greeting.text4')" />

        <el-form>
          <el-input :class="{'input-error': errors.company}" class="mb_16 b-r_8"
                    v-model="request.company" :placeholder="$t('placeholders.company')" />

          <el-select v-model="selectedCountryId" class="b-r_8 mb_16" :placeholder="$t('placeholders.country')" @change="selectCountry">
            <el-option
              v-for="item in phoneCodes.codes"
              :key="item.id"
              :label="$t(`countries.titles.${item.phoneRegion}`)"
              :value="item.id">
            </el-option>
          </el-select>

          <div class="l-send-request__phone mb_24 b-r_8" :class="{'block-error': errors.phone}">
            <p v-if="selectedCountryId" class="text_14 text_dark">
              {{phoneCodes.selectedCountry.phoneCode}}
            </p>

            <el-input ref="phone" v-model="request.phone" :disabled="!selectedCountryId"
                      v-mask="phoneCodes.selectedCountry.phoneMobileMask"
                      :placeholder="$t('placeholders.phoneRepresentative')" class="b-r_8" />
          </div>

          <el-button type="primary" :loading="loading" :disabled="loading" class="el-button--wide b-r_8" @click="sendRequest">
            {{ $t('buttons.startDemo') }}
          </el-button>
        </el-form>
      </div>
    </div>

    <div v-if="stage === 2">
      <p class="text_22 text_dark text_b mb_24">
        {{$t('adminHelps.title')}}
      </p>

      <p class="text_16 text_blue text_b">{{$t('adminTitles.step')}} 1.</p>

      <p class="text_16 text_dark text_b">
        {{$t('adminHelps.block1.title')}}
      </p>

      <p class="text_16 text_grey mb_32">
        {{$t('adminHelps.block1.text')}}
      </p>

      <div class="greeting-demo__get-apps mb_24">
        <img src="/img/misc/get-app-qr.svg" alt="">

        <img src="/img/misc/apps-vertical.svg" alt="">
      </div>

      <el-button type="primary" class="el-button--wide el-button--wide b-r_8" @click="stage = 3">
        {{$t('buttons.completeDownload')}}
      </el-button>
    </div>

    <div v-if="stage === 3">
      <p class="text_16 text_blue text_b">{{$t('adminTitles.step')}} 2.</p>

      <p class="text_16 text_dark text_b">
        {{$t('adminHelps.block2.title')}}
      </p>

      <p class="text_16 text_grey mb_24">
        {{$t('adminHelps.block2.text')}}
      </p>

      <div class="greeting-demo__login-info mb_16">
        <p class="text_16 text_grey">E-mail:</p>
        <p class="text_16 text_dark text_b mb_16">demo@checkme.tech</p>

        <p class="text_16 text_grey">{{$t('adminTitles.code')}}:</p>
        <p class="text_16 text_dark text_b">1234</p>
      </div>

      <el-button type="primary" class="el-button--wide el-button--wide b-r_8" @click="closeModal">
        {{$t('buttons.begin')}}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_PHONE_CODE } from '@/store/actions/dictionary';
import RequestsStore from '@/stores/requests';
import masker from 'vue-the-mask/src/masker';
import { tokens } from 'vue-the-mask';
import { AUTH_REQUEST } from '@/store/actions/auth';

function Request(country) {
  this.phone = '';
  this.company = '';
  this.country = country;
  this.requestType = 'INSURANCE_FORM';
}

export default {
  name: 'demo-greeting',
  data() {
    return {
      dialogVisible: false,
      stage: 1,
      request: null,
      selectedCountryId: null,
      errors: {
        phone: false,
        company: false,
      },
      loading: false,
    };
  },
  created() {
    this.setDefault();
  },
  mounted() {
    this.dialogVisible = localStorage.getItem('demo') && !localStorage.getItem('greeting-shown');
  },
  watch: {
    'phoneCodes.selectedCountry': function () { // необходимо чтобы привести в соответствие форму в тайтле и в модалке
      this.selectedCountryId = this.phoneCodes.selectedCountry.id;
      this.request.country = this.phoneCodes.selectedCountry.phoneRegion;
    },
  },
  computed: {
    ...mapGetters(['phoneCodes']),
    dialogClass() {
      return `greeting-demo ${this.stage === 1 ? 'greeting-demo-wide' : ''}`;
    },
  },
  methods: {
    closeModal() {
      this.dialogVisible = false;
      localStorage.setItem('greeting-shown', true);
    },
    sendRequest() {
      this.errors.phone = this.unmask(this.request.phone, this.phoneCodes.selectedCountry.phoneMobileMask).length < this.phoneCodes.selectedCountry.phoneLength;
      this.errors.company = this.request.company.length < 3;

      const allValid = Object.values(this.errors).every((value) => !value);

      if (allValid) {
        const request = JSON.parse(JSON.stringify(this.request));
        request.phone = this.phoneCodes.selectedCountry.phoneCode + this.request.phone;

        this.loading = true;

        RequestsStore.methods.dmsRequest(request).then(() => {
          this.loading = false;

          this.stage = 2;
        })
          .catch(() => {
            this.loading = false;

            this.$message({
              message: `${this.$t('adminTitles.someError')}`,
              duration: 5000,
              center: true,
              customClass: 'l-request__message l-request__message--error',
            });
          });
      }
    },
    unmask(maskedValue, mask) {
      return masker(maskedValue, mask, false, tokens);
    },
    setDefault() {
      this.request = new Request(
        this.phoneCodes.selectedCountry.phoneRegion,
      );
      this.selectedCountryId = this.phoneCodes.selectedCountry.id;
      this.request.country = this.phoneCodes.selectedCountry.phoneRegion;
    },
    selectCountry() {
      this.$store.commit(SET_PHONE_CODE, this.phoneCodes.codes.filter((item) => item.id === this.selectedCountryId)[0]); // выбор в одном месте - выбор везде

      this.$nextTick(() => {
        this.request.phone = '';
        this.$refs.phone.focus();
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
