<template>
  <div class="onboarding-company">
    <p class="h2 mb_12 color--black-purple text_ac">
      {{ $t('onboarding.AddCompanyDetails') }}
    </p>
    <p class="body-text mb_32 onboarding-company__description"
       v-html="$t('onboarding.SeeHowYourCompanyAppear')">
    </p>
    <el-form
      ref="form"
      :model="form"
      label-width="120px"
      label-position="top"
      class="onboarding-company__form"
      @submit.native.prevent
    >
      <el-form-item
        :label="$t('adminTitles.companyName')"
        prop="title"
        class="story-modal__input"
      >
        <el-input
          v-model="form.name"
          :maxlength="40"
          :placeholder="$t('adminTitles.enterCompanyName')"
          @change="updateOnboardingCompany({name: $event})"
        />
      </el-form-item>
      <el-form-item
        :label="$t('adminTitles.companyLogo')"
        class="story-modal__input"
        prop="backgroundImageUrl"
      >
        <BaseDocument
          :file-upload-view="!form.logoCdnFile"
          :type="'logo'"
          :name="file ? file.name : null"
          :link="form.logoCdnFile ? form.logoCdnFile.url : null"
          :delete-btn="!!form.logoCdnFile"
          :loading="fileLoading"
          :accept-formats="'.jpeg, .jpg, .png'"
          :accept-formats-text="'JPG, PNG'"
          @fileChange="uploadFile"
          @delete="deleteFile"
        ></BaseDocument>
      </el-form-item>
    </el-form>
    <BaseButton
      :loading="btnLoading"
      :disabled="!form.name"
      :full-width="true"
      class="mt_32 mb_16"
      @click="$emit('submitStep')"
    >
      {{ $t('adminTitles.continue') }}
    </BaseButton>
    <BaseTextButton
      @click="skipStep"
      class="color--purple">
      {{ $t('onboarding.SkipForNow') }}
    </BaseTextButton>
  </div>
</template>

<script>
import BaseTextButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseTextButton';
import BaseDocument from '@/views/RenovaDashboard/components/ui-kit/Cards/BaseDocument';
import { SAVE_FILE_TO_CDN_COMMON } from '@/store/actions/files';
import { UPDATE_ONBOARDING } from '@/store/actions/onboarding';
import { mapGetters } from 'vuex';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';

export default {
  name: 'OnboardingCompanyStep',
  components: {
    BaseButton,
    BaseDocument,
    BaseTextButton
  },
  emits: ['skip', 'submitStep'],
  props: {
    btnLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      file: null,
      fileLoading: false,
      form: {
        logoCdnFile: null,
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters(['user', 'onboarding', 'onboardingInsuranceCompany']),
  },
  mounted() {
    this.setInitialCompany();
  },
  methods: {
    setInitialCompany() {
      if (this.onboarding.company) {
        this.form = this.onboarding.company;
      }
    },
    uploadFile(e) {
      this.fileLoading = true;
      [this.file] = e.target.files;
      this.$store.dispatch(SAVE_FILE_TO_CDN_COMMON, {
        file: this.file,
        type: 'IMAGE',
        name: this.file.name.split('.')[0],
      }).then((logoCdnFile) => {
        this.form.logoCdnFile = logoCdnFile;
        this.fileLoading = false;
        this.updateOnboardingCompany({logoCdnFile});
      });
    },
    updateOnboardingCompany(value) {
      this.$store.dispatch(UPDATE_ONBOARDING, {
        company: {
          ...this.onboarding.company,
          ...value
        },
      });
    },
    deleteFile() {
      this.updateOnboardingCompany({logoCdnFile: null});
      this.form.logoCdnFile = null;
      this.file = null;
    },
    skipStep() {
      this.form = {
        logoCdnFile: this.onboardingInsuranceCompany.logoCdnFile,
        name: this.onboardingInsuranceCompany.name,
      };
      this.updateOnboardingCompany(this.form);
      this.$emit('skip');
    },
  },
};
</script>

<style lang="scss">
.onboarding-company {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 70px 0px 70px;
  align-items: center;
}

.onboarding-company__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.onboarding-company__description {
  line-height: 20px !important;
  color: var(--solid-black-purple);
  text-align: center;
}
</style>
