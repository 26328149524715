import axios from 'axios';
import i18n from '@/i18n';
import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  GET_HR_AUTH_TOKEN,
  GET_HR_PHONE_TOKEN,
  AUTH_HR_REQUEST,
  GET_USER_OTP_REQUEST,
  GET_USER_OTP_SUCCESS,
  GET_USER_OTP_ERROR,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_ERROR,
  PASSWORD_CHANGE_EMAIL_REQUEST,
  PASSWORD_CHANGE_EMAIL_ERROR,
  PASSWORD_CHANGE_EMAIL_SUCCESS,
} from '../actions/auth';

import { USER_REQUEST } from '../actions/user';

const state = {
  authData: {
    isAuthenticated: !!localStorage.getItem('ins-token'),
    loading: false,
  },
  authToken: '',
  authSerialNumber: '',
  token: '',
  userPhone: { phone: '', formattedPhone: '' },
  otpInfo: null,
};
const clearStorage = () => {
  localStorage.removeItem('programsFilters');
  // localStorage.removeItem('tickets');
  localStorage.removeItem('clientCompaniesSearchQuery');
  localStorage.removeItem('ticketListFilters');
  localStorage.removeItem('usersListFilters');
};
const getters = {
  authData: () => state.authData,
  authToken: () => state.authToken,
  authSerialNumber: () => state.authSerialNumber,
  userPhone: () => state.userPhone,
  otpInfo: () => state.otpInfo,
};

const actions = {
  [AUTH_REQUEST]({ commit }, user) {
    commit(AUTH_REQUEST);
    return axios.post('/insurance/login', user)
      .then((resp) => {
        localStorage.setItem('ins-token', resp.data.secret);
        axios.defaults.headers.common.Authorization = `Bearer ${resp.data.secret}`;
        commit(AUTH_SUCCESS);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(AUTH_ERROR);
        localStorage.removeItem('ins-token');
        return Promise.reject(err);
      });
  },
  [GET_HR_AUTH_TOKEN]({ commit, dispatch }, request) {
    const params = request.call ? `call=${request.call}&phone=${request.phone}&email=${request.email}`
      : `call=${request.call}&email=${request.email}`;
    return axios.get(`/user/code?${params}`, {
      headers: {
        'X-Locale': i18n.locale,
      },
    })
      .then((resp) => {
        commit(GET_HR_AUTH_TOKEN, {
          request,
          authToken: resp.data.token,
          authSerialNumber: resp.data.serialNumber,
        });
      });
  },
  [AUTH_HR_REQUEST]({ commit, dispatch }, user) {
    commit(AUTH_REQUEST);
    return axios.post('/user/login', user)
      .then((resp) => {
        localStorage.setItem('ins-token', resp.data.secret);
        axios.defaults.headers.common.Authorization = `Bearer ${resp.data.secret}`;
        commit(AUTH_SUCCESS);
      })
      .catch((err) => {
        commit(AUTH_ERROR, err);
        localStorage.removeItem('ins-token');
        return Promise.reject(err.response.data);
      });
  },
  [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve) => {
    localStorage.removeItem('ins-token');
    delete axios.defaults.headers.common.Authorization;
    commit(AUTH_LOGOUT);
    resolve();
  }),
  [GET_USER_OTP_REQUEST]({ commit, dispatch }, otpId) {
    commit(GET_USER_OTP_REQUEST);
    return axios.post('/auth/otp/check', {
      otpId,
    })
      .then((resp) => {
        commit(GET_USER_OTP_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(GET_USER_OTP_ERROR, err);
        return Promise.reject(err.response.data);
      });
  },
  [PASSWORD_CHANGE_REQUEST]({ commit, dispatch }, data) {
    commit(GET_USER_OTP_REQUEST);
    return axios.post('/auth/password/save', data)
      .then((resp) => {
        commit(PASSWORD_CHANGE_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(PASSWORD_CHANGE_ERROR, err);
        return Promise.reject(err.response.data);
      });
  },
  [PASSWORD_CHANGE_EMAIL_REQUEST]({ commit, dispatch }, data) {
    commit(PASSWORD_CHANGE_EMAIL_REQUEST);
    return axios.post(
      '/auth/password/recovery',
      data,
      {
        headers: {
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(PASSWORD_CHANGE_EMAIL_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(PASSWORD_CHANGE_EMAIL_ERROR, err);
        return Promise.reject(err.response.data);
      });
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.authData.loading = true;
  },
  [AUTH_SUCCESS]: (state) => {
    state.authData.loading = false;
    state.authData.isAuthenticated = true;
  },
  [AUTH_ERROR]: (state) => {
    state.authData.loading = false;
  },
  [AUTH_LOGOUT]: (state) => {
    state.authData.isAuthenticated = false;
    clearStorage();
  },
  [GET_USER_OTP_SUCCESS]: (state, data) => {
    state.authData.loading = false;
    state.otpInfo = data;
  },
  [GET_USER_OTP_ERROR]: (state, err) => {
    state.authData.loading = false;
    state.otpInfo = {
      email: 'test-operator-002@mail.com',
      firstName: 'FN4',
      lastName: 'LN4',
      otp: 'a192a915-75d1-425a-9546-288b0434149a',
    };
  },
  [PASSWORD_CHANGE_SUCCESS]: (state, data) => {
    state.authData.loading = false;
    state.otpInfo = null;
  },
  [PASSWORD_CHANGE_ERROR]: (state, err) => {
    state.authData.loading = false;
  },

  [GET_HR_AUTH_TOKEN]: (state, data) => {
    state.authToken = data.authToken;
    state.authSerialNumber = data.authSerialNumber;
    state.userPhone.phone = data.request.phone;
    state.userPhone.formattedPhone = data.request.formattedPhone;
  },
  [PASSWORD_CHANGE_EMAIL_SUCCESS]: (state, data) => {
  },
  [PASSWORD_CHANGE_EMAIL_ERROR]: (state, data) => {
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
