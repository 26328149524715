import axios from 'axios';

const initialState = {
  doctor: null,
  slots: [],
};
const state = { ...initialState };

const getters = {
  doctor: (state) => state.doctor,
  slots: (state) => state.slots,
};
const mutations = {
  SET_DOCTOR(state, doctor) {
    state.doctor = doctor;
  },
  SET_SLOTS(state, slots) {
    state.slots = slots;
  },
  RESET_STORE(state) {
    Object.assign(state, initialState);
  },
};
const actions = {
  async createDoctor({ dispatch }, doctor) {
    try {
      await axios.post('/insurance/telemedicine/doctor/save', doctor);
      dispatch('fetchDoctors');
    } catch (error) {
      console.error('Error creating doctor:', error);
    }
  },
  async editDoctor({ dispatch }, doctor) {
    try {
      await axios.post('/insurance/telemedicine/doctor/save', doctor);
      dispatch('fetchDoctors');
    } catch (error) {
      console.error('Error editing doctor:', error);
    }
  },
  async fetchDoctor({ commit }, doctorId) {
    try {
      const response = await axios.post('/insurance/telemedicine/doctor/detail', {
        doctorId,
      });
      commit('SET_DOCTOR', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching doctor:', error);
    }
  },
  async fetchSlots({ commit }, doctorId) {
    try {
      const response = await axios.post('/insurance/telemedicine/slot/list', {
        doctorId,
      });
      const slots = response.data.items;
      commit('SET_SLOTS', slots);
      return slots;
    } catch (error) {
      console.error('Error fetching slots:', error);
    }
  },
  async createSlots({ dispatch }, data) {
    try {
      await axios.post('/insurance/telemedicine/slot/save', {
        doctorId: data.doctorId,
        ...data.slot,
      });
      dispatch('fetchSlots', data.doctorId);
    } catch (error) {
      throw new Error(error);
    }
  },
  async deleteSlots({ commit }, data) {
    try {
      // const requests = data.slots.map((slot) => axios.delete(`telemed/doctor/slot/delete`, {
      // }));
      //
      // await Promise.all(requests);

      this.fetchSlots(data.doctorId);
    } catch (error) {
      console.error('Error creating slots:', error);
    }
  },
  resetDoctorStore({ commit }) {
    commit('RESET_STORE');
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
