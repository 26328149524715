import axios from 'axios';

const defaultFilters = {
  searchQuery: null,
  dueDateFrom: null,
  dueDateTo: null,
  assigneeUserIds: [],
  authorUserIds: [],
  statuses: ['TODO'],
  leadsIds: [],
  personProfileIds: [],
};

const state = {
  tasks: {
    items: [],
    loading: false,
  },
  taskDetails: {
    data: null,
    loading: false,
  },
  filters: {...defaultFilters},
};

const getters = {
  tasksList: () => state.tasks.items,
  tasksLoading: () => state.tasks.loading,
  tasksFilters: () => state.filters,
  taskDetails: () => state.taskDetails.data,
  taskDetailsLoading: () => state.taskDetails.loading,
};
export function getTasks(filters) {
  return axios.post('/insurance/task/list', {
    page: 0,
    limit: 100,
    withoutPaging: true,
    ...filters
  });
}
const actions = {
  fetchTasks({commit, state}) {
    commit('setTasksListLoading', true);
    getTasks(state.filters).then((response) => {
      commit('setTasks', response.data);
      commit('setTasksListLoading', false);
    }).catch((error) => {
      console.error('Error fetching tasks:', error);
      commit('setTasksListLoading', false);
    });
  },
  saveTask({commit, dispatch}, body) {
    axios.post('/insurance/task/save', {
      ...body
    })
      .then((response) => {
        dispatch('fetchTasks');
      })
      .catch((error) => {
        console.error('Error fetching task details:', error);
      });
  },
  deleteTask({commit, dispatch}, taskId) {
    axios.post('/insurance/task/save', {
      taskId,
      taskDelete: true
    })
      .then((response) => {
        dispatch('fetchTasks');
      })
      .catch((error) => {
        console.error('Error fetching task details:', error);
      });
  },
  fetchTaskDetails({commit, dispatch}, taskId) {
    return axios.post('/insurance/task/detail', {taskId});
  },

  updateTaskFilters({commit}, filters) {
    commit('setFilters', filters);
  },

  clearFilters({commit}) {
    commit('clearAllFilters');
  }
};

const mutations = {
  setTasks(state, tasks) {
    state.tasks.items = tasks.items.filter((item) => item.title?.toLowerCase()
      .match(state.filters.searchQuery?.toLowerCase()) || item.description?.toLowerCase()
      .match(state.filters.searchQuery?.toLowerCase()));
    state.tasks.totalCount = tasks.totalCount;
  },

  setTasksListLoading(state, loading) {
    state.tasks.loading = loading;
  },

  setTaskDetails(state, details) {
    state.taskDetails.data = details;
  },
  setDetailsLoading(state, loading) {
    state.taskDetails.loading = loading;
  },

  setFilters(state, filters) {
    state.filters = {...state.filters, ...filters};
  },

  clearAllFilters(state) {
    state.filters = {...defaultFilters};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
