import axios from 'axios';
import debounce from 'lodash/debounce';
import i18n from '@/i18n';

const defaultFilters = {
  clientCompanyId: null,
  from: null,
  to: null,
};
const state = {
  hrDashboardMetrics: null,
  hrDashboardFilters: defaultFilters,
};

const getters = {
  hrDashboardMetrics: (state) => state.hrDashboardMetrics,
  hrDashboardFilters: (state) => state.hrDashboardFilters,
};

const mutations = {
  SET_HR_DASHBOARD_METRICS(state, metrics) {
    state.hrDashboardMetrics = metrics;
  },
  SET_HR_DASHBOARD_FILTERS(state, hrDashboardFilters) {
    state.hrDashboardFilters = { ...state.hrDashboardFilters, ...hrDashboardFilters };
  },
};

const actions = {
  async fetchHrDashboardMetrics({
    commit,
    state,
  }) {
    try {
      const response = await axios.post(
        '/company/metric/detail',
        state.hrDashboardFilters,
        {
          headers: {
            'X-Domain-ID': state.hrDashboardFilters.clientCompanyId,
          },
        },
      );
      commit('SET_HR_DASHBOARD_METRICS', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching dashboard:', error);
    }
  },
  updateHrDashboardFilters: debounce(({
    commit,
    dispatch,
  }, hrDashboardFilters) => {
    commit('SET_HR_DASHBOARD_FILTERS', hrDashboardFilters);
    dispatch('fetchHrDashboardMetrics');
  }, 300),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
