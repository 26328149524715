export const TICKET_LIST_REQUEST = 'TICKET_LIST_REQUEST';
export const TICKET_LIST_SUCCESS = 'TICKET_LIST_SUCCESS';
export const TICKET_LIST_FILTERS_REQUEST = 'TICKET_LIST_FILTERS_REQUEST';
export const TICKET_LIST_FILTERS_SUCCESS = 'TICKET_LIST_FILTERS_SUCCESS';

export const TICKET_DETAILS_REQUEST = 'TICKET_DETAILS_REQUEST';
export const TICKET_DETAILS_SUCCESS = 'TICKET_DETAILS_SUCCESS';

export const TICKET_PROGRAM_DETAILS_REQUEST = 'TICKET_PROGRAM_DETAILS_REQUEST';
export const TICKET_PROGRAM_DETAILS_SUCCESS = 'TICKET_PROGRAM_DETAILS_SUCCESS';

export const TICKET_LIST_FILTERS_CHANGE = 'TICKET_LIST_FILTERS_CHANGE';
export const TICKET_LIST_FILTERS_CLEAR = 'TICKET_LIST_FILTERS_CLEAR';

export const TICKET_LIST_SET_PAGE = 'TICKET_LIST_SET_PAGE';
export const TICKET_LIST_SET_LIMIT = 'TICKET_LIST_SET_LIMIT';

export const TICKET_CHANGE_STATUS = 'TICKET_CHANGE_STATUS';
export const DOCTOR_TICKET_CHANGE_STATUS = 'DOCTOR_TICKET_CHANGE_STATUS';

export const GET_TICKETS_SUGGEST_LIST = 'GET_TICKETS_SUGGEST_LIST';

export const TICKET_TAGS_REQUEST = 'TICKET_TAGS_REQUEST';
export const TICKET_TAGS_SUCCESS = 'TICKET_TAGS_SUCCESS';
export const TICKET_TAGS_UPDATE = 'TICKET_TAGS_UPDATE';
export const TICKET_TAG_DELETE = 'TICKET_TAG_DELETE';
