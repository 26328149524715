import axios from 'axios';

const state = {
  tags: [],
};

const getters = {
  tags: (state) => state.tags,
};

const actions = {
  fetchTags({ commit }) {
    axios
      .post('/insurance/ticket/tag/list', {}, { headers: { 'X-Locale': 'en' } })
      .then((response) => {
        commit('setTags', response.data);
      })
      .catch((error) => {
        console.error('Error fetching tags:', error);
      });
  },

  saveTag({ commit, dispatch }, tag) {
    return axios
      .post('/insurance/ticket/tag/save', tag, {
        headers: { 'X-Locale': 'en' },
      })
      .then((res) => {
        const tagData = {
          name: res.data.name,
          id: res.data.tagId,
        };
        if (!tag.tagId) {
          commit('setNewTag', tagData);
        } else {
          commit('updateTag', tagData);
        }
        dispatch('fetchTags');
        return tagData;
      })
      .catch((error) => {
        console.error('Error saving tag:', error);
      });
  },

  deleteTag({ commit, dispatch }, tagId) {
    axios
      .post(
        '/insurance/ticket/tag/delete',
        { tagId },
        {
          headers: { 'X-Locale': 'en' },
        }
      )
      .then(() => {
        commit('deleteTag', tagId);
        dispatch('fetchTags');
      })
      .catch((error) => {
        console.error('Error deleting tag:', error);
      });
  },
};

const mutations = {
  setTags(state, tags) {
    state.tags = tags.items;
  },
  setNewTag(state, tag) {
    state.tags = [...state.tags, tag];
  },
  updateTag(state, tag) {
    state.tags = state.tags.map((val) => {
      if (val.id === tag.id) {
        return tag;
      }
      return val;
    });
  },
  deleteTag(state, tagId) {
    state.tags = state.tags.filter((tag) => tag.id !== tagId);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
