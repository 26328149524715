import i18n from '@/i18n';

export default function sortLanguages(arr) {
  const fromIndex = arr.findIndex((item) => item.id === i18n.locale);

  if (fromIndex !== -1) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(0, 0, element);
  }

  return arr;
}
