export const EMPLOYEES_LIST_REQUEST = 'EMPLOYEES_LIST_REQUEST';
export const EMPLOYEES_LIST_SUCCESS = 'EMPLOYEES_LIST_SUCCESS';

export const EMPLOYEE_DETAILS_REQUEST = 'EMPLOYEE_DETAILS_REQUEST';
export const EMPLOYEE_DETAILS_SUCCESS = 'EMPLOYEE_DETAILS_SUCCESS';
export const EMPLOYEE_SAVE_REQUEST = 'EMPLOYEE_SAVE_REQUEST';
export const EMPLOYEE_SAVE_SUCCESS = 'EMPLOYEE_SAVE_SUCCESS';
export const EMPLOYEE_DELETE_REQUEST = 'EMPLOYEE_DELETE_REQUEST';
export const EMPLOYEE_DELETE_SUCCESS = 'EMPLOYEE_DELETE_SUCCESS';

export const EMPLOYEE_SEND_WELCOME_EMAIL_REQUEST = 'EMPLOYEE_SEND_WELCOME_EMAIL_REQUEST';
export const EMPLOYEE_SEND_WELCOME_EMAIL_SUCCESS = 'EMPLOYEE_SEND_WELCOME_EMAIL_SUCCESS';
