import axios from 'axios';
import Vue from 'vue';
import Programs from '@/store/modules/programs';
import i18n from '@/i18n';
import {
  OFFERS_LIST_REQUEST,
  OFFERS_LIST_SUCCESS,
  OFFER_DETAILS_REQUEST,
  OFFER_DETAILS_SUCCESS,
  OFFER_SAVE_REQUEST,
  OFFER_COPY,
  OFFER_ADD_CLINICS_FROM_FILE,
  OFFERS_LIST_BY_PROGRAM_REQUEST,
  OFFERS_LIST_BY_INSURANCE_REQUEST,
  OFFERS_LIST_BY_INSURANCE_SUCCESS,
  OFFERS_LIST_BY_PROGRAM_SUCCESS,
  CHANGE_OFFER_BY_PROGRAM_DESCRIPTION_COLLAPSE,
  CHANGE_OFFER_BY_INSURANCE_DESCRIPTION_COLLAPSE,
} from '../actions/offers';

import User from './user';

function FiltersDefault() {
  this.page = 1;
  this.limit = 100000;
  this.insuranceCompanyIds = [];
  this.programIds = [];
}

function getOffersLocales(resp) {
  const langArr = [];
  Object.keys(resp.localization).forEach((key) => {
    langArr.push({
      key,
      sort: resp.localization[key].sort,
    });
  });
  langArr.sort((a, b) => a.sort - b.sort);
  return langArr;
}

const state = {
  offersList: {
    items: [],
    totalCount: 1,
    loading: false,
  },
  offersListByProgram: {
    items: null,
    totalCount: 1,
    loading: false,
  },
  offersListByInsuranceCompany: {
    items: [],
    totalCount: 1,
    loading: false,
  },
  offerDetails: {
    offer: null,
    loading: false,
  },
  filters: new FiltersDefault(),
};

const getters = {
  offersList: () => state.offersList,
  offersListByProgram: () => state.offersListByProgram,
  offersListByInsuranceCompany: () => state.offersListByInsuranceCompany,
  offerDetails: () => state.offerDetails,
  offersFilters: () => state.filters,
};

const actions = {
  [OFFERS_LIST_REQUEST]({commit, state}) {
    commit(OFFERS_LIST_REQUEST);

    const filters = JSON.parse(JSON.stringify(state.filters));
    filters.page -= 1;

    return axios.post(
      '/insurance/offer/list',
      filters,
      {
        headers: {
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(OFFERS_LIST_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(OFFERS_LIST_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [OFFERS_LIST_BY_PROGRAM_REQUEST]({commit}, packageId) {
    commit(OFFERS_LIST_BY_PROGRAM_REQUEST);

    return axios.post(
      '/insurance/offer/list',
      {page: 0, limit: 10000, programIds: [packageId]},
      {
        headers: {
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(OFFERS_LIST_BY_PROGRAM_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(OFFERS_LIST_BY_PROGRAM_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [OFFERS_LIST_BY_INSURANCE_REQUEST]({commit}, insuranceCompanyId) {
    commit(OFFERS_LIST_BY_INSURANCE_REQUEST);

    return axios.post(
      '/insurance/offer/list',
      {page: 0, limit: 10000, insuranceCompanyIds: [insuranceCompanyId]},
      {
        headers: {
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(OFFERS_LIST_BY_INSURANCE_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(OFFERS_LIST_BY_INSURANCE_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [OFFER_DETAILS_REQUEST]({commit}, offerId) {
    commit(OFFER_DETAILS_REQUEST);
    return axios.post('/insurance/offer/detail', offerId, {headers: {'X-Locale': i18n.locale}})
      .then((resp) => {
        const response = resp.data;
        response.langArr = getOffersLocales(resp.data);

        commit(OFFER_DETAILS_SUCCESS, response);
        return resp.data;
      })
      .catch((err) => {
        commit(OFFER_DETAILS_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [OFFER_SAVE_REQUEST]({commit, dispatch}, data) {
    commit(OFFER_SAVE_REQUEST);
    return axios.post('/insurance/offer/save', data.offer, {headers: {'X-Locale': i18n.locale}})
      .then((resp) => {
        if (!data?.preventListDispatch) {
          dispatch(OFFERS_LIST_BY_PROGRAM_REQUEST, Programs.state.programDetails.program.packageId);
        }
        const response = resp.data;
        response.langArr = getOffersLocales(resp.data);

        commit(OFFER_DETAILS_SUCCESS, response);
        return resp.data;
      })
      .catch((err) => {
        commit(OFFER_SAVE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [OFFER_COPY]({commit}, mainOfferId) {
    return axios.post('/insurance/offer/copy', mainOfferId, {headers: {'X-Locale': i18n.locale}})
      .then((resp) => resp.data)
      .catch((err) => {
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [OFFER_ADD_CLINICS_FROM_FILE]({commit}, data) {
    const timestamp = Date.now();
    const formData = new FormData();
    formData.append('file', data.file);
    return axios({
      url: `/insurance/clinic/batch/upload/v2?insuranceCompanyId=${data.insuranceCompanyId}&meta=${data.file.name}-${timestamp}&offerId=${data.offerId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'X-Locale': i18n.locale,
      },
      data: formData,
      method: 'POST',
    })
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err.response.data));
  },
};

const mutations = {
  [OFFERS_LIST_REQUEST]: (state) => {
    state.offersList.loading = !state.offersList.loading;
  },
  [OFFERS_LIST_BY_PROGRAM_REQUEST]: (state) => {
    state.offersListByProgram.loading = !state.offersListByProgram.loading;
  },
  [OFFERS_LIST_BY_INSURANCE_REQUEST]: (state) => {
    state.offersListByInsuranceCompany.loading = !state.offersListByInsuranceCompany.loading;
  },

  [OFFERS_LIST_SUCCESS]: (state, resp) => {
    state.offersList.loading = false;
    state.offersList.items = resp.items;
    state.offersList.totalCount = resp.totalCount;
  },
  [OFFERS_LIST_BY_PROGRAM_SUCCESS]: (state, resp) => {
    state.offersListByProgram.loading = false;
    state.offersListByProgram.items = resp.items;
    state.offersListByProgram.items.forEach((item) => {
      Vue.set(item, 'collapse', true);
    });
    state.offersListByProgram.totalCount = resp.totalCount;
  },
  [OFFERS_LIST_BY_INSURANCE_SUCCESS]: (state, resp) => {
    state.offersListByInsuranceCompany.loading = false;
    state.offersListByInsuranceCompany.items = resp.items;
    state.offersListByInsuranceCompany.items.forEach((item) => {
      Vue.set(item, 'collapse', true);
    });
    state.offersListByInsuranceCompany.totalCount = resp.totalCount;
  },

  [OFFER_DETAILS_REQUEST]: (state) => {
    state.offerDetails.loading = !state.offerDetails.loading;
  },
  [OFFER_DETAILS_SUCCESS]: (state, resp) => {
    state.offerDetails.offer = resp;
    state.offerDetails.offer.localizationEdit = null;
    state.offerDetails.offer.status = resp.status.id;
    state.offerDetails.offer.icon = resp.icons.find((item) => item.alias === 'L') || {url: null};
    state.offerDetails.loading = false;
  },
  [OFFER_SAVE_REQUEST]: (state) => {
    state.offerDetails.loading = !state.offerDetails.loading;
  },

  [CHANGE_OFFER_BY_PROGRAM_DESCRIPTION_COLLAPSE]: (state, offerId) => {
    const ind = state.offersListByProgram.items.findIndex((item) => item.offerId === offerId);
    state.offersListByProgram.items[ind].collapse = !state.offersListByProgram.items[ind].collapse;
  },
  [CHANGE_OFFER_BY_INSURANCE_DESCRIPTION_COLLAPSE]: (state, offerId) => {
    const ind = state.offersListByInsuranceCompany.items.findIndex((item) => item.offerId === offerId);
    state.offersListByInsuranceCompany.items[ind].collapse = !state.offersListByInsuranceCompany.items[ind].collapse;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
