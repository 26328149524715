<template>
  <div class="a-mwe">
    <mwe-controls
      v-if="editor"
      :ref="`mwe_editor${refSuffix}-controls`"
      :editor="editor"
      :showBalloon="showBalloon"
      :hideActions="hideActions"
      @selectAttachment="$emit('selectAttachment')"
    />

    <div
      class="a-mwe__editor"
      :placeholder="placeholder"
      :ref="`mwe_editor${this.refSuffix}`"
      v-html="innerValue"
      contenteditable="true"
      @input="onInput"
      @keyup.enter.exact="resetCommands"
      @mouseup="saveSelection"
      @keyup.exact="saveSelection"
      @mouseleave="saveSelection"
      @blur="saveSelection"
      @keyup.enter.shift="pasteNewLine"
    />

    <div v-if="false">{{preview}}</div>
  </div>
</template>

<script>
import { Marked } from '@ts-stack/markdown';
import TurndownService from 'turndown';
import MweControls from '@/components/editor/parts/mwe-controls';

export default {
  name: 'markdown-wysiwyg',
  components: { MweControls },
  props: {
    value: {
      type: String,
      defaultValue: '',
    },
    refSuffix: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    keyReInitValue: {
      type: [String, Number],
      default: '',
    },
    htmlMode: {
      type: Boolean,
      default: false,
    },
    showBalloon: {
      type: Boolean,
      default: false,
    },
    newLineByShiftEnter: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      editor: null,
      innerValue: null,
      preview: '',
      turndown: null,
    };
  },
  created() {
    this.init();
    this.turndownInit();
  },
  mounted() {
    this.editor = this.$refs[`mwe_editor${this.refSuffix}`];
    this.inputEmit();
  },
  watch: {
    keyReInitValue() {
      this.init();
    },
  },
  computed: {
    config() {
      return this.$props;
    },
  },
  methods: {
    init() {
      this.innerValue = this.value ? Marked.parse(this.value).replace(/(<del>)(.*?)(<\/del>)/g, '<strike>$2</strike>') : '';
      document.execCommand('defaultParagraphSeparator', false, 'p');
    },
    pasteNewLine() {
      if (this.newLineByShiftEnter) {
        this.$refs[`mwe_editor${this.refSuffix}-controls`].pasteNewLine();
      }
    },
    turndownInit() {
      this.turndown = new TurndownService({
        emDelimiter: '*',
        linkStyle: 'inlined',
        headingStyle: 'atx',
      });

      this.turndown.addRule('strikethrough', {
        filter: ['del', 's', 'strike'],
        replacement(content) {
          return `~~${content}~~`;
        },
      });
    },
    resetCommands() { // после нажатия enter
      this.$refs[`mwe_editor${this.refSuffix}-controls`].resetCommands();
    },
    saveSelection() {
      this.$refs[`mwe_editor${this.refSuffix}-controls`].saveSelection();
    },
    clearValue() {
      this.editor.innerHTML = '';
      this.inputEmit();
    },

    onInput() {
      if (!this.editor.innerHTML) {
        this.clearValue();
      }

      this.preview = this.turndown.turndown(this.editor.innerHTML);

      this.inputEmit();
    },
    inputEmit() {
      this.$emit('input', this.htmlMode ? this.editor.innerHTML : this.turndown.turndown(this.editor.innerHTML));
    },
  },
};
</script>

<style scoped>
.a-mwe__editor[placeholder]:empty:before {
  content: attr(placeholder);
  color: var(--solid-dark-purple);
}
.a-mwe__editor{

}
</style>
