<template>
  <div class="l-footer container">
    <div>
      <p class="text_48 text_dark text_b_900 mb_16">
        {{ $t('footer.title') }}
      </p>

      <l-phone-form :buttonTitle="$t('buttons.send')" :requestType="insurancePage"/>

      <p class="l-footer-c text_16 text_dark">
        <template v-if="$companyName === 'Checkme'">
          © 2020—{{ getYear() }}
        </template>
        <template v-else>
          © 2023
        </template>
        {{ $t('footer.company') }}
        <a
          :href="$t('meta.privacyPolicy.link')"
          target="_blank"
          class="text_16 text_blue">
          {{ $t('meta.privacyPolicy.title') }}
        </a>
        <a
          :href="$t('meta.termsAndConditions.link')"
          target="_blank"
          class="text_16 text_blue">
          {{ $t('meta.termsAndConditions.title') }}
        </a>
        <a
          :href="$t('meta.acceptableUsePolicy.link')"
          target="_blank"
          class="text_16 text_blue">
          {{ $t('meta.acceptableUsePolicy.title') }}
        </a>
      </p>
    </div>

    <div class="flex-as-fe">
      <div v-if="false" class="l-footer__contacts">
        <div>
          <a href="https://api.whatsapp.com/send?phone=79951158000" target="_blank"
             class="text_blue" data-v-038a7016="">
            <svg width="32" height="32">
              <use xlink:href="/img/svg/symbol.svg#whatsapp"/>
            </svg>
          </a>
        </div>

        <div>
          <svg width="32" height="32">
            <use xlink:href="/img/svg/symbol.svg#vk"/>
          </svg>
        </div>

        <div>
          <a href="https://t.me/checkmerubot" target="_blank" class="text_blue" data-v-038a7016="">
            <svg width="32" height="32">
              <use xlink:href="/img/svg/symbol.svg#tg"/>
            </svg>
          </a>
        </div>
      </div>

      <template v-if="$companyName === 'Checkme'">
        <a
          href="mailto:info@checkme.tech"
          class="text_20 text_dark mb_8"
        >
          info@checkme.tech
        </a>
        <a
          href="mailto:hr@checkme.tech"
          class="text_20 text_dark mb_32"
        >
          hr@checkme.tech
        </a>
      </template>
      <template v-else>
        <a
          href="mailto:hello@renova.world"
          class="text_20 text_dark mb_8"
        >
          hello@renova.world
        </a>
        <a
          href="mailto:team@renova.world"
          class="text_20 text_dark mb_32"
        >
          team@renova.world
        </a>
      </template>

      <div class="l-footer__store">
        <a href="https://apps.apple.com/ru/app/checkme-запись-к-врачу-онлайн/id1555968311"
           target="_blank">
          <svg width="140" height="40">
            <use xlink:href="/img/svg/symbol.svg#app-story"/>
          </svg>
        </a>

        <a href="https://play.google.com/store/apps/details?id=ru.checkme.app" target="_blank">
          <svg width="154" height="40">
            <use xlink:href="/img/svg/symbol.svg#google-play"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import LPhoneForm from '@/views/Landings/common-parts/l-phone-form';

export default {
  name: 'l-footer',
  components: { LPhoneForm },
  computed: {
    insurancePage() {
      return this.$route.name === 'acquisitionInsurance' || this.$route.name === 'insurancePlatform' ? 'INSURANCE_PHONE' : 'PHONE';
    },
  },
  methods: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>

</style>
