<template>
  <div class="admin-page-title">
    <h1><slot/></h1>
  </div>
</template>

<script>
export default {
  name: 'AdminPageTitle',
};
</script>

<style scoped>
h1{
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1px;
  color: var(--solid-black-purple);
}
</style>
