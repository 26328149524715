import axios from 'axios';
import i18n from '@/i18n';

const RequestsStore = {
  methods: {
    dmsRequest(params) {
      return axios.post('/company/dms/request', params);
    },
    getClinicsCSV(params) {
      return axios.post(
        '/insurance/clinic/list-in-csv',
        params,
        {
          responseType: 'arraybuffer',
        },
      );
    },
  },
};

export default RequestsStore;
