<template>
  <div
    class="dropdown"
    :style="{
      width: width,
      'min-width': width,
    }"
    :class="{
      'dropdown--multiple-selected': selectedIds.length > 1,
      'dropdown--disabled': disabled,
      'dropdown--readonly': readonly,
      'dropdown--with-status': statusDotColor,
    }"
  >
    <div
      class="dropdown__status"
      :style="{ 'background-color': statusDotColor }"
      v-if="statusDotColor"
    ></div>
    <el-select
      ref="dropdown"
      v-model="selectedIds"
      :multiple="true"
      :filterable="filterable"
      :clearable="clearable"
      :placeholder="placeholder"
      :disabled="disabled"
      :no-data-text="noDataText"
      collapse-tags
      @change="updateOptions"
    >
      <el-option
        v-for="option in options"
        :key="option.id"
        :label="option.name || option.title"
        :value="option.id"
        :disabled="option.locked || option.disabled"
      >
        <div
          class="dropdown__option"
          :class="{
            'dropdown__option--locked': option.locked,
            'dropdown__option--disabled': option.disabled,
            'dropdown__option--with-dot': option.color,
          }"
        >
          <div
            class="dropdown__option-dot"
            :style="{ 'background-color': option.color }"
            v-if="option.color"
          ></div>
          <p
            class="dropdown__option-title body-text"
            v-html="option.name || option.title || option.text"
          ></p>
          <p
            class="dropdown__option-subtitle caption color--dark-purple"
            v-if="option.subtitle || option.addressLine"
          >
            {{ option.subtitle || option.addressLine }}
          </p>
          <BaseFeatureLocker
            v-if="option.locked"
            :size="'s'"
            :tooltip-text="option.lockerTooltip"
          ></BaseFeatureLocker>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import BaseFeatureLocker from '@/views/RenovaDashboard/components/ui-kit/Cards/BaseFeatureLocker';

export default {
  name: 'BaseDropdown',
  components: {
    BaseFeatureLocker,
  },
  emits: ['changeOptions'],
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    statusDotColor: {
      type: String,
      default: null,
    },
    noDataText: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '200px',
    },
  },
  computed: {},
  data() {
    return {
      selectedIds: this.selectedOptions,
    };
  },
  watch: {
    selectedOptions: {
      immediate: true,
      handler(newSelectedOptions) {
        this.selectedIds = newSelectedOptions;
      },
    },
  },
  methods: {
    updateOptions(value) {
      if (!this.disabled && !this.readonly) {
        if (!this.multiple) {
          const defaultList = this.clearable ? [] : this.selectedOptions;
          this.selectedIds = value && value.length ? [value[value.length - 1]] : defaultList;
          this.$emit('changeOptions', this.selectedIds);
          setTimeout(() => {
            this.$refs.dropdown.handleClose();
          }, 100);
        } else {
          this.selectedIds = value;
          this.$emit('changeOptions', this.selectedIds);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  height: 40px;
  cursor: pointer;
  position: relative;

  &--with-status {
    .el-select__tags {
      padding-left: 32px !important;
    }
  }

  &--multiple-selected {
    .el-select__tags {
      width: calc(100% - 35px) !important;
    }

    .el-select__input {
      margin-left: 8px;
      padding-right: 36px;
    }
  }

  &--disabled {
    //opacity: 0.7;
    cursor: default;

    .el-select__caret {
      opacity: 0.4;
    }

    .el-select__tags-text {
      color: var(--solid-dark-purple) !important;
    }

    .el-input.is-disabled .el-input__inner {
      //background-color: #f5f7fa82;
    }
  }

  &--readonly {
    cursor: default;

    * {
      pointer-events: none;
    }

    .el-select__caret {
      display: none;
    }
  }

  .el-select__input {
    margin-left: 0px;
    padding-right: 36px;
  }

  .el-input__inner {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
    display: flex;
    border-color: var(--solid-lavender-grey) !important;
    height: 40px;
    padding-left: 16px;
  }

  .el-select__tags {
    padding-left: 16px;
    max-width: 100% !important;
    pointer-events: none;

    .el-tag:last-of-type:not(:first-of-type) {
      border: 1px solid var(--solid-lavender-grey);
      padding: 4px;
      height: 20px;
      margin-left: 8px;

      .el-select__tags-text {
        color: var(--solid-dark-purple) !important;
      }
    }
  }

  .el-tag.el-tag--info {
    background-color: transparent;
    border: none;
    margin: 0;
    padding-left: 0px;
    max-width: 100% !important;
    padding-right: 0px;

    .el-tag__close {
      display: none;
    }
  }

  .el-select .el-select__tags > span {
    max-width: calc(100% - 40px);
    display: flex;
    align-items: center;
  }

  .el-select__tags-text {
    color: var(--solid-black-purple);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: var(--solid-black-purple);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  .el-select .el-input__suffix,
  .el-input .el-input__suffix {
    margin-top: 0px;
    height: 40px;
    right: 12px;
  }

  .el-input {
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--solid-dark-purple);
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }

    ::placeholder {
      color: var(--solid-dark-purple);
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.dropdown__option {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 800px;
  width: 100%;

  &--disabled {
    opacity: 0.4;
  }

  &--with-dot {
    padding-left: 16px !important;
  }

  .feature-locker {
    justify-content: flex-end !important;
  }

  .feature-locker__btn {
    background: #b7b7b7;
    right: -4px;
    position: relative;
    backdrop-filter: blur(0.31578946113586426px) !important;
  }
}

.dropdown__option-title,
.dropdown__option-subtitle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown__option-title {
  color: var(--solid-black-purple);
}

.dropdown__option-subtitle {
  margin: 0;
}

.dropdown__status,
.dropdown__option-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
}

.dropdown__option-dot {
  top: 4px;
  left: -5px;
}
</style>
