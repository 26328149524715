import axios from 'axios';

const defaultFilters = {
  clientType: [],
  insuranceCompanyIds: [],
  paymentType: [],
  paymentStatus: [],
  transactionStatus: [],
  searchQuery: '',
  agents: [],
};
const defaultRequestFilters = {
  agentCommissionPaymentStatuses: [],
  withoutAgentCommissionPaymentStatus: false,
};
const filterLeads = (leads, filters) => leads.filter((lead) => {
  const searchQueryLower = filters.searchQuery.toLowerCase();
  return (
    (filters.clientType.length === 0 || filters.clientType.includes(lead.clientType))
    && (filters.insuranceCompanyIds.length === 0 || lead.insuranceCompany && filters.insuranceCompanyIds.includes(lead.insuranceCompany.id))
    && (filters.paymentType.length === 0 || filters.paymentType.includes(lead.paymentType))
    && (filters.agents.length === 0 || filters.agents.includes(lead?.agent?.id))
    && (filters.paymentStatus.length === 0 || filters.paymentStatus.includes(lead.paymentStatus))
    && (filters.transactionStatus.length === 0 || filters.transactionStatus.includes(lead.transactionStatus))
    && (filters.searchQuery === ''
      || lead?.title?.toLowerCase().includes(searchQueryLower)
      || lead?.email?.toLowerCase().includes(searchQueryLower)
      || lead?.phone?.toLowerCase().includes(searchQueryLower))
  );
});
const state = {
  leads: {
    items: [],
    totalCount: 0,
    loading: false,
  },
  leadDetails: {
    data: null,
    loading: false,
    actions: {
      items: [],
      totalCount: 0,
      loading: false
    }
  },
  filteredLeadsList: [],
  filters: {...defaultFilters},
  requestFilters: {...defaultRequestFilters},
};

const getters = {
  leadList: () => state.leads.items,
  leadTotalCount: () => state.leads.totalCount,
  filteredLeadsList: () => state.filteredLeadsList,
  leadsLoading: () => state.leads.loading,
  leadsFilters: () => state.filters,
  leadsRequestFilters: () => state.requestFilters,
  leadDetails: () => state.leadDetails.data,
  leadDetailsActions: () => state.leadDetails.actions,
  leadDetailsLoading: () => state.leadDetails.loading,
};
export function getLeads(requestFilters, filters = {}) {
  return axios.post('/insurance/lead/list', {
    page: 0,
    limit: 1000,
    ...filters,
    ...{
      ...requestFilters,
      agentCommissionPaymentStatuses: requestFilters.agentCommissionPaymentStatuses.filter((val) => val !== 'MISSING'),
      withoutAgentCommissionPaymentStatus: requestFilters.agentCommissionPaymentStatuses.includes('MISSING')
    },
  });
}
const actions = {
  fetchLeads({commit, state}) {
    commit('setLeadsListLoading', true);
    getLeads(state.requestFilters).then((response) => {
      commit('setLeads', response.data);
      commit('updateFilteredLeads');
      commit('setLeadsListLoading', false);
    }).catch((error) => {
      console.error('Error fetching leads:', error);
      commit('setLeadsListLoading', false);
    });
  },

  updateLeads({commit}, leads) {
    axios.post('/insurance/lead/save-bulk', {
      itemsSave: leads
    })
      .then((response) => {
        commit('setUpdatedLeads', leads);
        commit('updateFilteredLeads');
      })
      .catch((error) => {
        console.error('Error updating leads:', error);
      });
  },

  deleteLeads({commit}, leadIds) {
    const itemsSave = leadIds.map((leadId) => ({
      leadId,
      leadDelete: true
    }));
    axios.post('/insurance/lead/save-bulk', {
      itemsSave
    })
      .then((response) => {
        commit('removeLeads', leadIds);
        commit('updateFilteredLeads');
      })
      .catch((error) => {
        console.error('Error deleting leads:', error);
      });
  },

  fetchLeadDetails({commit, dispatch}, leadId) {
    commit('setDetailsLoading', true);
    dispatch('fetchLeadActions', leadId);
    axios.post('/insurance/lead/detail', {leadId})
      .then((response) => {
        commit('setLeadDetails', response.data);
        commit('setDetailsLoading', false);
      })
      .catch((error) => {
        console.error('Error fetching lead details:', error);
        commit('setDetailsLoading', false);
      });
  },
  fetchLeadActions({commit}, leadId) {
    commit('setActionsLoading', true);
    axios.post('/insurance/lead/audit-log/list', {
      leadId,
      page: 0,
      limit: 1000,
    })
      .then((response) => {
        commit('setLeadActions', response.data);
        commit('setActionsLoading', false);
      })
      .catch((error) => {
        console.error('Error fetching lead details:', error);
        commit('setActionsLoading', false);
      });
  },
  saveLead({commit, dispatch}, body) {
    axios.post('/insurance/lead/save', {
      ...body
    })
      .then((response) => {
        dispatch('fetchLeads');
        if (body.leadId) {
          dispatch('fetchLeadDetails', body.leadId);
        }
      })
      .catch((error) => {
        console.error('Error fetching lead details:', error);
      });
  },
  exportLeads({commit, dispatch}, leads) {
    axios.post('/insurance/lead/download', {
      leads
    })
      .then((resp) => {
        const fileName = 'Leads';

        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([resp.data], {
          type: 'text/csv',
        }));
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Error fetching lead details:', error);
      });
  },
  addLeadFile({commit, dispatch}, body) {
    axios.post('/insurance/lead/save', {
      ...body
    })
      .then((response) => {
        dispatch('fetchLeadDetails', body.leadId);
      })
      .catch((error) => {
        console.error('Error add file:', error);
      });
  },

  deleteLeadFile({commit, dispatch}, body) {
    axios.post('/insurance/lead/save', {
      ...body
    })
      .then((response) => {
        dispatch('fetchLeadDetails', body.leadId);
      })
      .catch((error) => {
        console.error('Error delete file:', error);
      });
  },
  updateLeadsFilters({commit}, filters) {
    commit('setFilters', filters);
    commit('updateFilteredLeads');
  },
  updateLeadsRequestFilters({commit, dispatch}, filters) {
    commit('setRequestFilters', filters);
    dispatch('fetchLeads');
  },
  clearFilters({commit}) {
    commit('clearAllFilters');
    commit('updateFilteredLeads');
  }
};

const mutations = {
  setLeads(state, leads) {
    state.leads.items = leads.items;
    state.leads.totalCount = leads.totalCount;
  },

  setLeadsListLoading(state, loading) {
    state.leads.loading = loading;
  },

  setLeadDetails(state, details) {
    state.leadDetails.data = details;
  },
  setLeadActions(state, actions) {
    state.leadDetails.actions = actions;
  },

  setDetailsLoading(state, loading) {
    state.leadDetails.loading = loading;
  },
  setActionsLoading(state, loading) {
    state.leadDetails.actions.loading = loading;
  },

  setUpdatedLeads(state, updatedLeads) {
    state.leads.items = state.leads.items.map((lead) => {
      const updatedLead = updatedLeads.find(((val) => val.leadId === lead.leadId));
      if (updatedLead) {
        return {
          ...lead,
          ...updatedLead,
          stage: updatedLead.stageSave
        };
      }
      return lead;
    });
  },

  removeLeads(state, leadIds) {
    state.leads.items = state.leads.items.filter((lead) => !leadIds.includes(lead.leadId));
  },

  setFilters(state, filters) {
    state.filters = {...state.filters, ...filters};
  },

  clearAllFilters(state) {
    state.filters = {...defaultFilters};
  },
  updateFilteredLeads() {
    state.filteredLeadsList = filterLeads(state.leads.items, state.filters);
  },
  setRequestFilters(state, requestFilters) {
    state.requestFilters = {...state.requestFilters, ...requestFilters};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
