<template>
  <div class="feature-locker">
    <BaseTooltip :tooltip-text="tooltipText" :color="color">
      <div :class="`feature-locker__btn feature-locker__btn--${size} feature-locker__btn--${color}`">
        <div class="feature-locker__btn-icon"></div>
      </div>
    </BaseTooltip>
  </div>
</template>

<script>
import BaseTooltip from '@/views/RenovaDashboard/components/ui-kit/BaseTooltip';

export default {
  name: 'BaseFeatureLocker',
  components: {
    BaseTooltip,
  },
  props: {
    tooltipText: {
      type: String,
    },
    size: {
      type: String,
      default: 'm',
      validate: (value) => ['xs', 's', 'm'].includes(value),
    },
    color: {
      type: String,
      default: 'dark',
      validate: (value) => ['dark', 'light'].includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-locker {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.feature-locker__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(1.5px);
  background-size: contain;
  &--xs {
    width: 16px;
    height: 16px;
    .feature-locker__btn-icon {
      width: 6px;
      height: 6px;
    }
  }
  &--s {
    width: 24px;
    height: 24px;
    .feature-locker__btn-icon {
      width: 9px;
      height: 9px;
    }
  }
  &--m {
    width: 40px;
    height: 40px;
    .feature-locker__btn-icon {
      width: 16px;
      height: 16px;
    }
  }
  &--light{
    background: rgba(255, 255, 255, 0.80);
    .feature-locker__btn-icon{
      background-image: url(/public/img/renova-dashboard/icons/lock--black.svg);
    }
  }
}

.feature-locker__btn-icon {
  background-image: url(/public/img/renova-dashboard/icons/lock.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
