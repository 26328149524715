<template>
  <div id="mwe__balloon" class="a-mwe__balloon">
    <div v-if="rangeContainsA" class="a-mwe__controls">
      <el-button @click="test">test</el-button>
    </div>

    <div v-else class="a-mwe__controls">
      <button @click="applyBold" class="button" :class="{'a-mwe__button-active': activeCommand.bold}">
        <svg width="20" height="20">
          <use xlink:href="/img/svg/editor.svg#bold"/>
        </svg>
      </button>

      <button @click="applyItalic" class="button" :class="{'a-mwe__button-active': activeCommand.italic}">
        <svg width="20" height="20">
          <use xlink:href="/img/svg/editor.svg#italic"/>
        </svg>
      </button>

      <button @click="applyUnderline" class="button" :class="{'a-mwe__button-active': activeCommand.underline}">
        <svg width="20" height="20">
          <use xlink:href="/img/svg/editor.svg#underline"/>
        </svg>
      </button>

      <button @click="applyStrikeThrough" class="button" :class="{'a-mwe__button-active': activeCommand.strikeThrough}">
        <svg>
          <use xlink:href="/img/svg/editor.svg#strike"/>
        </svg>
      </button>
    </div>

    <div id="mwe__balloon-arrow" data-popper-arrow />
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
  name: 'mwe-balloon-menu',
  props: ['savedRange', 'activeCommand', 'rangeContainsA'],
  data() {
    return {
      target: null,
      menu: null,
      popperInstance: null,
    };
  },
  mounted() {
    this.menu = document.getElementById('mwe__balloon');
  },
  watch: {
    savedRange(val) {
      if (val?.startOffset === val?.endOffset && !this.rangeContainsA) {
        this.hide();
      }

      if (val?.startOffset !== val?.endOffset && !this.rangeContainsA) {
        this.createNewPopper();

        this.show();
      }

      if (this.rangeContainsA) {
        this.createNewPopper();

        this.show();
      }
    },
  },
  methods: {
    test() {
      this.savedRange.selectNode(this.savedRange.commonAncestorContainer);
      const url = 'https://learn.javascript.ru/selection-range';
      document.execCommand('insertHTML', false, `<a href="${this.savedRange.commonAncestorContainer.href}" target="_blank">kkkkkkkkkkk</a>`);
    },
    createNewPopper() {
      this.popperInstance = createPopper(this.savedRange, this.menu, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          {
            name: 'arrow',
            options: {
              element: document.querySelector('#mwe__balloon-arrow'),
            },
          },
        ],
      });
    },
    addEvents() {
      const showEvents = ['mouseenter', 'focus', 'click'];
      const hideEvents = ['click'];

      showEvents.forEach((event) => {
        this.target.addEventListener(event, this.show);
      });

      hideEvents.forEach((event) => {
        this.target.addEventListener(event, this.hide);
      });
    },
    removeEvents() {
      const showEvents = ['mouseenter', 'focus', 'click'];
      const hideEvents = ['click'];

      hideEvents.forEach((event) => {
        this.target.removeEventListener(event, this.hide);
      });
    },
    show() {
      this.menu.setAttribute('data-show', '');
      this.popperInstance.update();
    },
    hide() {
      this.menu.removeAttribute('data-show');
    },

    // actions

    applyBold() {
      this.$emit('applyBold');
    },
    applyItalic() {
      this.$emit('applyItalic');
    },
    applyUnderline() {
      this.$emit('applyUnderline');
    },
    applyStrikeThrough() {
      this.$emit('applyStrikeThrough');
    },
  },
};
</script>

<style scoped lang="scss">

#mwe__balloon {
  display: none;
}

#mwe__balloon[data-show] {
  display: block;
}
</style>
