import axios from 'axios';
import {cloneDeep} from 'lodash';

const defaultState = {
  insuranceCompanies: [],
};
const state = {
  ...cloneDeep(defaultState),
};

const getters = {
  insuranceCompanies: () => state.insuranceCompanies,
};
const actions = {
  getInsuranceCompany({
    commit,
    state
  }, insuranceCompanyId) {
    return axios.post('/insurance/insurance-company/detail', {
      insuranceCompanyId,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error fetching policies:', error);
      });
  },
  saveInsuranceCompany({
    commit,
    state
  }, data) {
    return axios.post('/insurance/insurance-company/save', {
      ...data,
    })
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error fetching policies:', error);
      });
  },
};

const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations,
};
