export const GET_DICTIONARY = 'GET_DICTIONARY';
export const GET_HR_DICTIONARY = 'GET_HR_DICTIONARY';

export const GET_PHONE_CODES = 'GET_PHONE_CODES';
export const SET_PHONE_CODE = 'SET_PHONE_CODE';

export const GET_CITIES = 'GET_CITIES';
export const CITIES_FILTERS_CHANGE = 'CITIES_FILTERS_CHANGE';
export const GET_CITIES_CSV = 'GET_CITIES_CSV';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_ACTIVE_INSURANCE_COMPANIES = 'GET_ACTIVE_INSURANCE_COMPANIES';
