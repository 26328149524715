var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown",class:{
    'dropdown--multiple-selected': _vm.selectedIds.length > 1,
    'dropdown--disabled': _vm.disabled,
    'dropdown--readonly': _vm.readonly,
    'dropdown--with-status': _vm.statusDotColor,
  },style:({
    width: _vm.width,
    'min-width': _vm.width,
  })},[(_vm.statusDotColor)?_c('div',{staticClass:"dropdown__status",style:({ 'background-color': _vm.statusDotColor })}):_vm._e(),_c('el-select',{ref:"dropdown",attrs:{"multiple":true,"filterable":_vm.filterable,"clearable":_vm.clearable,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"no-data-text":_vm.noDataText,"collapse-tags":""},on:{"change":_vm.updateOptions},model:{value:(_vm.selectedIds),callback:function ($$v) {_vm.selectedIds=$$v},expression:"selectedIds"}},_vm._l((_vm.options),function(option){return _c('el-option',{key:option.id,attrs:{"label":option.name || option.title,"value":option.id,"disabled":option.locked || option.disabled}},[_c('div',{staticClass:"dropdown__option",class:{
          'dropdown__option--locked': option.locked,
          'dropdown__option--disabled': option.disabled,
          'dropdown__option--with-dot': option.color,
        }},[(option.color)?_c('div',{staticClass:"dropdown__option-dot",style:({ 'background-color': option.color })}):_vm._e(),_c('p',{staticClass:"dropdown__option-title body-text",domProps:{"innerHTML":_vm._s(option.name || option.title || option.text)}}),(option.subtitle || option.addressLine)?_c('p',{staticClass:"dropdown__option-subtitle caption color--dark-purple"},[_vm._v(" "+_vm._s(option.subtitle || option.addressLine)+" ")]):_vm._e(),(option.locked)?_c('BaseFeatureLocker',{attrs:{"size":'s',"tooltip-text":option.lockerTooltip}}):_vm._e()],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }