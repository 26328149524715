import axios from 'axios';
import {
  SAVE_FILE_TO_CDN, SAVE_FILE_TO_CDN_COMMON, DELETE_FILE_FROM_CDN, SAVE_POLICY_TO_CDN,
} from '../actions/files';

const state = {
  fileSave: {
    loading: false,
  },
};

const getters = {
  fileSave: () => state.fileSave,
};

const actions = {
  [SAVE_FILE_TO_CDN]({ commit }, data) {
    commit(SAVE_FILE_TO_CDN);
    const formData = new FormData();
    formData.append('file', data);
    return axios({
      url: '/insurance/file/save',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      method: 'POST',
    })
      .then((resp) => {
        commit(SAVE_FILE_TO_CDN);
        return resp.data;
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [SAVE_FILE_TO_CDN_COMMON]({ commit }, data) {
    commit(SAVE_FILE_TO_CDN);
    const formData = new FormData();
    formData.append('file', data.file);
    return axios({
      url: `/insurance/file/save?type=${data.type}&prefix=${data.name}-attachment`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      method: 'POST',
    })
      .then((resp) => {
        commit(SAVE_FILE_TO_CDN);
        return resp.data;
      })
      .catch((err) => {
        commit(SAVE_FILE_TO_CDN);
        return Promise.reject(err.response.data);
      });
  },
  [SAVE_POLICY_TO_CDN]({ commit }, data) {
    commit(SAVE_POLICY_TO_CDN);
    const formData = new FormData();
    formData.append('file', data.file);
    return axios({
      url: `/insurance/file/save?type=${data.type}&prefix=${data.name}-attachment`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      method: 'POST',
    })
      .then((resp) => {
        commit(SAVE_POLICY_TO_CDN);
        return resp.data;
      })
      .catch((err) => {
        commit(SAVE_POLICY_TO_CDN);
        return Promise.reject(err.response.data);
      });
  },
  [DELETE_FILE_FROM_CDN]({ commit }, data) {
    return axios({
      url: '/user/file/delete',
      data,
      method: 'POST',
    })
      .then((resp) => Promise.resolve(resp))
      .catch((err) => Promise.reject(err.response.data));
  },

};

const mutations = {
  [SAVE_FILE_TO_CDN]: (state) => {
    state.fileSave.loading = !state.fileSave.loading;
  },
  [SAVE_POLICY_TO_CDN]: (state) => {
    state.fileSave.loading = !state.fileSave.loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
