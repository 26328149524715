/* import { TICKET_DETAILS_REQUEST, TICKET_DOCTOR_CALL_REQUEST } from '@/store/actions/ticketList'; */
import eventBus from '@/main';
// eslint-disable-next-line import/prefer-default-export
export const tourSteps = {
  data() {
    return {
      options: {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: 'Пропустить тур',
          buttonPrevious: 'Назад',
          buttonNext: 'Вперед',
          buttonStop: 'Закончить',
        },
      },
      steps: [
        {
          target: '[data-v-step="ticket-list-1"]',
          content: 'Фильтры помогут найти нужные запросы (например от застрахованных из одной компании)',
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '[data-v-step="ticket-list-2"]',
          content: 'Все запросы и сообщения со статусами обработки от застрахованных и HR-менеджеров',
          params: {
            placement: 'left',
          },
          before: (type) => new Promise((resolve, reject) => {
            if (this.$route.name !== 'tickets') {
              this.$router.push('/admin/tickets/')
                .then((r) => {
                  resolve('foo');
                });
            } else {
              resolve('foo');
            }
          }),
        },
        {
          target: '[data-v-step="ticket-details-1"]',
          content: 'Рядом с чатом — вся информация о застрахованном и его программев',
          params: {
            placement: 'right',
          },
          before: (type) => new Promise((resolve, reject) => {
            if (this.$route.path !== '/admin/tickets/51615470-0deb-437e-9a83-13e1eebba29f') {
              this.$router.push('/admin/tickets/51615470-0deb-437e-9a83-13e1eebba29f')
                .then((r) => {
                  /* this.$store.dispatch(TICKET_DETAILS_REQUEST, { ticketId: this.$route.params.id }).then(() => {
                    resolve('foo');
                  }); */
                });
            } else {
              resolve('foo');
            }
          }),
        },
        {
          target: '[data-v-step="ticket-details-2"]',
          content: 'История записей<br>застрахованного — под рукой',
          params: {
            placement: 'top',
          },
        },
        {
          target: '[data-v-step="ticket-details-3"]',
          content: 'Отвечайте на запросы по программе онлайн. Здесь будет храниться история переписки за весь период страхования',
          params: {
            placement: 'left',
          },
          before: (type) => new Promise((resolve, reject) => {
            if (this.$route.path !== '/admin/tickets/51615470-0deb-437e-9a83-13e1eebba29f') {
              this.$router.push('/admin/tickets/51615470-0deb-437e-9a83-13e1eebba29f')
                .then((r) => {
                  /* this.$store.dispatch(TICKET_DETAILS_REQUEST, { ticketId: this.$route.params.id }).then(() => {
                    eventBus.$emit('tour-show-user-chat');
                    resolve('foo');
                  }); */
                });
            } else {
              resolve('foo');
            }
          }),
        },
        {
          target: '[data-v-step="ticket-details-4"]',
          content: 'Принимайте заявки на получение услуг от застрахованных',
          params: {
            placement: 'bottom',
          },
          before: () => new Promise((resolve, reject) => {
            if (this.$route.path !== '/admin/tickets/be565bf3-b884-456c-9cb1-7c661efa8590') {
              this.$router.push('/admin/tickets/be565bf3-b884-456c-9cb1-7c661efa8590')
                .then((r) => {
                  /* this.$store.dispatch(TICKET_DETAILS_REQUEST, { ticketId: this.$route.params.id }).then(() => {
                    this.$store.dispatch(
                      TICKET_DOCTOR_CALL_REQUEST,
                      { doctorCallId: 'be7dcf38-f1e7-4fdb-89b1-370bf17e9b88', status: { type: 'DOCTOR_CALL', updatedAt: '2022-08-05T10:04:35' }, type: 'DOCTOR_CALL' },
                    )
                      .then(() => {
                        eventBus.$emit('tour-show-user-reception');
                        resolve('foo');
                      });
                  }); */
                });
            } else {
              resolve('foo');
            }
          }),
        },
        {
          target: '[data-v-step="ticket-details-5"]',
          content: 'При нажатии вы увидите все параметры, которые выбрал застрахованный',
          params: {
            placement: 'top',
          },
          before: () => new Promise((resolve, reject) => {
            if (this.$route.path !== '/admin/tickets/be565bf3-b884-456c-9cb1-7c661efa8590') {
              this.$router.push('/admin/tickets/be565bf3-b884-456c-9cb1-7c661efa8590')
                .then((r) => {
                  /* this.$store.dispatch(TICKET_DETAILS_REQUEST, { ticketId: this.$route.params.id }).then(() => {
                    this.$store.dispatch(
                      TICKET_DOCTOR_CALL_REQUEST,
                      { doctorCallId: 'be7dcf38-f1e7-4fdb-89b1-370bf17e9b88', status: { type: 'DOCTOR_CALL', updatedAt: '2022-08-05T10:04:35' }, type: 'DOCTOR_CALL' },
                    )
                      .then(() => {
                        eventBus.$emit('tour-show-user-reception');
                        resolve('foo');
                      });
                  }); */
                });
            } else {
              resolve('foo');
            }
          }),
        },
        {
          target: '[data-v-step="programs-1"]',
          content: 'Нажмите, чтобы создать новую программу',
          params: {
            placement: 'bottom-end',
          },
          before: () => new Promise((resolve, reject) => {
            if (this.$route.path !== '/admin/programs') {
              this.$router.push('/admin/programs')
                .then((r) => {
                  resolve('foo');
                });
            } else {
              resolve('foo');
            }
          }),
        },
        {
          target: '[data-v-step="programs-2"]',
          content: 'Уже существующие программы — у вас под рукой с кратким описанием и основными данными',
          params: {
            placement: 'bottom-end',
          },
          before: () => new Promise((resolve, reject) => {
            if (this.$route.path !== '/admin/programs') {
              this.$router.push('/admin/programs')
                .then((r) => {
                  resolve('foo');
                });
            } else {
              resolve('foo');
            }
          }),
        },
        {
          target: '[data-v-step="program-details-1"]',
          content: 'Заполните поля — информация отобразится на карточке программы на главной странице',
          params: {
            placement: 'right',
          },
          before: () => new Promise((resolve, reject) => {
            if (this.$route.path !== '/admin/programs/1') {
              this.$router.push('/admin/programs/1')
                .then((r) => {
                  resolve('foo');
                });
            } else {
              const myElement = document.getElementById('program-to-scroll');
              document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop - 1300;
              resolve('foo');
            }
          }),
        },
        {
          target: '[data-v-step="program-details-2"]',
          content: 'Загрузите файлы по программе. Они также отобразятся в личном кабинете HR',
          params: {
            placement: 'right',
          },
          before: (type) => new Promise((resolve, reject) => {
            const myElement = document.getElementById('program-to-scroll');
            document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop - 1200;

            resolve('foo');
          }),
        },
        {
          target: '[data-v-step="program-details-3"]',
          content: 'Добавьте все услуги, которые должны войти в программу',
          params: {
            placement: 'top',
          },
          before: (type) => new Promise((resolve, reject) => {
            const myElement = document.getElementById('program-to-scroll');
            document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop - 1000;

            resolve('foo');
          }),
        },
        {
          target: '[data-v-step="program-details-3-1"]',
          content: 'Нажмите, чтобы открыть список доступных услуг',
          params: {
            placement: 'top',
          },
          before: (type) => new Promise((resolve, reject) => {
            const myElement = document.getElementById('program-to-scroll');
            document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop - 1000;

            eventBus.$emit('tour-show-offers-list');

            resolve('foo');
          }),
        },
        {
          target: '[data-v-step="program-details-3-2"]',
          content: 'Нажмите, чтобы перенести доступные услуги из списка в программу для клиента',
          params: {
            placement: 'left',
          },
          before: (type) => new Promise((resolve, reject) => {
            const myElement = document.getElementById('program-to-scroll');
            document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop - 1000;

            eventBus.$emit('tour-show-offers-list');

            resolve('foo');
          }),
        },
        {
          target: '[data-v-step="program-details-3-3"]',
          content: 'Нажмите, чтобы создать новую услугу',
          params: {
            placement: 'top',
          },
          before: (type) => new Promise((resolve, reject) => {
            const myElement = document.getElementById('program-to-scroll');
            document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop - 1000;

            eventBus.$emit('tour-show-offers-add');

            resolve('foo');
          }),
        },
        {
          target: '[data-v-step="program-details-3-4"]',
          content: 'Заполните основную информацию об услуге и загрузите файл с подробным описанием',
          params: {
            placement: 'right',
          },
        },
        {
          target: '[data-v-step="program-details-3-5"]',
          content: `Это краткое описание, которое появится под названием услуги в приложении ${window.companyName}`,
          params: {
            placement: 'top-start',
          },
        },
        {
          target: '[data-v-step="program-details-3-6"]',
          content: 'Загрузите файл со списком клиник, где можно получить услуги по программе',
          params: {
            placement: 'top',
          },
        },
        {
          target: '[data-v-step="program-details-4"]',
          content: 'Управляйте списком застрахованных и отправляйте важную информацию',
          params: {
            placement: 'top',
          },
          before: (type) => new Promise((resolve, reject) => {
            const myElement = document.getElementById('program-to-scroll');
            document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop + 1000;

            resolve('foo');
          }),
        },
        {
          target: '[data-v-step="program-details-5"]',
          content: 'Нужно добавить сразу несколько застрахованных? Загрузите файл со списком',
          params: {
            placement: 'top',
          },
          before: (type) => new Promise((resolve, reject) => {
            const myElement = document.getElementById('program-to-scroll');
            document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop + 1000;

            resolve('foo');
          }),
        },
        {
          target: '[data-v-step="program-details-6"]',
          content: `Отправьте застрахованным коды активации и ссылку на приложение ${window.companyName} для доступа к услугам`,
          params: {
            placement: 'top',
          },
          before: (type) => new Promise((resolve, reject) => {
            const myElement = document.getElementById('program-to-scroll');
            document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop + 1000;

            resolve('foo');
          }),
        },
        {
          target: '[data-v-step="program-details-7"]',
          content: 'Нажмите, если сотрудника нужно убрать из списка застрахованных',
          params: {
            placement: 'top',
          },
          before: (type) => new Promise((resolve, reject) => {
            const myElement = document.getElementById('program-to-scroll');
            document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop + 1000;

            resolve('foo');
          }),
        },
        {
          target: '[data-v-step="program-details-8"]',
          content: 'Полный список застрахованных по программе с основными данными — здесь',
          params: {
            placement: 'right',
          },
          before: (type) => new Promise((resolve, reject) => {
            const myElement = document.getElementById('program-to-scroll');
            document.getElementById('program-scrolling_div').scrollTop = myElement.offsetTop + 1000;

            resolve('foo');
          }),
        },
      ],
    };
  },
};
