import Vue from 'vue';
import VueI18n from 'vue-i18n';

export const timeFormat = {
  en: 'LT',
  es: 'LT',
  pt: 'LT',
  ru: 'HH:mm',
};
Vue.use(VueI18n);

function loadLocaleMessages() {
  const jsonLocales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  const messages = {};
  jsonLocales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = {
        ...messages[locale],
        ...jsonLocales(key),
      };
    }
  });
  return messages;
}
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  pluralizationRules: {
    ru(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }
      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;
      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }
      return (choicesLength < 4) ? 2 : 3;
    },
  },
});
