import el from 'element-ui/src/locale/lang/el';
import {
  ADD_OFFER, DELETE_OFFER, CHANGE_COLLAPSE, CHANGE_COLLAPSE_REP, SAVE_OFFER, SET_EDIT_PROGRAM, SAVE_EDIT_PROGRAM,
} from '../actions/hardcode';

const state = {
  programHardcode: {},
  programs: [
    {
      id: 1,
      name: 'Взрослый',
      description: 'Facebook Взрослый стоматология',
      insuranceCompanyId: 1,
      status: 'ACTIVE',
      docs: [
        {
          name: 'Договор',
          url: '',
        },
        {
          name: 'Лицензия',
          url: '',
        },
        {
          name: 'Полные условия',
          url: '',
        },
      ],
      offers: [
        {
          id: 4,
          name: 'Запись в клинику',
          type: 'INSURANCE',
          description: 'Facebook взрослые Москва',
          collapse: true,
          contentTitle: 'Очная консультация врача в удобной для вас клинике по ДМС (бесплатно)',
          contentDescription: 'Запись в клинику — услуга, которая входит в вашу программу. Если у вас есть жалобы или чекап показал, что не все показатели в норме, вы можете попасть на приём к терапевту или профильному врачу (кардиологу, офтальмологу, эндокринологу и другим). Выбирайте удобную клинику на карте, дату и время.'
            + 'Консультация врача — это первый шаг к решению вашей проблемы. Если далее нужна будет диагностика (анализ крови или, например, МРТ), физиотерапия, ЛФК или другие лечебные процедуры, их должен назначить врач. Тогда по ДМС эти услуги, если они есть в пакете, окажут вам бесплатно.\n',
          contents: [
            {
              title: 'Каких врачей можно посетить?',
              description: 'Помимо терапевта это может быть аллерголог, гастроэнтеролог, '
                + 'дерматовенеролог, кардиолог, отоларинголог, офтальмолог, ревматолог, травматолог, хирург, '
                + 'гинеколог, эндокринолог, уролог и другие. Всё зависит от условий программы и наличия записи '
                + 'к нужным специалистам в клиниках: это нужно уточнить отдельно.',
            },
            {
              title: 'Какой врач нужен вам?',
              description: 'Расскажите о своём состоянии терапевту онлайн. Он подскажет.',
            },
            {
              title: 'Какое лечение доступно по ДМС?',
              description: 'Если вы пришли к врачу по ДМС и он назначил вам обследования или лечение, '
                + 'которые входят в вашу программу, вы получите медицинскую помощь бесплатно. Чтобы согласовать '
                + 'оказание дополнительных услуг, врач свяжется со страховой компанией. Полное описание услуг — в файле ниже.',
            },
            {
              title: 'Почему клиники на карте выделены разным цветом?',
              description: 'Есть клиники, куда можно записаться напрямую — по телефону. В этих клиниках '
                + `вам доступны семейный врач и узкие специалисты. Запись в другие клиники — через ${window.companyName}. `
                + 'В них вам доступны приёмы узких специалистов, и для этого вам нужно направление семейного врача.',
            },
          ],
          doc: {
            name: 'Описание услуги',
            url: '',
          },
          clinic: null,
        },
        {
          id: 1,
          name: 'Обратиться к психологу',
          type: 'CHAT',
          description: 'Сервис «Понимаю»',
          collapse: true,
          contentTitle: '',
          contentDescription: '',
          contents: [
            {
              title: '',
              description: '',
            },
          ],
          clinic: {
            name: '',
            url: '',
          },
        },
        {
          id: 2,
          name: 'Вызов врача на дом',
          type: 'NEED_DOCTOR',
          description: 'Ингосстрах стандартная услуга',
          collapse: true,
          contentTitle: '',
          contentDescription: '',
          contents: [
            {
              title: '',
              description: '',
            },
          ],
          clinic: {
            name: '',
            url: '',
          },
        },
      ],
      employees: [
        {
          id: 1,
          name: 'Иванов Иван Иванович',
          phone: '+79999999991',
          email: 'example1@mail.ru',
          policyNumber: '12345678-111',
          sendingMessage: false,
        },
        {
          id: 2,
          name: 'Петров Пётр Петрович',
          phone: '+79999999992',
          email: 'example2@mail.ru',
          policyNumber: '12345678-222',
          sendingMessage: false,
        },
        {
          id: 3,
          name: 'Сергей Сергеевич Сергеев',
          phone: '+79999999993',
          email: 'example3@mail.ru',
          policyNumber: '12345678-333',
          sendingMessage: false,
        },
      ],
    },
    {
      id: 2,
      name: 'Детский',
      description: 'Facebook детский',
      insuranceCompanyId: 1,
      status: 'ACTIVE',
      docs: [
        {
          name: 'Договор',
          url: '',
        },
        {
          name: 'Лицензия',
          url: '',
        },
        {
          name: 'Полные условия',
          url: '',
        },
      ],
      offers: [
        {
          id: 4,
          name: 'Запись в клинику',
          type: 'INSURANCE',
          description: 'Facebook взрослые Москва',
          collapse: true,
          contentTitle: 'Очная консультация врача в удобной для вас клинике по ДМС (бесплатно)',
          contentDescription: 'Запись в клинику — услуга, которая входит в вашу программу. Если у вас есть жалобы или чекап показал, что не все показатели в норме, вы можете попасть на приём к терапевту или профильному врачу (кардиологу, офтальмологу, эндокринологу и другим). Выбирайте удобную клинику на карте, дату и время.'
            + 'Консультация врача — это первый шаг к решению вашей проблемы. Если далее нужна будет диагностика (анализ крови или, например, МРТ), физиотерапия, ЛФК или другие лечебные процедуры, их должен назначить врач. Тогда по ДМС эти услуги, если они есть в пакете, окажут вам бесплатно.\n',
          contents: [
            {
              title: 'Каких врачей можно посетить?',
              description: 'Помимо терапевта это может быть аллерголог, гастроэнтеролог, '
                + 'дерматовенеролог, кардиолог, отоларинголог, офтальмолог, ревматолог, травматолог, хирург, '
                + 'гинеколог, эндокринолог, уролог и другие. Всё зависит от условий программы и наличия записи '
                + 'к нужным специалистам в клиниках: это нужно уточнить отдельно.',
            },
            {
              title: 'Какой врач нужен вам?',
              description: 'Расскажите о своём состоянии терапевту онлайн. Он подскажет.',
            },
            {
              title: 'Какое лечение доступно по ДМС?',
              description: 'Если вы пришли к врачу по ДМС и он назначил вам обследования или лечение, '
                + 'которые входят в вашу программу, вы получите медицинскую помощь бесплатно. Чтобы согласовать '
                + 'оказание дополнительных услуг, врач свяжется со страховой компанией. Полное описание услуг — в файле ниже.',
            },
            {
              title: 'Почему клиники на карте выделены разным цветом?',
              description: 'Есть клиники, куда можно записаться напрямую — по телефону. В этих клиниках '
                + `вам доступны семейный врач и узкие специалисты. Запись в другие клиники — через ${window.companyName}. `
                + 'В них вам доступны приёмы узких специалистов, и для этого вам нужно направление семейного врача.',
            },
          ],
          doc: {
            name: 'Описание услуги',
            url: '',
          },
          clinic: null,
        },
        {
          id: 1,
          name: 'Обратиться к психологу',
          type: 'CHAT',
          description: 'Сервис «Понимаю»',
          collapse: true,
          contentTitle: '',
          contentDescription: '',
          contents: [
            {
              title: '',
              description: '',
            },
          ],
          clinic: {
            name: '',
            url: '',
          },
        },
        {
          id: 2,
          name: 'Вызов врача на дом',
          type: 'NEED_DOCTOR',
          description: 'Ингосстрах стандартная услуга',
          collapse: true,
          contentTitle: '',
          contentDescription: '',
          contents: [
            {
              title: '',
              description: '',
            },
          ],
          clinic: {
            name: '',
            url: '',
          },
        },
      ],
      employees: [
        {
          id: 1,
          name: 'Иванов Иван Иванович',
          phone: '+79999999991',
          email: 'example1@mail.ru',
          policyNumber: '12345678-111',
          sendingMessage: false,
        },
        {
          id: 2,
          name: 'Петров Пётр Петрович',
          phone: '+79999999992',
          email: 'example2@mail.ru',
          policyNumber: '12345678-222',
          sendingMessage: false,
        },
        {
          id: 3,
          name: 'Сергей Сергеевич Сергеев',
          phone: '+79999999993',
          email: 'example3@mail.ru',
          policyNumber: '12345678-333',
          sendingMessage: false,
        },
      ],
    },
  ],
  newProgram: {
    id: null,
    name: '',
    insuranceCompanyId: null,
    description: '',
    status: null,
    docs: [
    ],
    clinic: {
      name: '',
      url: '',
    },
    offers: [
    ],
    employees: [
    ],
  },
  types: [
    {
      id: 'CALL',
      name: 'Звонок в страховую',
    },
    {
      id: 'CHAT',
      name: 'Заявка в чат поддержки',
    },
    {
      id: 'INSURANCE',
      name: 'Страхование',
    },
    {
      id: 'NEED_DOCTOR',
      name: 'Вызов врача',
    },
  ],
  offers: {
    lastOfferId: 4,
    items: [
      {
        id: 1,
        name: 'Запись в клинику',
        type: 'INSURANCE',
        description: 'Facebook взрослые Москва',
        collapse: true,
        contentTitle: 'Очная консультация врача в удобной для вас клинике по ДМС (бесплатно)',
        contentDescription: 'Запись в клинику — услуга, которая входит в вашу программу. Если у вас есть жалобы или чекап показал, что не все показатели в норме, вы можете попасть на приём к терапевту или профильному врачу (кардиологу, офтальмологу, эндокринологу и другим). Выбирайте удобную клинику на карте, дату и время.'
          + 'Консультация врача — это первый шаг к решению вашей проблемы. Если далее нужна будет диагностика (анализ крови или, например, МРТ), физиотерапия, ЛФК или другие лечебные процедуры, их должен назначить врач. Тогда по ДМС эти услуги, если они есть в пакете, окажут вам бесплатно.\n',
        contents: [
          {
            title: 'Каких врачей можно посетить?',
            description: 'Помимо терапевта это может быть аллерголог, гастроэнтеролог, '
              + 'дерматовенеролог, кардиолог, отоларинголог, офтальмолог, ревматолог, травматолог, хирург, '
              + 'гинеколог, эндокринолог, уролог и другие. Всё зависит от условий программы и наличия записи '
              + 'к нужным специалистам в клиниках: это нужно уточнить отдельно.',
          },
          {
            title: 'Какой врач нужен вам?',
            description: 'Расскажите о своём состоянии терапевту онлайн. Он подскажет.',
          },
          {
            title: 'Какое лечение доступно по ДМС?',
            description: ' Если вы пришли к врачу по ДМС и он назначил вам обследования или лечение, '
              + 'которые входят в вашу программу, вы получите медицинскую помощь бесплатно. Чтобы согласовать '
              + 'оказание дополнительных услуг, врач свяжется со страховой компанией. Полное описание услуг — в файле ниже.',
          },
          {
            title: 'Почему клиники на карте выделены разным цветом?',
            description: 'Есть клиники, куда можно записаться напрямую — по телефону. В этих клиниках '
              + `вам доступны семейный врач и узкие специалисты. Запись в другие клиники — через ${window.companyName}. `
              + 'В них вам доступны приёмы узких специалистов, и для этого вам нужно направление семейного врача.',
          },
        ],
        clinic: null,
      },
      {
        id: 2,
        name: 'Обратиться к психологу',
        type: 'CHAT',
        description: 'Сервис «Понимаю»',
        collapse: true,
        contentTitle: '',
        contentDescription: '',
        contents: [
          {
            title: '',
            description: '',
          },
        ],
        clinic: {
          name: '',
          url: '',
        },
      },
      {
        id: 3,
        name: 'Вызов врача на дом',
        type: 'NEED_DOCTOR',
        description: 'Ингосстрах стандартная услуга',
        collapse: true,
        contentTitle: '',
        contentDescription: '',
        contents: [
          {
            title: '',
            description: '',
          },
        ],
        clinic: {
          name: '',
          url: '',
        },
      },
      {
        id: 4,
        name: 'Запись к стоматологу',
        type: 'INSURANCE',
        description: 'Facebook взрослые Москва',
        collapse: true,
        contentTitle: '',
        contentDescription: '',
        contents: [
          {
            title: '',
            description: '',
          },
        ],
        clinic: {
          name: '',
          url: '',
        },
      },
    ],
  },
  insuranceCompaniesHard: [
    {
      id: 1,
      name: 'Insurance company',
    },
  ],
  statusArr: [
    {
      id: 'ACTIVE',
      name: 'Активен',
    },
    {
      id: 'PAUSE',
      name: 'Пауза',
    },
    {
      id: 'NEED_ACTIVATION',
      name: 'Ждет активации',
    },
  ],
};

const getters = {
  programs: () => state.programs,
  programHardcode: () => state.programHardcode,
  newProgram: () => state.newProgram,
  offers: () => state.offers,
  types: () => state.types,
  insuranceCompaniesHard: () => state.insuranceCompaniesHard,
  statusArr: () => state.statusArr,
};

const mutations = {
  [SET_EDIT_PROGRAM]: (state, program) => {
    state.programHardcode = program;
  },
  [SAVE_EDIT_PROGRAM]: (state, data) => {
    state.programHardcode[data.ind] = data.program;
  },
  [ADD_OFFER]: (state, props) => {
    if (props.id) {
      if (!state.programHardcode.offers.find((item) => item.id === state.offers.items[props.ind].id)) {
        state.programHardcode.offers.push(state.offers.items[props.ind]);
      }
    } else if (!props.id) {
      if (!state.newProgram.offers.find((item) => item.id === state.offers.items[props.ind].id)) {
        state.newProgram.offers.push(state.offers.items[props.ind]);
      }
    }
  },
  [DELETE_OFFER]: (state, props) => {
    if (props.id) {
      state.programHardcode.offers.splice(props.ind, 1);
    } else {
      state.newProgram.offers.splice(props.ind, 1);
    }
  },
  [CHANGE_COLLAPSE]: (state, id) => {
    const ind = state.programHardcode.offers.findIndex((item) => item.id === id);
    state.programHardcode.offers[ind].collapse = !state.programHardcode.offers[ind].collapse;
  },
  [CHANGE_COLLAPSE_REP]: (state, id) => {
    const ind = state.offers.items.findIndex((item) => item.id === id);
    state.offers.items[ind].collapse = !state.offers.items[ind].collapse;
  },
  [SAVE_OFFER]: (state, offer) => {
    if (offer.id) {
      const ind = state.offers.items.findIndex((item) => item.id === offer.id);
      state.offers.items[ind] = offer;
    } else {
      // eslint-disable-next-line no-param-reassign
      offer.id = state.offers.lastOfferId + 1;
      state.offers.items.push(offer);
    }
  },
};

export default {
  state,
  getters,
  mutations,
};
