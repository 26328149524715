import {mapGetters} from 'vuex';

export const PermissionsTypes = {
  DASHBOARD_LEADS_VIEW: 'DASHBOARD_LEADS_VIEW',
  DASHBOARD_LEADS_CREATE: 'DASHBOARD_LEADS_CREATE',
  DASHBOARD_LEADS_UPDATE: 'DASHBOARD_LEADS_UPDATE',
  DASHBOARD_LEADS_DELETE: 'DASHBOARD_LEADS_DELETE',
  DASHBOARD_LEADS_EXPORT: 'DASHBOARD_LEADS_EXPORT',

  DASHBOARD_SERVICING_VIEW: 'DASHBOARD_SERVICING_VIEW',
  DASHBOARD_SERVICING_CREATE: 'DASHBOARD_SERVICING_CREATE',
  DASHBOARD_SERVICING_UPDATE: 'DASHBOARD_SERVICING_UPDATE',
  DASHBOARD_SERVICING_DELETE: 'DASHBOARD_SERVICING_DELETE',
  DASHBOARD_SERVICING_EXPORT: 'DASHBOARD_SERVICING_EXPORT',

  POLICIES_VIEW: 'POLICIES_VIEW',
  POLICIES_CREATE: 'POLICIES_CREATE',
  POLICIES_UPDATE: 'POLICIES_UPDATE',
  POLICIES_DELETE: 'POLICIES_DELETE',
  POLICIES_EXPORT: 'POLICIES_EXPORT',

  RENEWALS_VIEW: 'RENEWALS_VIEW',
  RENEWALS_CREATE: 'RENEWALS_CREATE',
  RENEWALS_UPDATE: 'RENEWALS_UPDATE',
  RENEWALS_DELETE: 'RENEWALS_DELETE',
  RENEWALS_EXPORT: 'RENEWALS_EXPORT',

  INSURED_VIEW: 'INSURED_VIEW',
  INSURED_CREATE: 'INSURED_CREATE',
  INSURED_UPDATE: 'INSURED_UPDATE',
  INSURED_DELETE: 'INSURED_DELETE',
  INSURED_EXPORT: 'INSURED_EXPORT',

  PLANS_VIEW: 'PLANS_VIEW',
  PLANS_CREATE: 'PLANS_CREATE',
  PLANS_UPDATE: 'PLANS_UPDATE',
  PLANS_DELETE: 'PLANS_DELETE',
  PLANS_EXPORT: 'PLANS_EXPORT',

  STORIES_VIEW: 'STORIES_VIEW',
  STORIES_CREATE: 'STORIES_CREATE',
  STORIES_UPDATE: 'STORIES_UPDATE',
  STORIES_DELETE: 'STORIES_DELETE',
  STORIES_EXPORT: 'STORIES_EXPORT',

  LEADS_VIEW: 'LEADS_VIEW',
  LEADS_CREATE: 'LEADS_CREATE',
  LEADS_UPDATE: 'LEADS_UPDATE',
  LEADS_DELETE: 'LEADS_DELETE',
  LEADS_EXPORT: 'LEADS_EXPORT',

  TASKS_VIEW: 'TASKS_VIEW',
  TASKS_CREATE: 'TASKS_CREATE',
  TASKS_UPDATE: 'TASKS_UPDATE',
  TASKS_DELETE: 'TASKS_DELETE',
  TASKS_EXPORT: 'TASKS_EXPORT',

  TELEMED_VIEW: 'TELEMED_VIEW',
  TELEMED_CREATE: 'TELEMED_CREATE',
  TELEMED_UPDATE: 'TELEMED_UPDATE',
  TELEMED_DELETE: 'TELEMED_DELETE',
  TELEMED_EXPORT: 'TELEMED_EXPORT',

  ADD_MEMBERS_VIEW: 'ADD_MEMBERS_VIEW',
  ADD_MEMBERS_CREATE: 'ADD_MEMBERS_CREATE',
  ADD_MEMBERS_UPDATE: 'ADD_MEMBERS_UPDATE',
  ADD_MEMBERS_DELETE: 'ADD_MEMBERS_DELETE',
  ADD_MEMBERS_EXPORT: 'ADD_MEMBERS_EXPORT',

  INTEGRATIONS_VIEW: 'INTEGRATIONS_VIEW',
  INTEGRATIONS_CREATE: 'INTEGRATIONS_CREATE',
  INTEGRATIONS_UPDATE: 'INTEGRATIONS_UPDATE',
  INTEGRATIONS_DELETE: 'INTEGRATIONS_DELETE',
  INTEGRATIONS_EXPORT: 'INTEGRATIONS_EXPORT',

  SETTINGS_VIEW: 'SETTINGS_VIEW',
  SETTINGS_CREATE: 'SETTINGS_CREATE',
  SETTINGS_UPDATE: 'SETTINGS_UPDATE',
  SETTINGS_DELETE: 'SETTINGS_DELETE',
  SETTINGS_EXPORT: 'SETTINGS_EXPORT',

  MY_PROFILE_VIEW: 'MY_PROFILE_VIEW',
  MY_PROFILE_CREATE: 'MY_PROFILE_CREATE',
  MY_PROFILE_UPDATE: 'MY_PROFILE_UPDATE',
  MY_PROFILE_DELETE: 'MY_PROFILE_DELETE',
  MY_PROFILE_EXPORT: 'MY_PROFILE_EXPORT',

  REQUESTS_VIEW: 'REQUESTS_VIEW',
  REQUESTS_CREATE: 'REQUESTS_CREATE',
  REQUESTS_UPDATE: 'REQUESTS_UPDATE',
  REQUESTS_DELETE: 'REQUESTS_DELETE',
  REQUESTS_EXPORT: 'REQUESTS_EXPORT',
};
export const checkUserPermissions = (types, permissions) => types?.every((type) => permissions?.includes(type));
export const checkOptionalUserPermissions = (types, permissions) => types?.some((type) => permissions?.includes(type));

export const UserPermissions = {
  computed: {
    ...mapGetters(['permissions', 'isUserSuperAdmin']),
    PermissionsTypes() {
      return PermissionsTypes;
    },
  },
  methods: {
    checkUserPermissions(permissionsForCheck) {
      return this.isUserSuperAdmin || checkUserPermissions(permissionsForCheck, this.permissions);
    },
    checkOptionalUserPermissions(permissionsForCheck) {
      return this.isUserSuperAdmin || checkOptionalUserPermissions(permissionsForCheck, this.permissions);
    }
  }
};
