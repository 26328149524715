import axios from 'axios';
import debounce from 'lodash/debounce';

const defaultFilters = {
  specId: '',
  status: '',
};
const state = {
  doctorsList: [],
  filters: defaultFilters,
};

const getters = {
  doctorsList: (state) => state.doctorsList,
  filters: (state) => state.filters,
};

const mutations = {
  SET_DOCTORS_LIST(state, doctors) {
    state.doctorsList = doctors;
  },
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
};

const actions = {
  async fetchDoctors({
    commit,
    state,
  }) {
    try {
      const {
        specId,
        status,
      } = state.filters;
      const response = await axios.post(
        '/insurance/telemedicine/doctor/list',
        {
          specId: specId || undefined,
          status: status || undefined,
        },
      );
      commit('SET_DOCTORS_LIST', response.data.items);
      return response.data.items;
    } catch (error) {
      console.error('Error fetching doctors:', error);
    }
  },
  updateDoctorsFilters: debounce(({
    commit,
    dispatch,
  }, filters) => {
    commit('SET_FILTERS', filters);
    dispatch('fetchDoctors');
  }, 300),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
