import axios from 'axios';

const state = {
  specializations: [],
  loading: false,
};

const getters = {
  specializations: (state) => state.specializations,
  loading: (state) => state.loading,
};

const actions = {
  async fetchSpecializations({ commit }) {
    try {
      commit('SET_LOADING', true);
      const response = await axios.post('/insurance/telemedicine/doctor-specialization/list', {
        status: 'ACTIVE',
      });
      commit('SET_SPECIALIZATIONS', response.data.items);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('Error fetching specializations:', error);
      commit('SET_LOADING', false);
    }
  },

  async createSpecialization({ commit }, specialization) {
    try {
      const response = await axios.post('/insurance/telemedicine/doctor-specialization/save', specialization);
      const spec = {
        id: response.data.id,
        ...specialization,
      };
      commit('ADD_SPECIALIZATION', spec);
      return spec;
    } catch (error) {
      console.error('Error creating specialization:', error);
      return null;
    }
  },

  async editSpecialization({ commit }, specialization) {
    try {
      const response = await axios.post('/insurance/telemedicine/doctor-specialization/save', specialization);
      commit('UPDATE_SPECIALIZATION', response.data);
    } catch (error) {
      console.error('Error editing specialization:', error);
    }
  },
};

const mutations = {
  SET_SPECIALIZATIONS(state, specializations) {
    state.specializations = specializations;
  },
  ADD_SPECIALIZATION(state, specialization) {
    state.specializations.push(specialization);
  },
  UPDATE_SPECIALIZATION(state, updatedSpecialization) {
    const index = state.specializations.findIndex((spec) => spec.id === updatedSpecialization.id);
    if (index !== -1) {
      state.specializations.splice(index, 1, updatedSpecialization);
    }
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
