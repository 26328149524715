import axios from 'axios';
import {
  CHAT_ATTACH_FILE,
  CHAT_CLEAR_ATTACHMENTS,
  CHAT_CONFIG_REQUEST,
  CHAT_CONVERSATIONS_DETAILS_REQUEST,
  CHAT_DELETE_ATTACHMENT,
  CHAT_MESSAGE_SEND_REQUEST,
  CHAT_MESSAGES_READ_REQUEST,
  CHAT_MESSAGES_REQUEST,
  CHAT_WEBSOCKET_MESSAGE_READ_RECEIVED,
  CHAT_WEBSOCKET_RECEIVED,
} from '@/store/actions/chat';

const state = {
  chatConversationChannel: null,
  chatConversationId: '',
  chatWebSocketURL: null,
  chatMessages: [],
  chatAttachedFiles: [],
};

const getters = {
  chatConversationChannel: () => state.chatConversationChannel,
  chatConversationId: () => state.chatConversationId,
  chatWebSocketURL: () => state.chatWebSocketURL,
  chatMessages: () => state.chatMessages,
  chatAttachedFiles: () => state.chatAttachedFiles,
};

const actions = {
  /**
   * Get chat config request (web socket url)
   * @returns {Promise}
   */
  [CHAT_CONFIG_REQUEST]({ commit }, payload) {
    return axios.post(`/${payload.isHrChat ? 'company' : 'insurance'}/conversation/config/detail`)
      .then((resp) => {
        commit(CHAT_CONFIG_REQUEST, resp);
        return resp.data.connectUrl;
      });
  },
  /**
   * Get conversation details (channel and id) request
   * @returns {Promise<void>}
   */
  [CHAT_CONVERSATIONS_DETAILS_REQUEST]({ commit }, payload) {
    return axios.post(`/${payload.isHrChat ? 'company' : 'insurance'}/conversation/detail`, {})
      .then((resp) => {
        commit(CHAT_CONVERSATIONS_DETAILS_REQUEST, resp);
        return resp.data;
      });
  },
  /**
   * Send message request
   * @param commit
   * @param dispatch
   * @param state
   * @param payload
   * @returns {Promise}
   */
  [CHAT_MESSAGE_SEND_REQUEST]({ commit, dispatch, state }, payload) {
    return axios.post(`/${payload.isHrChat ? 'company' : 'insurance'}/conversation/message/send`, {
      cdnFileIds: state.chatAttachedFiles.map((file) => file.cdnFileId),
      conversationId: payload.conversationId,
      text: payload.message,
    }).then((resp) => {
      commit(CHAT_MESSAGE_SEND_REQUEST, resp.data);
      return resp.data;
    });
  },
  [CHAT_CLEAR_ATTACHMENTS]({ commit }) {
    commit(CHAT_CLEAR_ATTACHMENTS);
  },
  /**
   * API request for chat messages history
   * @param commit
   * @param dispatch
   * @param payload {conversationId: number, lastNumber: number}
   * @returns {Promise}
   */
  [CHAT_MESSAGES_REQUEST]({ commit, dispatch }, payload) {
    return axios.post(`/${payload.isHrChat ? 'company' : 'insurance'}/conversation/message/list`, {
      conversationId: payload.conversationId,
      to: payload.lastNumber,
    }).then((resp) => {
      commit(CHAT_MESSAGES_REQUEST, resp.data);
      // as long as we recieved messages, we can mark them as read
      if (resp.data.messages.length > 0 && !payload.preventReadMessage) {
        dispatch(CHAT_MESSAGES_READ_REQUEST, payload);
      }
    });
  },
  [CHAT_MESSAGES_READ_REQUEST]({ commit, dispatch }, payload) {
    return axios.post(`/${payload.isHrChat ? 'company' : 'insurance'}/conversation/message/mark-read`, {
      conversationId: payload.conversationId,
    }).then(() => {
      state.chatMessages = [...state.chatMessages.map((message) => ({
        ...message,
        clientIsRead: true,
      }))];
    });
  },
  [CHAT_ATTACH_FILE]({ commit, dispatch }, payload) {
    // upload file Multipart/form-data
    const formData = new FormData();
    formData.append('file', payload.file);
    return axios.post(`/${payload.isHrChat ? 'company' : 'insurance'}/conversation/file/save`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((resp) => {
      commit(CHAT_ATTACH_FILE, resp.data);
      return resp.data;
    });
  },
};

const mutations = {
  [CHAT_MESSAGE_SEND_REQUEST]: (state) => {
    // Will clear attached files after sending message
    state.chatAttachedFiles = [];
  },
  [CHAT_CONFIG_REQUEST]: (state, resp) => {
    state.chatWebSocketURL = resp.data.connectUrl;
  },
  [CHAT_CONVERSATIONS_DETAILS_REQUEST]: (state, resp) => {
    state.chatConversationChannel = resp.data.conversationChannel;
    state.chatConversationId = resp.data.conversationId;
  },
  [CHAT_WEBSOCKET_RECEIVED]: (state, data) => {
    state.chatMessages.push(data);
  },
  [CHAT_MESSAGES_REQUEST]: (state, data) => {
    state.chatMessages = data.messages;
  },
  [CHAT_ATTACH_FILE]: (state, data) => {
    state.chatAttachedFiles.push(data);
  },
  [CHAT_CLEAR_ATTACHMENTS]: (state) => {
    state.chatAttachedFiles = [];
  },
  [CHAT_DELETE_ATTACHMENT]: (state, data) => {
    state.chatAttachedFiles = state.chatAttachedFiles.filter((file) => file.cdnFileId !== data.fileId);
  },
  [CHAT_WEBSOCKET_MESSAGE_READ_RECEIVED]: (state) => {
    state.chatMessages = state.chatMessages.map((message) => ({
      ...message,
      supportIsRead: true,
    }));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
