export const PROGRAMS_LIST_REQUEST = 'PROGRAMS_LIST_REQUEST';
export const PROGRAMS_LIST_BY_CLIENT_COMPANY_REQUEST = 'PROGRAMS_LIST_BY_CLIENT_COMPANY_REQUEST';
export const PROGRAMS_LOADING_STATUS = 'PROGRAMS_LIST_SUCCESS';
export const PROGRAMS_LIST_FILTERS_CHANGE = 'PROGRAMS_LIST_FILTERS_CHANGE';
export const PROGRAMS_LIST_SEARCH_QUERY_CHANGE = 'PROGRAMS_LIST_SEARCH_QUERY_CHANGE';
export const PROGRAMS_LIST_STATUS_FILTER_CHANGE = 'PROGRAMS_LIST_STATUS_FILTER_CHANGE';
export const PROGRAMS_LIST_FILTERS_CLEAR = 'PROGRAMS_LIST_FILTERS_CLEAR';
export const PROGRAM_DETAILS_REQUEST = 'PROGRAM_DETAILS_REQUEST';
export const PROGRAM_LOADING_STATUS = 'PROGRAM_LOADING_STATUS';
export const PROGRAM_STOP_GENERATION = 'PROGRAM_STOP_GENERATION';

export const PROGRAM_SAVE = 'PROGRAM_SAVE';
export const PROGRAM_OFFER_ADD = 'PROGRAM_OFFER_ADD';
export const PROGRAM_OFFER_DELETE = 'PROGRAM_OFFER_DELETE';
export const PROGRAM_SERVICES_ORDER_SAVE = 'PROGRAM_SERVICES_ORDER_SAVE';
export const PROGRAM_SERVICES_ORDER_SUCCESS = 'PROGRAM_SERVICES_ORDER_SUCCESS';
export const PROGRAM_SERVICES_ORDER_ERROR = 'PROGRAM_SERVICES_ORDER_ERROR';
