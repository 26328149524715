// Used to display different company name in different hosts
import Vue from 'vue';
import axios from 'axios';
import { getCompanyName } from '@/utils/whitelabel-utils';

window.setCompanyName = (companyName) => {
  window.companyName = companyName;
  Vue.prototype.$companyName = window.companyName;
  document.documentElement.style.setProperty('--company-logo', `var(--${window.companyName}-logo)`);
  axios.defaults.headers.common['X-Product'] = companyName.toLowerCase();
};

const renovaLayoutDomains = [
  'renova.international',
  'admin.renova.world',
  'hr.renova.world',
  'dev.renova.world',
  'hr.capitalcare.dev.renova.world',
  'hr.capitalcare.renova.world',
  'main.dqe1ixxnpxslh6.amplifyapp.com',
  'test.dqe1ixxnpslh6.amplifyapp.com',
  'dev.admin.renova.international',
  'dev.hr.renova.international',
  'admin.renova.international',
  'hr.renova.international',
  'portento.hr.renova.world',
  'dev-portento.hr.renova.world',
  'malakut.hr.renova.world',
  'dev-malakut.hr.renova.world',
  'apex.hr.renova.world',
  'dev-apex.hr.renova.world',
  'capitalcare.hr.renova.world',
  'dev-capitalcare.hr.renova.world',
  'localhost',
];

window.whiteLabelCompany = getCompanyName(window.location.hostname);
window.setCompanyName(renovaLayoutDomains.includes(window.location.hostname) ? 'Renova' : 'Checkme');

// Additional temporary property to set what layout to use for Admin Section
window.adminLayout = renovaLayoutDomains.includes(window.location.hostname) ? 'RenovaDashboardLayout' : 'AdminLayout';
