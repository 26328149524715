<template>
  <section>
    <img
      v-if="currentStep !== stepTypes.ONBOARDING_SUBMIT && stepInfo.img"
      :style="stepInfo.img.styles"
      :src="stepInfo.img.src"
      class="onboarding__steps-img"
      alt="onboarding-img"/>
    <div
      v-if="currentStep === stepTypes.COMPANY_DETAILS && onboarding.company"
      class="onboarding__step-company">
      <div class="onboarding__step-company-img">
        <img v-if="onboarding.company.logoCdnFile" :src="onboarding.company.logoCdnFile.url"
             alt="logo">
      </div>
      <span
        class="caption color--black-purple ml_4">
            {{ onboarding.company.name || $t('adminTitles.InsuranceProvider') }}
          </span>
    </div>
  </section>
</template>
<script>
import { stepsInfo, stepTypes } from '@/views/RenovaDashboard/OnboardingParts/onboarding-steps';
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingPreviewImg',
  props: {
    currentStep: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(['onboarding']),
    stepInfo() {
      return this.currentStep ? stepsInfo[this.currentStep] : null;
    },
  },
  data() {
    return {
      stepsInfo,
      stepTypes,
    };
  },
};
</script>

<style lang="scss">

.onboarding__step-company {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 55px;
  left: 338px;

  span {
    font-family: "Inter", serif;
    font-size: 9px;
    max-width: 84px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.onboarding__step-company-img {
  width: 11px;
  height: 11px;
  display: flex;

  img {
    width: 11px;
    height: 11px;
    border-radius: 2px;
    object-fit: cover;
    overflow: hidden;
  }
}

.onboarding__steps-img {
  position: absolute;
  object-fit: contain;
  object-position: bottom;
}
</style>
