import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import i18n, { timeFormat } from '@/i18n';

dayjs.locale(i18n.locale);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
Vue.prototype.$dayjs = dayjs;

Vue.filter('date', (value) => {
  if (!value) return value;
  return dayjs(String(value)).format('L');
});
Vue.filter('formatDate', (value) => {
  if (!value) return value;
  return dayjs(String(value).replace(/Z$/, '')).format('DD.MM.YYYY');
});
Vue.filter('time', (value) => {
  if (!value) return value;
  return dayjs(String(value)).format('LT');
});

Vue.filter('formatTime', (value) => {
  if (!value) return value;
  return dayjs(String(value)).format(timeFormat[i18n.locale]);
});

Vue.filter('dateUserZone', (value) => {
  if (!value) return value;
  return dayjs(String(value)).utc(true).local().format('L');
});

Vue.filter('timeUserZone', (value) => {
  if (!value) return value;
  return dayjs(String(value)).utc(true).local().format('LT');
});

Vue.filter('dateTime', (value) => {
  if (!value) return value;
  return dayjs(String(value)).format('L LT');
});

Vue.filter('dateTimeUserZone', (value) => {
  if (!value) return value;
  return dayjs(String(value)).utc(true).local().format('L LT');
});
Vue.filter('getFileNameFromUrl', (url) => {
  const parsedUrl = new URL(url);
  return parsedUrl.pathname.split('/').pop();
});
