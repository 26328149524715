import axios from 'axios';

import {
  STORIES_LIST_REQUEST,
  STORIES_LIST_SUCCESS,
  STORY_DETAILS_SUCCESS,
  STORY_DETAILS_REQUEST,
  STORY_SAVE_REQUEST,
  STORY_SAVE_SUCCESS,
  STORY_DELETE_REQUEST,
  STORY_DELETE_SUCCESS,
} from '@/store/actions/stories';

import i18n from '@/i18n';

const state = {
  storiesList: {
    items: null,
    loading: false,
  },
  storyDetails: {
    story: null,
    loading: false,
    saveLoading: false,
  },
};

const getters = {
  storiesList: () => state.storiesList,
  storyDetails: () => state.storyDetails,
};

const actions = {
  [STORIES_LIST_REQUEST]({ commit, dispatch, state }, filters) {
    commit(STORIES_LIST_REQUEST);
    return axios.post(
      '/insurance/story/list',
      filters,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(STORIES_LIST_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(STORIES_LIST_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [STORY_DETAILS_REQUEST]({ commit }, storyId) {
    commit(STORY_DETAILS_REQUEST);
    return axios.post(
      '/insurance/story/detail',
      { storyId },
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(STORY_DETAILS_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(STORY_DETAILS_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [STORY_SAVE_REQUEST]({ commit }, story) {
    commit(STORY_SAVE_REQUEST);
    return axios.post(
      '/insurance/story/save',
      {
        ...story,
      },
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(STORY_SAVE_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(STORY_SAVE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [STORY_DELETE_REQUEST]({ commit }, storyId) {
    commit(STORY_DELETE_REQUEST);
    return axios.post(
      '/insurance/story/delete',
      { storyId },
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(STORY_DELETE_SUCCESS);
        return true;
      })
      .catch((err) => {
        commit(STORY_DELETE_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
};

const mutations = {
  [STORIES_LIST_REQUEST]: (state) => {
    state.storiesList.loading = !state.storiesList.loading;
  },
  [STORIES_LIST_SUCCESS]: (state, resp) => {
    state.storiesList.loading = false;
    state.storiesList.items = resp.items;
  },
  [STORY_DETAILS_REQUEST]: (state) => {
    state.storyDetails.story = null;
    state.storyDetails.loading = !state.storyDetails.loading;
  },
  [STORY_DETAILS_SUCCESS]: (state, resp) => {
    state.storyDetails.loading = false;
    state.storyDetails.story = resp;
  },

  [STORY_SAVE_REQUEST]: (state) => {
    state.storyDetails.saveLoading = !state.storyDetails.saveLoading;
  },
  [STORY_SAVE_SUCCESS]: (state, resp) => {
    state.storyDetails.saveLoading = false;
    state.storyDetails.story = resp;
  },
  [STORY_DELETE_REQUEST]: (state, resp) => {
    state.storyDetails.saveLoading = !state.storyDetails.saveLoading;
  },
  [STORY_DELETE_SUCCESS]: (state, resp) => {
    state.storyDetails.saveLoading = false;
    // state.storyDetails.story = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
