<template>
  <section></section>
</template>

<script>
import { Centrifuge } from 'centrifuge';
import { mapGetters } from 'vuex';
import {PROGRAM_DETAILS_REQUEST, PROGRAMS_LIST_REQUEST} from '@/store/actions/programs';

export default {
  name: 'InsuranceNotifications',
  watch: {
    insuranceCompanies: {
      handler() {
        this.subscribeToChannels();
      },
      deep: true,
    },
  },
  mounted() {
    this.subscribeToUserChannel();
  },
  computed: {
    ...mapGetters([
      'insuranceCompanies',
      'userSettings',
    ]),
  },
  methods: {
    subscribeToChannels() {
      const channels = this.insuranceCompanies.map((val) => val.insuranceChannel);
      this.initWebsocket(channels);
    },
    subscribeToUserChannel() {
      this.initWebsocket([this.userSettings.userChannel]);
    },
    initWebsocket(channels) {
      const centrifuge = new Centrifuge(window.WebsocketUrl, { token: localStorage.getItem('ins-token') });
      centrifuge.connect();
      channels.forEach((channelName) => {
        const channel = centrifuge.newSubscription(channelName);
        channel.on('publication', (message) => {
          this.handleNotification(message.data);
        });
        channel.subscribe();
      });
    },
    handleNotification(message) {
      switch (message.type) {
        case 'PROGRAM_SERVICES_GENERATION_COMPLETED':
          this.$notify.success({
            dangerouslyUseHTMLString: true,
            message: `${this.$t('adminTitles.ServicesHaveGenerated', {programId: message.programServicesGenerationCompletedDetails.programId})}`,
            duration: 15000,
          });
          this.$store.dispatch(PROGRAMS_LIST_REQUEST);
          if (this.$route.params.id) {
            this.$store.dispatch(PROGRAM_DETAILS_REQUEST, {
              packageId: this.$route.params.id,
            });
          }
          break;
        case 'INSURANCE_CONVERSATION_MESSAGE_SEND_BULK_COMPLETED':
          this.$notify.success({
            message: `${this.$t('adminTitles.MessagesSent', {
              count: `${message.insuranceConversationMessageSendBulkCompletedDetails?.sendSuccessCount}`,
            })}`,
          });
          this.$store.dispatch(PROGRAMS_LIST_REQUEST);
          break;
        case 'TASK_ASSIGNED':
          this.$notify.warning({
            dangerouslyUseHTMLString: true,
            message: `${this.$t(`notifications.messages.${message.type}`, {
              name: `${message.taskAssigned?.authorName}`,
              link: `/admin/tasks?taskId=${message.taskAssigned.taskId}`
            })}`,
          });
          this.$store.dispatch('fetchTasks');
          break;
        case 'TASK_EXPIRED':
          this.$notify.warning({
            dangerouslyUseHTMLString: true,
            message: `${this.$t(`notifications.messages.${message.type}`, {
              link: `/admin/tasks?taskId=${message.taskExpired.taskId}`
            })}`,
          });
          this.$store.dispatch('fetchTasks');
          break;
        case 'LEAD_CREATED':
          this.$notify.warning({
            dangerouslyUseHTMLString: true,
            message: `${this.$t(`notifications.messages.${message.type}`, {
              link: `/admin/leads/${message.leadCreated?.leadId}`,
            })}`,
          });
          break;
        case 'LEAD_MESSAGE_CREATED':
          this.$notify.warning({
            dangerouslyUseHTMLString: true,
            message: `${this.$t(`notifications.messages.${message.type}`, {
              link: `/admin/leads/${message.leadMessageCreated?.leadId}`,
            })}`,
          });
          break;
        case 'TICKET_CREATED':
          this.$notify.warning({
            dangerouslyUseHTMLString: true,
            message: `${this.$t(`notifications.messages.${message.type}`, {
              link: `/admin/tickets/${message.ticketCreated?.ticketId}`,
            })}`,
          });
          break;
        case 'TICKET_MESSAGE_CREATED':
          this.$notify.warning({
            dangerouslyUseHTMLString: true,
            message: `${this.$t(`notifications.messages.${message.type}`, {
              link: `/admin/tickets/${message.ticketMessageCreated?.ticketId}`,
            })}`,
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
