<template>
  <div
    class="input-switcher"
    @click="$emit('change', !checked)"
    :class="{'input-switcher--active': checked}"
  >
    <div class="input-switcher__circle"></div>
  </div>
</template>

<script>
export default {
  name: 'InputSwitcher',
  emits: ['change'],
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.input-switcher {
  width: 36px;
  height: 20px;
  background-color: var(--solid-lavender-grey);
  border-radius: 20px;
  padding: 2px;
  transition: all .3s ease-in-out;
}
.input-switcher > .input-switcher__circle {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 100%;
  filter: drop-shadow(0px 1.624px 3.248px rgba(0, 0, 0, 0.16));
  transition: all 300ms ease-in-out;
}
.input-switcher.input-switcher--active {
  background-color: rgba(88, 219, 77, 1);
}
.input-switcher.input-switcher--active > .input-switcher__circle {
  margin-left: 16px;
}

</style>
