var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(!_vm.fileUploadView && !_vm.loading)?_c('div',{staticClass:"document-file",class:{
      'document-file--disabled' : _vm.disabled,
      'document-file--deletable' : _vm.deleteBtn,
    }},[_c('a',{staticClass:"document-file__link",attrs:{"href":_vm.link,"download":"","target":"_blank"}},[(_vm.typeInfo || !_vm.type)?_c('div',{staticClass:"document-file__type",style:({'background-color': _vm.typeInfo ? _vm.typeInfo.color : '#58DB4D'})},[_vm._v(" "+_vm._s(_vm.typeInfo ? _vm.typeInfo.text : _vm.getFileTypeFromLink(_vm.link))+" ")]):_vm._e(),(_vm.type === 'img' || _vm.type === 'avatar' || _vm.type === 'logo' || _vm.type === 'companyLogo')?_c('img',{staticClass:"document-file__img",class:{
          'document-file__img--avatar': _vm.type === 'avatar',
          'document-file__img--logo': _vm.type === 'logo',
          'document-file__img--companyLogo': _vm.type === 'companyLogo',
        },attrs:{"alt":"'img'","src":_vm.link}}):_vm._e(),_c('div',{staticClass:"document-file__info body-text"},[_vm._v(" "+_vm._s(_vm.name || _vm.getFileNameFromLink(_vm.link))+" "),(_vm.description)?_c('p',{staticClass:"caption color--dark-purple"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e()]),(_vm.link && !_vm.deleteBtn && !_vm.editBtn)?_c('div',{staticClass:"document-file__btn"}):_vm._e()]),(_vm.deleteBtn)?_c('div',{staticClass:"document-file__delete-btn",on:{"click":function($event){return _vm.$emit('delete')}}}):_vm._e(),(_vm.editBtn)?_c('div',{staticClass:"document-file__edit-btn"},[_c('input',{ref:"fileInput",staticClass:"document-file__input",attrs:{"type":"file","name":"file","id":"file-input","accept":_vm.acceptFormats},on:{"change":_vm.onFileChange}})]):_vm._e()]):_vm._e(),(_vm.fileUploadView && !_vm.loading)?_c('div',{staticClass:"document-file-upload",class:{
      'document-file--disabled' : _vm.disabled,
    }},[_c('div',{staticClass:"document-file-upload__icon"}),_c('div',{staticClass:"document-file-upload__texts"},[_c('p',{staticClass:"body-text color--black-purple",domProps:{"innerHTML":_vm._s(_vm.$t('adminTitles.dragAndDropOrClickToImportFile'))}}),_c('p',{staticClass:"caption color--dark-purple"},[_vm._v(_vm._s(_vm.acceptFormatsText))])]),_c('input',{ref:"fileInput",staticClass:"document-file__input",attrs:{"type":"file","name":"file","id":"file-input","accept":_vm.acceptFormats},on:{"change":_vm.onFileChange}})]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"document-file document-file--loading"},[_c('BasePreloader',{attrs:{"color":'purple',"size":'l'}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }