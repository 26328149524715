<template>
  <section>
    <BaseSideModal
      @close="$emit('close')"
    >
      <template #title>
        {{ companyEdit ? $t('adminTitles.editCompany') : $t('adminTitles.createNewCompany') }}
      </template>

      <el-form label-position="top">

        <el-form-item class="mb_16" :label="$t('adminTitles.companyName')">
          <el-input
            :class="{'a-common__input-error': errors.name}"
            :placeholder="$t('adminTitles.enterCompanyName')"
            v-model="company.name"
          />
        </el-form-item>
        <el-form-item
          :label="$t('adminTitles.companyLogo')"
          class="employee-modal__input"
          prop="backgroundImageUrl"
        >
          <BaseDocument
            :file-upload-view="!company.logo"
            :type="'avatar'"
            :link="company.logo ? (company.logo.url || company.logo.link) : null"
            :delete-btn="!!company.logo"
            :loading="imgLoading"
            :accept-formats="'.jpeg, .jpg, .png'"
            :accept-formats-text="'JPG, PNG'"
            @fileChange="saveFile"
            @delete="deleteLogo()"
          ></BaseDocument>
        </el-form-item>
      </el-form>

      <template #footer>
        <BaseButton
          type="outline"
          @click="$emit('close')"
        >
          {{ $t('buttons.close') }}
        </BaseButton>
        <div class="company-modal__btns">
          <BaseButton
            v-if="companyEdit"
            :type="'error'"
            :loading="companyModalDeleteLoading"
            @click="startDeleting"
          >
            <img src="/img/renova-dashboard/icons/trash-solid-red.svg" alt="edit"/>
            {{ $t('adminTitles.deleteCompany') }}
          </BaseButton>
          <BaseButton
            @click="saveCompany()"
          >
            {{ companyEdit ? $t('buttons.save') : $t('buttons.create') }}
          </BaseButton>
        </div>
      </template>
    </BaseSideModal>
    <BaseDialog
      v-if="deleteCompanyModal"
      :width="'444px'"
      :text-align="'left'"
      :footer-align="'flex-end'"
      :title="deactivationTexts.title"
      :text="deactivationTexts.description"
      :confirm-button-text="$t('adminTitles.Delete')"
      :cancel-button-text="$t('adminTitles.goBack')"
      :confirm-loading="deleteBtnLoading"
      @close="deleteCompanyModal = null; deleteBtnLoading = false;"
      @confirm="deleteCompany()"
    />
  </section>
</template>

<script>
import BaseSideModal
  from '@/views/RenovaDashboard/components/ui-kit/BaseSideModal/BaseSideModal';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import {mapGetters} from 'vuex';
import {CLIENT_COMPANY_SAVE_REQUEST} from '@/store/actions/client-companies';
import BaseDocument from '@/views/RenovaDashboard/components/ui-kit/Cards/BaseDocument';
import {SAVE_FILE_TO_CDN_COMMON} from '@/store/actions/files';
import BaseDialog from '@/views/RenovaDashboard/components/ui-kit/BaseDialog';
import axios from 'axios';

export default {
  name: 'ModalCreateNewCompany',
  components: {
    BaseDialog,
    BaseDocument,
    BaseButton,
    BaseSideModal,
  },
  emits: ['close', 'saved', 'deleted'],
  props: {
    /**
     * Company to edit, if present - modal will be in edit mode
     */
    companyEdit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      company: null,
      imgLoading: false,
      file: null,
      companyModalDeleteLoading: false,
      deleteCompanyModal: false,
      deleteBtnLoading: false,
      companyPlans: [],
      errors: {
        defaultLocale: false,
        insuranceCompanyId: false,
        clientCompanyId: false,
        name: false,
      },
    };
  },
  created() {
    if (this.companyEdit) {
      this.company = JSON.parse(JSON.stringify(this.companyEdit));
    } else {
      this.company = {
        insuranceCompanyId: null,
        logoCdnFileIdSave: null,
        logo: null,
        name: '',
      };
      this.company.insuranceCompanyId = this.selectedInsuranceProvider;
    }
  },
  watch: {
    selectedInsuranceProvider(val) {
      if (val) {
        this.company.insuranceCompanyId = val;
      }
    },
  },
  computed: {
    ...mapGetters(['selectedInsuranceProvider', 'clientCompanyDetails']),
    deactivationTexts() {
      const plans = this.companyPlans.map((plan) => `<li><a href="/admin/programs/${plan.packageId}" target="_blank">${plan.name}</a></li>`)
        .join('');
      if (plans?.length) {
        return {
          title: this.$t('adminTitles.deleteCompanyTitle'),
          description: `${this.$t('adminTitles.GlobalCorpCompanyHasActivePlans', {
            plans,
            name: this.company.name
          })} ${this.$t('adminTitles.IfYouDeleteThisCompany')}`,
        };
      }
      return {
        title: this.$t('adminTitles.AreYouSureYouWantDeleteCompany', {name: this.company.name}),
        description: this.$t('adminTitles.AreYouSureYouWantDeleteCompanyText'),
      };
    }
  },
  methods: {
    deleteCompany() {
      this.$store.dispatch(CLIENT_COMPANY_SAVE_REQUEST, {
        clientCompanyId: this.company.clientCompanyId,
        clientCompanyDelete: true
      })
        .then((resp) => {
          this.$notify.success({
            message: `${this.$t('adminTitles.success')}`,
          });
          this.$emit('deleted');
        })
        .catch(() => {
          this.$notify.error({
            message: `${this.$t('adminTitles.someError')}`,
          });
        });
    },
    async startDeleting() {
      this.companyModalDeleteLoading = true;
      const plans = await axios.post(
        '/insurance/package/list',
        {
          clientCompanyIds: [this.company.clientCompanyId],
          limit: 1000,
          page: 0
        },
      );
      this.companyPlans = plans.data.items;
      this.companyModalDeleteLoading = false;
      this.deleteCompanyModal = true;
    },
    saveCompany() {
      this.$store.dispatch(CLIENT_COMPANY_SAVE_REQUEST, this.company)
        .then((resp) => {
          this.$notify.success({
            message: `${this.$t('adminTitles.success')}`,
          });
          this.$emit('saved');
          // this.$router.push(`/admin/companies/${resp.clientCompanyId}`);
        })
        .catch(() => {
          this.$notify.error({
            message: `${this.$t('adminTitles.someError')}`,
          });
        });
    },
    saveFile(e) {
      [this.file] = e.target.files;
      this.imgLoading = true;
      this.$store.dispatch(SAVE_FILE_TO_CDN_COMMON, {
        file: this.file,
        type: 'IMAGE',
        name: this.file.name.split('.')[0],
      })
        .then((resp) => {
          this.company.logoCdnFileIdSave = resp.cdnFileId;
          this.company.logo = resp;
          this.company.logoCdnFileIdDelete = null;
          this.file = resp;
          this.imgLoading = false;
        })
        .catch(() => {
          this.imgLoading = false;
          this.$notify.error({
            message: this.$t('adminTitles.fileUploadError'),
            duration: 5000,
          });
        });
    },
    deleteLogo() {
      this.company = {
        ...this.company,
        logo: null,
        logoCdnFileIdSave: null,
        logoCdnFileIdDelete: true,
      };
      this.file = null;
    }
  },
};
</script>

<style>
.company-modal__btns {
  display: flex;
  gap: 16px;
}

.delete-text__plans {
  padding-left: 20px;
  margin: 12px 0;

  li {
    margin-bottom: 4px;
  }

  a {
    color: var(--solid-dark-purple);
    text-decoration: underline;
    transition: .3s;

    &:hover {
      color: var(--solid-purple);
    }
  }
}
</style>
