export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const GET_HR_AUTH_TOKEN = 'GET_HR_AUTH_TOKEN';
export const GET_HR_PHONE_TOKEN = 'GET_HR_PHONE_TOKEN';
export const AUTH_HR_REQUEST = 'AUTH_HR_REQUEST';
export const GET_USER_OTP_REQUEST = 'GET_USER_OTP_REQUEST';
export const GET_USER_OTP_SUCCESS = 'GET_USER_OTP_SUCCESS';

export const GET_USER_OTP_ERROR = 'GET_USER_OTP_ERROR';
export const PASSWORD_CHANGE_EMAIL_REQUEST = 'PASSWORD_CHANGE_EMAIL_REQUEST';
export const PASSWORD_CHANGE_EMAIL_SUCCESS = 'PASSWORD_CHANGE_EMAIL_SUCCESS';

export const PASSWORD_CHANGE_EMAIL_ERROR = 'PASSWORD_CHANGE_EMAIL_ERROR';
export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';

export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR';
