export function setIntercomUserInfo(user) {
  if (window.Intercom) {
    window.Intercom('update', {
      user_id: user.id,
      email: user.email,
      name: `${user.firstName || ''} ${user.lastName || ''}`,
    });
  }
}

export function closeIntercomChat() {
  if (window.Intercom) {
    window.Intercom('hide');
  }
}
