import axios from 'axios';
import Qs from 'qs';
import dayjs from 'dayjs';
import users from '@/store/modules/users';
import Vue from 'vue';
import i18n from '@/i18n';
import { USER_DETAILS_REQUEST } from '@/store/actions/users';
import {
  TICKET_LIST_REQUEST,
  TICKET_LIST_SUCCESS,
  TICKET_LIST_FILTERS_REQUEST,
  TICKET_LIST_FILTERS_SUCCESS,
  TICKET_LIST_FILTERS_CHANGE,
  TICKET_LIST_FILTERS_CLEAR,
  TICKET_DETAILS_REQUEST,
  TICKET_DETAILS_SUCCESS,
  TICKET_CHANGE_STATUS,
  TICKET_LIST_SET_PAGE,
  TICKET_LIST_SET_LIMIT,
  TICKET_PROGRAM_DETAILS_REQUEST,
  TICKET_PROGRAM_DETAILS_SUCCESS,
  GET_TICKETS_SUGGEST_LIST,
  TICKET_TAGS_REQUEST,
  TICKET_TAGS_SUCCESS,
  TICKET_TAGS_UPDATE,
  TICKET_TAG_DELETE, DOCTOR_TICKET_CHANGE_STATUS,
} from '../actions/ticketList';

/**
 * Default filters
 * @param useCache - if true, will check if we have filters stored in local storage
 * @returns {any|{fromDate: string, dateRange: *[], toDate: string, searchQuery: string, limit: number, clientCompanyId: *[], page: number, businessType: *[], status: *[]}}
 * @constructor
 */
function FiltersDefault() {
  return {
    page: 1,
    limit: 30,
    clientCompanyId: [],
    businessType: [],
    status: [],
    fromDate: '',
    toDate: '',
    dateRange: [],
    searchQuery: '',
    oneHourPendingRequest: false,
    programId: [],
    serviceId: [],
    operatorUserId: [],
    tagId: [],
    insuranceType: [],
  };
}

const state = {
  ticketList: {
    items: [],
    ticketsSuggestList: [],
    totalCount: 1,
    loading: false,
  },
  ticketDetails: {
    ticket: null,
    loading: false,
    program: null,
    programLoading: false,
    reception: null,
    receptionLoading: false,
  },
  ticketTags: {
    items: [],
    loading: false,
  },
  filtersData: {
    loading: false,
    haveTodoTicket: false,
    oneCompany: false,
    companies: [],
  },
  filters: FiltersDefault(),
};

const getters = {
  ticketList: () => state.ticketList,
  ticketDetails: () => state.ticketDetails,
  ticketListFilters: () => state.filters,
  ticketsFiltersData: () => state.filtersData,
  ticketTags: () => state.ticketTags,
};

const actions = {
  [TICKET_LIST_REQUEST]({ commit, state }) {
    commit(TICKET_LIST_REQUEST);

    function paramsFilter(prefix, value) {
      if (prefix === 'toDate' || prefix === 'fromDate') {
        return value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss') : undefined;
      }

      if (prefix === 'dateRange') {
        return;
      }

      if (prefix === 'page') {
        return value - 1;
      }

      return value || undefined;
    }
    return axios.get('/insurance/ticket/list', {
      headers: { 'X-Locale': i18n.locale },
      params: {
        ...state.filters,
        businessTypeExclude: 'LEAD'
      },
      paramsSerializer(params) {
        return Qs.stringify(params, {
          arrayFormat: 'repeat',
          filter: paramsFilter,
        }).replace(/%3A/g, ':');
      },
    })
      .then((resp) => {
        commit(TICKET_LIST_SUCCESS, resp.data);
        return Promise.resolve(resp.data);
      })
      .catch((err) => {
        commit(TICKET_LIST_REQUEST);
        return Promise.reject(err.response.data);
      });
  },
  [TICKET_DETAILS_REQUEST]({ commit }, params) {
    commit(TICKET_DETAILS_REQUEST, params);
    return axios.post(
      '/insurance/ticket/detail',
      params,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(TICKET_DETAILS_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(TICKET_DETAILS_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },

  [TICKET_PROGRAM_DETAILS_REQUEST]({ commit }, request) {
    commit(TICKET_PROGRAM_DETAILS_REQUEST);
    return axios.post(
      '/insurance/ticket/program/detail',
      request,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(TICKET_PROGRAM_DETAILS_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        commit(TICKET_PROGRAM_DETAILS_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },

  [TICKET_LIST_FILTERS_REQUEST]({ commit }) {
    commit(TICKET_LIST_FILTERS_REQUEST);
    return axios.get(
      '/insurance/ticket/filter/detail',
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(TICKET_LIST_FILTERS_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(TICKET_LIST_FILTERS_REQUEST);
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [TICKET_LIST_FILTERS_CHANGE]({ commit, dispatch }, filters) {
    commit(TICKET_LIST_FILTERS_CHANGE, filters);
    dispatch(TICKET_LIST_REQUEST);
  },

  [TICKET_CHANGE_STATUS]({ commit, dispatch }, ticket) {
    return axios.post(
      '/insurance/ticket/save',
      ticket,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(TICKET_DETAILS_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [DOCTOR_TICKET_CHANGE_STATUS]({ commit, dispatch }, data) {
    return axios.post(
      '/insurance/doctor-call/save',
      data,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => Promise.resolve())
      .catch((err) => {
        console.log(err.response.data);
        return Promise.reject(err.response.data);
      });
  },
  [GET_TICKETS_SUGGEST_LIST]({ commit }, searchString) {
    return axios.post(
      '/insurance/ticket/suggest/list',
      { limit: 10, searchString },
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(GET_TICKETS_SUGGEST_LIST, resp.data);
        return resp.data;
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [TICKET_LIST_SET_LIMIT]({ commit, dispatch }, limit) {
    commit(TICKET_LIST_SET_LIMIT, limit);
    dispatch(TICKET_LIST_REQUEST);
  },
  [TICKET_LIST_SET_PAGE]({ commit, dispatch }, page) {
    commit(TICKET_LIST_SET_PAGE, page);
    dispatch(TICKET_LIST_REQUEST);
  },
  [TICKET_LIST_FILTERS_CLEAR]({ commit, dispatch }, page) {
    commit(TICKET_LIST_FILTERS_CLEAR);
    dispatch(TICKET_LIST_REQUEST);
  },

  [TICKET_TAGS_REQUEST]({ commit }, insuranceCompanyId) {
    return axios.post(
      '/insurance/ticket/tag/list',
      insuranceCompanyId,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(TICKET_TAGS_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [TICKET_TAGS_UPDATE]({ commit, dispatch, state }, tag) {
    return axios.post(
      '/insurance/ticket/save',
      tag,
      { headers: { 'X-Locale': i18n.locale } },
    )
      .then((resp) => {
        commit(TICKET_DETAILS_SUCCESS, resp.data);
        dispatch(TICKET_TAGS_REQUEST, { insuranceCompanyId: state.ticketDetails.ticket.insuranceCompanyId });
        return Promise.resolve();
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [TICKET_TAG_DELETE]({ commit, dispatch }, tag) {
    return axios.post('/insurance/ticket/tag/delete/all', tag)
      .then((resp) => Promise.resolve())
      .catch((err) => Promise.reject(err.response.data));
  },
};

const mutations = {
  [TICKET_LIST_REQUEST]: (state) => {
    state.ticketList.loading = !state.ticketList.loading;
  },
  [TICKET_LIST_SUCCESS]: (state, resp) => {
    state.ticketList.loading = false;
    state.ticketList.items = resp.items;
    state.ticketList.totalCount = resp.totalCount;
    state.ticketList.stats = resp.stats;

    state.filtersData.haveTodoTicket = resp.items.some((item) => item.hasUnreadClientMessages);
  },

  [TICKET_DETAILS_REQUEST]: (state, params) => {
    state.ticketDetails.loading = !state.ticketDetails.loading;
    state.ticketDetails.ticket = null;
    if (params.needUserGet) {
      users.state.userDetails.user = null;
    }
  },
  [TICKET_DETAILS_SUCCESS]: (state, resp) => {
    state.ticketDetails.loading = false;
    state.ticketDetails.ticket = resp;
    state.ticketDetails.ticket.insuranceCompanyId = resp?.insuranceCompany?.id;
  },

  [TICKET_PROGRAM_DETAILS_REQUEST]: (state) => {
    state.ticketDetails.programLoading = !state.ticketDetails.programLoading;
    state.ticketDetails.program = null;
  },
  [TICKET_PROGRAM_DETAILS_SUCCESS]: (state, resp) => {
    state.ticketDetails.programLoading = false;
    state.ticketDetails.program = resp;
  },

  [TICKET_LIST_FILTERS_REQUEST]: (state) => {
    state.ticketList.loading = !state.ticketList.loading;
  },
  [TICKET_LIST_FILTERS_SUCCESS]: (state, resp) => {
    state.filtersData.companies = resp.companies;
    state.filtersData.services = resp.services.map((val) => ({...val, id: val.serviceId}));
    state.filtersData.oneCompany = resp.companies.length === 1;
    // state.filters.clientCompanyId = state.filtersData.oneCompany
    //   ? state.filtersData.companies[0].id
    //   : state.filters.clientCompanyId;
  },

  [TICKET_LIST_FILTERS_CHANGE]: (state, filters) => {
    state.filters = filters;
    localStorage.setItem('ticketListFilters', JSON.stringify(filters));
  },
  [TICKET_LIST_SET_PAGE]: (state, page) => {
    state.filters.page = page;
  },
  [TICKET_LIST_SET_LIMIT]: (state, limit) => {
    state.filters.limit = limit;
    state.filters.page = 1;
  },
  [TICKET_LIST_FILTERS_CLEAR]: (state) => {
    state.filters = FiltersDefault();
  },
  [GET_TICKETS_SUGGEST_LIST]: (state, resp) => {
    state.ticketList.ticketsSuggestList = resp.items;
  },

  [TICKET_TAGS_REQUEST]: (state) => {
    state.ticketTags.loading = !state.ticketTags.loading;
  },
  [TICKET_TAGS_SUCCESS]: (state, resp) => {
    state.ticketTags.loading = false;
    state.ticketTags.items = resp.items;
    state.ticketTags.items.forEach((item) => {
      Vue.set(item, 'value', item.name);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
