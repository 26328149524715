export const CLIENTS_CHAT_CONFIG_REQUEST = 'CLIENTS_CHAT_CONFIG_REQUEST';
export const CLIENTS_CHAT_CONVERSATIONS_DETAILS_REQUEST = 'CLIENTS_CHAT_CONVERSATIONS_DETAILS_REQUEST';
export const CLIENTS_CHAT_MESSAGES_REQUEST = 'CLIENTS_CHAT_MESSAGES_REQUEST';
export const CLIENTS_CHAT_MESSAGES_READ_REQUEST = 'CLIENTS_CHAT_MESSAGES_READ_REQUEST';
export const CLIENTS_CHAT_MESSAGES_ERROR = 'CLIENTS_CHAT_MESSAGES_ERROR';
export const CLIENTS_CHAT_MESSAGE_SEND_REQUEST = 'CLIENT_CHAT_MESSAGE_SEND_REQUEST';
export const CLIENTS_CHAT_MESSAGE_EDIT_REQUEST = 'CLIENTS_CHAT_MESSAGE_EDIT_REQUEST';
export const CLIENTS_CHAT_MESSAGE_DELETE_REQUEST = 'CLIENTS_CHAT_MESSAGE_DELETE_REQUEST';
export const CLIENTS_CHAT_MESSAGE_SEND_SUCCESS = 'CLIENTS_CHAT_MESSAGE_SEND_SUCCESS';
export const CLIENTS_CHAT_MESSAGE_SEND_ERROR = 'CLIENTS_CHAT_MESSAGE_SEND_ERROR';
export const CLIENTS_CHAT_ATTACH_FILE = 'CLIENTS_CHAT_ATTACH_FILE';
export const CLIENTS_CHAT_DELETE_ATTACHMENT = 'CLIENTS_CHAT_DELETE_ATTACHMENT';
export const CLIENTS_CHAT_CLEAR_ATTACHMENTS = 'CLIENTS_CHAT_CLEAR_ATTACHMENTS';
export const CLIENTS_CHAT_WEBSOCKET_RECEIVED = 'CLIENTS_CHAT_WEBSOCKET_RECEIVED';
export const CLIENTS_CHAT_WEBSOCKET_MESSAGE_READ_RECEIVED = 'CLIENTS_CHAT_WEBSOCKET_MESSAGE_READ_RECEIVED';
export const CLIENTS_CHAT_GET_SUGGESTION_REQUEST = 'CLIENTS_CHAT_GET_SUGGESTION_REQUEST';
export const CLIENTS_CHAT_GET_IMPROVED_TEXT = 'CLIENTS_CHAT_GET_IMPROVED_TEXT';

export const CHAT_MESSAGE_SEND_REQUEST = 'CHAT_MESSAGE_SEND_REQUEST';
export const CHAT_MESSAGES_REQUEST = 'CHAT_MESSAGES_REQUEST';
export const CHAT_MESSAGES_READ_REQUEST = 'CHAT_MESSAGES_READ_REQUEST';
export const CHAT_CONFIG_REQUEST = 'CHAT_CONFIG_REQUEST';
export const CHAT_CONVERSATIONS_DETAILS_REQUEST = 'CHAT_CONVERSATIONS_DETAILS_REQUEST';
export const CHAT_WEBSOCKET_RECEIVED = 'CHAT_WEBSOCKET_RECEIVED';
export const CHAT_ATTACH_FILE = 'CHAT_ATTACH_FILE';
export const CHAT_DELETE_ATTACHMENT = 'CHAT_DELETE_ATTACHMENT';
export const CHAT_CLEAR_ATTACHMENTS = 'CHAT_CLEAR_ATTACHMENTS';
export const CHAT_WEBSOCKET_MESSAGE_READ_RECEIVED = 'CHAT_WEBSOCKET_MESSAGE_READ_RECEIVED';
