<template>
  <div class="admin-container">
    <TheSideBar v-if="showContent" />
    <div class="admin-page-container">
      <AdminNavBar :breadcrumbs="breadcrumbs" />
      <div id="adminPageBody" class="admin-page-body">
        <slot v-if="showContent" />
      </div>
    </div>
    <InsuranceNotifications v-if="userSettings"></InsuranceNotifications>
    <OnboardingComponent></OnboardingComponent>
  </div>
</template>

<script>
import TheSideBar from '@/views/RenovaDashboard/CommonParts/SideBar/TheSideBar';
import AdminNavBar from '@/views/RenovaDashboard/CommonParts/AdminNavBar';
import { USER_REQUEST } from '@/store/actions/user';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { GET_ACTIVE_INSURANCE_COMPANIES, GET_DICTIONARY } from '@/store/actions/dictionary';
import { TICKET_LIST_FILTERS_REQUEST, TICKET_LIST_REQUEST } from '@/store/actions/ticketList';
import axios from 'axios';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import InsuranceNotifications from '@/views/RenovaDashboard/CommonParts/InsuranceNotifications';
import growthbook from '@/utils/growthbook';
import featureMixin from '@/mixins/featureMixin';
import OpenreplayTracker from '@/utils/openreplay';
import amplitude from 'amplitude-js';
import OnboardingComponent from '@/views/RenovaDashboard/OnboardingParts/Onboarding';
import { ONBOARDING_DETAILS_REQUEST } from '@/store/actions/onboarding';
import { setIntercomUserInfo } from '@/utils/intercom';

/**
 * @emits breadcrumbs, add-breadcrumb, click-breadcrumb
 * @type {Vue | CombinedVueInstance<Vue, object, object, object, Record<never, any>>}
 */
const adminEventBus = new Vue(); // для новой админки Renova
export { adminEventBus };

export default {
  name: 'RenovaDashboardLayout',
  components: {
    OnboardingComponent,
    AdminNavBar,
    TheSideBar,
    InsuranceNotifications,
  },
  data() {
    return {
      showContent: false,
      getTicketsInterval: null,
      breadcrumbs: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.breadcrumbs = [];
      }
    },
  },
  created() {
    // set breadcrumbs
    adminEventBus.$on('breadcrumbs', (breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });
    // add new breadcrumb to the end
    adminEventBus.$on('add-breadcrumb', (title, link) => {
      const breadcrumbAlreadyExists = this.breadcrumbs.some(
        (breadcrumb) => breadcrumb.title === title
      );
      if (!breadcrumbAlreadyExists) {
        this.breadcrumbs.push({
          title,
          link,
        });
      }
    });
    // remove all breadcrumbs after clicked
    adminEventBus.$on('click-breadcrumb', (index) => {
      this.breadcrumbs.splice(index + 1);
    });
    if (!localStorage.getItem('notify-sound')) localStorage.setItem('notify-sound', JSON.stringify(true));

    this.$store.dispatch(USER_REQUEST).then(() => {
      this.setProvider(this.user);
      this.initAmplitude(this.user);
      this.initIntercom(this.user);
      this.initGrowthbook({
        company: localStorage.getItem(`${this.user.id}_insuranceProvider`) || null,
        id: this.user.id,
      });
      this.initOpenreplay(this.user);
      // amplitude.init('5c0e7ca0715ba0643356491c582582a3', this.user.id);
      if (!this.user.domains?.length) {
        this.$store.dispatch(AUTH_LOGOUT).then(() => {
          this.$router.push('/');
        });
      } else if (!this.user.domains.some((item) => item.type === 'INSURANCE_ADMIN_WORKSPACE')) {
        this.$router.push('/');
      } else {
        this.$store.dispatch(GET_DICTIONARY, this.user.id);
        this.$store.dispatch(GET_ACTIVE_INSURANCE_COMPANIES);
        this.$store.dispatch(TICKET_LIST_FILTERS_REQUEST);
        this.fetchTickets();
        this.$store.dispatch('fetchTags');
      }
    });
    this.getUserSetting();
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          // Exception for 401 error from openai, will ignore it
          if (!error.request.responseURL.contains('openai')) {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
              this.$router.push('/login');
            });
          }
        }

        return Promise.reject(error);
      }
    );
  },
  mounted() {
    this.setupInterval();
    this.setupStyles();
  },
  mixins: [featureMixin],
  computed: {
    ...mapGetters(['user', 'ticketList', 'selectedInsuranceProviderDetails', 'userSettings']),
  },
  methods: {
    setupInterval() {
      this.getTicketsInterval = setInterval(() => {
        if (this.user?.id) {
          this.fetchTickets();
          if (!window.isDev) {
            amplitude.getInstance().logEvent('Session_Trigger');
          }
        }
      }, 10000);
    },
    fetchTickets() {
      this.$store.dispatch(TICKET_LIST_REQUEST).then((resp) => {
        this.newMessagesNotify();
        if (this.user?.id) {
          this.newTicketsNotify(resp.totalCount);
        }
      });
    },
    newTicketsNotify(totalCount) {
      const tickets = JSON.parse(localStorage.getItem(`tickets.${this.user.id}`));
      if (!tickets || totalCount > tickets?.count) {
        localStorage.setItem(
          `tickets.${this.user.id}`,
          JSON.stringify({
            count: totalCount,
          })
        );
        if (tickets && JSON.parse(localStorage.getItem('notify-sound'))) {
          const audio = new Audio('/sounds/new-ticket.mp3');
          audio.oncanplaythrough = () => {
            const playedPromise = audio.play();
            if (playedPromise) {
              playedPromise.catch((e) => {
                console.log(e.name);
              });
            }
          };
        }
      }
    },
    async initGrowthbook(attributes) {
      growthbook.setAttributes(attributes);
      await growthbook.loadFeatures();
      this.showContent = true;
    },
    initAmplitude(user) {
      if (!window.isDev) {
        amplitude.getInstance().init('58096cb94b277fcccd492e4d658bad12', user.id);
        amplitude.setUserProperties({
          email: user.email,
          ProviderId: this.selectedInsuranceProviderDetails.id,
          ProviderName: this.selectedInsuranceProviderDetails.name,
          ProjectName: `${window.whiteLabelCompany}-admin`,
        });
      }
    },
    initIntercom(user) {
      setIntercomUserInfo(user);
    },
    initOpenreplay(user) {
      OpenreplayTracker.start({
        userID: user.id,
      });
      if (user.clientCompanyDetails) {
        OpenreplayTracker.setMetadata('insurance', user.clientCompanyDetails.id);
        OpenreplayTracker.setMetadata('insuranceName', user.clientCompanyDetails.name);
      }
    },
    newMessagesNotify() {
      const newTodoTicketsCount = this.ticketList.stats.commonCountTodo;
      if (newTodoTicketsCount) {
        document.title = `${newTodoTicketsCount} ${this.$tc(
          'adminTitles.NewMessage',
          newTodoTicketsCount
        )}`;
        this.drawCounterOnFavicon(newTodoTicketsCount);
      } else if (
        !newTodoTicketsCount
        && document.title.includes(this.$tc('adminTitles.NewMessage', newTodoTicketsCount))
      ) {
        const title = this.$t(`meta.${this.$router.history.current.name}.title`);
        // eslint-disable-next-line no-template-curly-in-string
        document.title = title.replaceAll('${window.companyName}', window.companyName);
        const favicon = document.getElementById('favicon');
        favicon.href = `/favicons/favicon_${window.whiteLabelCompany}.svg`;
      }
    },
    setProvider(user) {
      const providers = user.domains
        .filter((domain) => domain.type === 'INSURANCE_ADMIN_WORKSPACE')
        .map((domain) => domain.insuranceCompany);
      const savedProviderId = localStorage.getItem(`${user.id}_insuranceProvider`);
      if (savedProviderId && providers.some((provider) => provider.id === savedProviderId)) {
        axios.defaults.headers.common['X-Insurance-Provider-ID'] = savedProviderId;
        this.$store.dispatch(ONBOARDING_DETAILS_REQUEST, savedProviderId);
      } else {
        localStorage.setItem(`${user.id}_insuranceProvider`, providers[0].id);
        axios.defaults.headers.common['X-Insurance-Provider-ID'] = providers[0].id;
        window.location.reload();
      }
    },
    setupStyles() {
      if (window.adminLayout === 'RenovaDashboardLayout') {
        import('@/assets/scss/renova/renova-ui-elements.scss');
      }
    },
    drawCounterOnFavicon(counter) {
      const canvas = document.createElement('canvas');
      canvas.width = 32;
      canvas.height = 32;
      const context = canvas.getContext('2d');

      const img = new Image();
      img.onload = () => {
        context.drawImage(img, 0, 0, 32, 32);
        context.fillStyle = '#FF8D7A';
        const circleSize = 16;
        context.beginPath();
        context.arc(23, 23, circleSize / 2, 0, 2 * Math.PI);
        context.fill();
        context.fillStyle = '#FFFFFF';
        context.font = `normal ${counter >= 100 ? '8' : '12'}px Arial`;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(counter, 23, 23 + 1);
        const favicon = document.getElementById('favicon');
        favicon.href = canvas.toDataURL('image/x-icon');
      };

      img.src = `/favicons/favicon_${window.whiteLabelCompany}.svg`;
    },
    getUserSetting() {
      setTimeout(() => {
        this.$store.dispatch('fetchUserSettings', this.selectedInsuranceProviderDetails.id).then((setting) => {
          console.log(setting);
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/renova/fonts.scss";
@import "@/assets/scss/renova/renova-admin.scss";

* {
  font-family: "Inter", serif;
}

.admin-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  //background: var(--solid-light-purple);
}

.admin-page-container {
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.admin-page-body {
  height: 100%;
  overflow-y: auto; // scroll
  padding: 32px 48px 100px;
}

.admin-page-body::-webkit-scrollbar {
  display: none !important;
}
</style>
