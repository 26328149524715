import axios from 'axios';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import i18n from '@/i18n';

const defaultFilters = {
  clientCompanyId: null,
  programId: null,
  from: dayjs().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
  to: dayjs().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
  ticketCategory: 'INSURED',
  agentIds: []
};
const state = {
  dashboardMetrics: null,
  dashboardFilters: defaultFilters,
};

const getters = {
  dashboardMetrics: (state) => state.dashboardMetrics,
  dashboardFilters: (state) => state.dashboardFilters,
};

const mutations = {
  SET_DASHBOARD_METRICS(state, metrics) {
    state.dashboardMetrics = metrics;
  },
  SET_DASHBOARD_FILTERS(state, dashboardFilters) {
    state.dashboardFilters = { ...state.dashboardFilters, ...dashboardFilters };
  },
  CLEAR_DASHBOARD_METRICS(state) {
    state.dashboardMetrics = null;
  },
  CLEAR_DASHBOARD_FILTERS(state, dashboardFilters) {
    state.dashboardFilters = { ...defaultFilters, ticketCategory: state.dashboardFilters.ticketCategory };
  },
};

const actions = {
  async fetchDashboardMetrics({
    commit,
    state,
  }) {
    try {
      const response = await axios.post(
        `/insurance/metric${state.dashboardFilters.ticketCategory === 'LEAD' ? '/lead' : ''}/detail`,
        state.dashboardFilters,
        {
          headers: {
            'X-Locale': i18n.locale,
          }
        }
      );
      commit('SET_DASHBOARD_METRICS', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching dashboard:', error);
    }
  },
  clearDashboardMetrics: ({ commit }) => {
    commit('CLEAR_DASHBOARD_METRICS');
  },
  clearDashboardFilters: ({ commit }) => {
    commit('CLEAR_DASHBOARD_FILTERS');
  },
  updateDashboardFilters: debounce(({
    commit,
    dispatch,
  }, dashboardFilters) => {
    commit('SET_DASHBOARD_FILTERS', dashboardFilters);
    dispatch('fetchDashboardMetrics');
  }, 300),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
