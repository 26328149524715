import axios from 'axios';

const state = {
  chatBot: null,
};

const getters = {
  chatBotButtons: () => state.chatBot?.buttons,
  chatBot: () => state.chatBot,
};
const actions = {

  fetchChatBot({commit}) {
    axios.post('/insurance/config/detail', { })
      .then((response) => {
        commit('setChatBot', response.data.chatBot);
      })
      .catch((error) => {
        console.error('Error updating replies:', error);
      });
  },
  addButton({commit, dispatch}, buttonSave) {
    axios.post('/insurance/config/save', {
      chatBot: {
        buttonSave
      }
    }).then((res) => {
      commit('setChatBot', res.data.chatBot);
    });
  },
  deleteButtons({commit, dispatch}, buttonsDelete) {
    axios.post('/insurance/config/save', {
      chatBot: {
        buttonsDelete
      }
    })
      .then((res) => {
        commit('setChatBot', res.data.chatBot);
      })
      .catch((error) => {
        console.error('Error deleting', error);
      });
  },
};

const mutations = {
  setChatBot(state, chatBot) {
    state.chatBot = chatBot;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
