<template>
  <BaseOverlay
    :style="overlayStyles"
    @click.native.self.exact="$emit('close')"
  >
    <div
      class="base-dialog"
      :style="dialogStyles"
    >
      <h3 :style="{'text-align': textAlign}">{{ title }}</h3>
      <p :style="{'text-align': textAlign}" v-html="text"></p>
      <slot/>
      <div
        class="base-dialog-footer"
        :style="{'justify-content': footerAlign}"
      >
        <BaseButton
          v-if="cancelButtonText"
          class="base-dialog-footer__button"
          type="outline"
          @click.native.self.exact="$emit('close')"
        >
          {{ cancelButtonText }}
        </BaseButton>
        <BaseButton
          v-if="confirmButtonText"
          class="base-dialog-footer__button"
          :loading="confirmLoading"
          @click.native.self.exact="$emit('confirm')"
        >
          <img v-if="icon === 'check-circle'" src="/img/renova-dashboard/icons/check-circle.svg" alt="">
          {{ confirmButtonText }}
        </BaseButton>
      </div>
    </div>
  </BaseOverlay>
</template>

<script>
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import BaseOverlay from '@/views/RenovaDashboard/components/ui-kit/BaseOverlay';

export default {
  name: 'BaseDialog',
  components: {
    BaseOverlay,
    BaseButton,
  },
  emits: ['close', 'confirm'],
  props: {
    title: {
      type: String,
      default: 'Confirm',
      required: false,
    },
    text: {
      type: String,
      default: 'Are you sure?',
      required: false,
    },
    width: {
      type: String,
      default: '307px',
      required: false,
    },
    textAlign: {
      type: String,
      default: 'center',
      required: false,
    },
    footerAlign: {
      type: String,
      default: 'space-around',
      required: false,
    },
    confirmButtonText: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    cancelButtonText: {
      type: String,
      required: false,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogStyles() {
      return {
        'max-width': this.width,
      };
    },
    overlayStyles() {
      return {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-dialog {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--solid-black-purple);
    margin: 0 0 16px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--solid-dark-purple);
    margin-bottom: 20px;
  }

  &-footer {
    display: flex;
    justify-content: space-around;
    gap: 12px;
  }
}
</style>
