import axios from 'axios';

import i18n from '@/i18n';
import {
  CLEAR_ONBOARDING,
  CLEAR_ONBOARDING_SUCCESS,
  ONBOARDING_DETAILS_REQUEST,
  ONBOARDING_DETAILS_SUCCESS, SAVE_INSURANCE_CONFIG, SAVE_ONBOARDING,
  UPDATE_ONBOARDING,
  UPDATE_ONBOARDING_SUCCESS,
} from '@/store/actions/onboarding';
import { stepTypes } from '@/views/RenovaDashboard/OnboardingParts/onboarding-steps';

export const DefaultOnboarding = {
  company: null,
  operators: [''],
  companies: [],
  currentStep: stepTypes.COMPANY_DETAILS,
  complete: false,
};
const state = {
  onboarding: DefaultOnboarding,
  onboardingInsuranceCompany: null,
};

const getters = {
  onboarding: () => state.onboarding,
  onboardingInsuranceCompany: () => state.onboardingInsuranceCompany,
};
const actions = {
  [ONBOARDING_DETAILS_REQUEST]({commit, state}, insuranceCompanyId) {
    return axios.post(
      '/insurance/config/detail',
      {insuranceCompanyId},
      {headers: {'X-Locale': i18n.locale}},
    )
      .then((resp) => {
        commit(ONBOARDING_DETAILS_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [SAVE_ONBOARDING]({commit}) {
    return axios.post(
      '/insurance/config/save',
      {
        insuranceCompanyId: state.onboardingInsuranceCompany.insuranceCompanyId,
        storageSave: {
          ...state.onboardingInsuranceCompany.storage,
          onboarding: state.onboarding,
        }
      },
    );
  },
  [SAVE_INSURANCE_CONFIG]({commit}, requestBody) {
    return axios.post(
      '/insurance/config/save',
      {
        insuranceCompanyId: state.onboardingInsuranceCompany.insuranceCompanyId,
        ...requestBody
      },
    );
  },
  [UPDATE_ONBOARDING]({commit}, updatedOnboarding) {
    commit(UPDATE_ONBOARDING_SUCCESS, updatedOnboarding);
  },
  [CLEAR_ONBOARDING]({commit, dispatch}) {
    return axios.post(
      '/insurance/config/save',
      {
        insuranceCompanyId: state.onboardingInsuranceCompany.insuranceCompanyId,
        storageDelete: ['onboarding']
      },
    ).then(() => {
      commit(CLEAR_ONBOARDING_SUCCESS);
      dispatch(ONBOARDING_DETAILS_REQUEST);
    });
  },
};
const mutations = {
  [ONBOARDING_DETAILS_SUCCESS](state, insuranceConfig) {
    state.onboardingInsuranceCompany = insuranceConfig;
    const onboarding = insuranceConfig?.storage?.onboarding;
    if (onboarding) {
      state.onboarding = onboarding;
    } else {
      state.onboarding.company = {
        logoCdnFile: insuranceConfig.logoCdnFile,
        name: insuranceConfig.name,
      };
    }
  },
  [CLEAR_ONBOARDING_SUCCESS](state) {
    state.onboarding = DefaultOnboarding;
  },
  [UPDATE_ONBOARDING_SUCCESS](state, updatedOnboarding) {
    state.onboarding = {
      ...state.onboarding,
      ...updatedOnboarding
    };
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
